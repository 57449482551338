import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import BarPlotForMFM from "../../Graphs/BarPlotForMFM";
import "./Abtmeter.scss";
import GaugeChart from "react-gauge-chart";

import "../../Common/FontSizes.scss";
import MultiDateLineGraph from "../../Graphs/MultiDateLineGraph";
import TableNoSearchBar from "../../Common/TableNoSearchBar";
import useErrorHandler from "../../../utils/errorHandler";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import TimestampFormatter from "../../Common/TimestampFormatter";
const invService = new CosmicInverterService();

function AbtMeter() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [energyGraphData, setEnergyGraphData] = useState([]);
  const [deviceID, setDeviceId] = useState(0);
  const [mfmData, setIsMfmData] = useState([]);
  const [abtMeterData, setAbtMeterData] = useState({});
  const { isMobileAndTab, isDesktopView } = useMobile();
  const [mfmFieldCompare, setMfmFieldCompare] = useState([]);
  const [isStartTimeMfmFieldCompare, setIsStartTimeMfmFieldCompare] =
    useState("");
  const [isStopTimeMfmFieldCompare, setIsStopTimeMfmFieldCompare] =
    useState("");
  const [gridHeightTwo, setGridHeightTwo] = useState(0);
  const [isStartTimeBarEnergy, setIsStartTimeBarEnergy] = useState("");
  const [isStopTimeBarEnergy, setIsStopTimeBarEnergy] = useState("");
 
  const [stringDuration, setStringDuration] = useState("h");
  const [stringDurationTwo, setStringDurationTwo] = useState("hour");
  const [durationType, setDurationType] = useState(["hour", "minute"]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [isFieldCompareVal, setIsFieldCompareVal] = useState(
    deviceFieldValue[0]
  );
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [loadingState, setLoadingState] = useState({
    mfmDataLoad: false,
    abtField: false,
    energyMeter: false,
    mfmParameter: false,
    mfmCompare: false,
    abtTable: false,
  });
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
  });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const gridRefTwo = useRef(null);
  const handleFetchError = useErrorHandler();
  useEffect(() => {
    if (gridRefTwo.current) {
      const heightTwo = gridRefTwo.current.offsetHeight;
      setGridHeightTwo(heightTwo);
    }
  }, [gridRefTwo]);

  useEffect(() => {
    if (deviceID) {
      if (isStartTimeMfmFieldCompare && isStopTimeMfmFieldCompare) {
        handleMfmFieldCompare(
          deviceID,
          isFieldCompareVal,
          isStartTimeMfmFieldCompare,
          isStopTimeMfmFieldCompare,
          stringDurationTwo.toLowerCase()
        );
      }
    }
  }, [isFieldCompareVal, isStopTimeMfmFieldCompare, stringDurationTwo]);
  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  const fetchApiDuration = 2 * 60 * 1000;
  useEffect(() => {
    if (id) {
      const callApis = () => {
        handleMfmParameters(id);
        handleMFMDeviceID(id);
      };
  
      callApis();
      const intervalId = setInterval(() => {
        callApis();
      }, fetchApiDuration); 
      return () => clearInterval(intervalId);
    }
  }, [id]);
  useEffect(() => {
    handleFieldsList();
  }, []);
  useEffect(() => {
    if (deviceFieldValue.length > 0) {
      setIsFieldCompareVal(deviceFieldValue[0]);
    }
  }, [deviceFieldValue]);
  useEffect(() => {
    if (!isFieldCompareVal) {
      setLoadingState((prevState) => ({
        ...prevState,
        mfmCompare: false, 
      }));
    }
  }, [isFieldCompareVal]);
  useEffect(() => {
    if (deviceID) {
      if ((isStartTimeBarEnergy && isStopTimeBarEnergy) || stringDuration) {
        handleEnergyMeterGraph(
          deviceID,
          stringDuration,
          isStartTimeBarEnergy,
          isStopTimeBarEnergy
        );
      }
    }
  }, [isStopTimeBarEnergy, stringDuration]);

  useEffect(() => {
    const fetchComparisonData = () => {
      if (deviceID) {
        handleMfmFieldCompare(deviceID, isFieldCompareVal, stringDurationTwo);
      }
    };
    fetchComparisonData();
  }, [isFieldCompareVal, stringDurationTwo]);

  const currentGrid = [
    {
      phase: "R-Phase Current",
      value: mfmData[0]?.device_data?.line_current_R_phase_A
        ? `${mfmData[0]?.device_data?.line_current_R_phase_A.toFixed(2)} A`
        : "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "Y-Phase Current",
      value: mfmData[0]?.device_data?.line_current_Y_phase_A
        ? `${mfmData[0]?.device_data?.line_current_Y_phase_A.toFixed(2)} A`
        : "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "B-Phase Current",
      value: mfmData[0]?.device_data?.line_current_B_phase_A
        ? `${mfmData[0]?.device_data?.line_current_B_phase_A.toFixed(2)} A`
        : "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "Neutral Current" + "\u00A0" + "\u00A0",
      value: mfmData[0]?.device_data?.neutral_current_A
        ? `${mfmData[0]?.device_data?.neutral_current_A.toFixed(2)} A`
        : "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
  ];
  const phaseCurrentVolt = [
    {
      phase: "R-Phase Voltage",
      value: mfmData[0]?.device_data?.phase_A_RMS_voltage_V
        ? `${mfmData[0]?.device_data?.phase_A_RMS_voltage_V.toFixed(2)} V`
        : mfmData[0]?.device_data?.voltage_R_phase_V
        ? `${mfmData[0]?.device_data?.voltage_R_phase_V.toFixed(2)} V`
        : "--",
    },
    {
      phase: "Y-Phase Voltage",
      value: mfmData[0]?.device_data?.phase_B_RMS_voltage_V
        ? `${mfmData[0]?.device_data?.phase_B_RMS_voltage_V.toFixed(2)} V`
        : mfmData[0]?.device_data?.voltage_Y_phase_V
        ? `${mfmData[0]?.device_data?.voltage_Y_phase_V.toFixed(2)} V`
        : "--",
    },
    {
      phase: "B-Phase Voltage",
      value: mfmData[0]?.device_data?.phase_C_RMS_voltage_V
        ? `${mfmData[0]?.device_data?.phase_C_RMS_voltage_V.toFixed(2)} V`
        : mfmData[0]?.device_data?.voltage_B_phase_V
        ? `${mfmData[0]?.device_data?.voltage_B_phase_V.toFixed(2)} V`
        : "--",
    },
    {
      phase: "R-Phase Current",
      value: mfmData[0]?.device_data?.phase_A_RMS_current_A
        ? `${mfmData[0]?.device_data?.phase_A_RMS_current_A.toFixed(2)} A`
        : mfmData[0]?.device_data?.line_current_R_phase_A
        ? `${mfmData[0]?.device_data?.line_current_R_phase_A.toFixed(2)} A`
        : "--",
    },

    {
      phase: "Y-Phase Current",
      value: mfmData[0]?.device_data?.phase_B_RMS_current_A
        ? `${mfmData[0]?.device_data?.phase_B_RMS_current_A.toFixed(2)} A`
        : mfmData[0]?.device_data?.line_current_Y_phase_A
        ? `${mfmData[0]?.device_data?.line_current_Y_phase_A.toFixed(2)} A`
        : "--",
    },

    {
      phase: "B-Phase Current",
      value: mfmData[0]?.device_data?.phase_C_RMS_current_A
        ? `${mfmData[0]?.device_data?.phase_C_RMS_current_A.toFixed(2)} A`
        : mfmData[0]?.device_data?.line_current_B_phase_A
        ? `${mfmData[0]?.device_data?.line_current_B_phase_A.toFixed(2)} A`
        : "--",
    },
  ];

  const meterAlerts = [
    {
      name: "DC Switch",
    },
    {
      name: "Over Current Status",
    },
    {
      name: "Over Voltage Status",
    },
    {
      name: "DC Switch",
    },
  ];
  const powerMetrics = [
    {
      name: "Reactive Power",
      value: mfmData[0]?.device_data?.three_phase_reactive_power_Kvar
        ? `${mfmData[0]?.device_data?.three_phase_reactive_power_Kvar.toFixed(
            2
          )} kVAR`
        : "--",
    },
    // {
    //   name: "Cos θ",
    //   value: "--°"
    //   ,
    // },
    {
      name: "Apparent Power",
      value: mfmData[0]?.device_data?.three_phase_apparent_power_KVA
        ? `${mfmData[0]?.device_data?.three_phase_apparent_power_KVA.toFixed(
            2
          )} kVA`
        : "--",
    },
    {
      name: "Frequency",
      value: mfmData[0]?.device_data?.frequency_Hz
        ? `${mfmData[0]?.device_data?.frequency_Hz.toFixed(2)} Hz`
        : "--",
    },
  ];
  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const handleMFMDeviceID = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmDataLoad: true }));

      const res = await invService.getPlantDevices(id, "abt");
      if (res.status === 200) {
        const fetchData = async () => {
          setDeviceId(res.data[0].id);
          await handleEnergyMeterGraph(res.data[0].id, stringDuration);
          await handleMfmFieldCompare(
            res.data[0].id,
            isFieldCompareVal,
            stringDurationTwo
          );
          await handleDeviceTable(res.data[0].id);
        };
        fetchData();
      }
      setLoadingState((prevState) => ({ ...prevState, mfmDataLoad: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, mfmDataLoad: false }));
      handleFetchError(error, "ABT data");
    }
  };
  const handleFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, abtField: true }));

      const res = await invService.getListDeviceFields("abt");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, mfmDataLoad: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, abtField: false }));
      handleFetchError(error, "ABT fiels list");
    }
  };

  const handleEnergyMeterGraph = async (
    id,
    stringDuration,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, energyMeter: true }));

      const res = await invService.getEnergyMeterGraph(
        id,
        stringDuration,
        start_time,
        end_time,
        "abt"
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const { timestamp_edge, "Energy Generation kWh": energyGeneration } =
            item;
          return { timestamp_edge, "Energy Generation kWh": energyGeneration };
        });

        setEnergyGraphData(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, energyMeter: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, energyMeter: false }));
      handleFetchError(error, "ABT energy meter");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmParameter: true }));

      const res = await invService.getDeviceLevelData(id, "abt");
      if (res.status === 200) {
        setIsMfmData(res.data);
        const acPower = res.data[0]?.device_data?.three_phase_apparent_power_KVA;

        const acVoltage =
          (res.data[0].device_data.voltage_R_phase_V +
            res.data[0].device_data.voltage_Y_phase_V +
            res.data[0].device_data.voltage_B_phase_V) /
          3;

        const acCurrent =
          (res.data[0].device_data.line_current_R_phase_A +
            res.data[0].device_data.line_current_Y_phase_A +
            res.data[0].device_data.line_current_B_phase_A) /
          3;

        setAbtMeterData({
          acPower,
          acVoltage,
          acCurrent,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, mfmParameter: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, mfmParameter: false }));
      handleFetchError(error, "ABT parameter");
    }
  };

  const handleMfmFieldCompare = async (
    id,
    fields,
    start_time,
    end_time,
    filter = stringDurationTwo.toLowerCase()
  ) => {
    try {
      if (!fields || fields.trim() === "" || fields === undefined || fields === null) {
        setMfmFieldCompare([]);

      } else {
        setLoadingState((prevState) => ({ ...prevState, mfmCompare: true }));
        const res = await invService.getMfmCompareFields(
          id,
          fields,
          start_time,
          end_time,
          filter,
          "abt",
          false
        );
        if (res.status === 200) {
          const formattedData = res.data.map((item) => {
            const formattedItem = { timestamp_edge: item.timestamp_edge };
            Object.keys(item).forEach((field) => {
              if (field !== "timestamp_edge" && field !== "device_name") {
                const formattedField = field
                  .replace("_", " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                formattedItem[formattedField] = item[field];
              }
            });
            return formattedItem;
          });
          setMfmFieldCompare(formattedData);
          setLoadingState((prevState) => ({ ...prevState, mfmCompare: false }));
        }
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, mfmCompare: false }));
      handleFetchError(error, "ABT field compare");
    }
  };
  const handleDeviceTable = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, abtTable: true }));

      const res = await invService.getDeviceTable(device_id, "abt");
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedDate = item["Date(YYYY-MM-DD)"]
            ? item["Date(YYYY-MM-DD)"].split("T")[0]
            : "--";
          const formattedItem = { ...item, "Date(YYYY-MM-DD)": formattedDate };

          for (let key in formattedItem) {
            if (typeof formattedItem[key] === "number") {
              formattedItem[key] = formattedItem[key].toFixed(2);
            }
          }

          return formattedItem;
        });

        setDeviceTableData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, abtTable: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, abtTable: false }));
      handleFetchError(error, "ABT data");
    }
  };
  const PowerPlant = [
    {
      title: "Cumulative Export Energy",
      energy: "25999",
      subtitle1: "Active (Fundamental) + Harmonics",
      value1: mfmData[0]?.device_data
        ?.active_export_fundamental_cum_Harmonics_KWh
        ? `${mfmData[0]?.device_data?.active_export_fundamental_cum_Harmonics_KWh.toFixed(
            2
          )} kWh`
        : "--",
      subtitle2: "Active (Fundamental)",
      value2: mfmData[0]?.device_data?.active_export_fundamental_KWh
        ? `${mfmData[0]?.device_data?.active_export_fundamental_KWh.toFixed(
            2
          )} kWh`
        : "--",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/ac_dc_2.svg",
      colorCode: "linear-gradient(180deg, #001C34 0%, #00529A 100%)",
    },
    {
      title: "Cumulative Import Energy",
      energy: "25999",
      subtitle1: "Active (Fundamental) + Harmonics",
      value1: mfmData[0]?.device_data
        ?.active_import_fundamental_cum_Harmonics_KWh
        ? `${mfmData[0]?.device_data?.active_import_fundamental_cum_Harmonics_KWh.toFixed(
            2
          )} kWh`
        : "--",
      subtitle2: "Active (Fundamental)",
      value2: mfmData[0]?.device_data?.active_import_fundamental_KWh
        ? `${mfmData[0]?.device_data?.active_import_fundamental_KWh.toFixed(
            2
          )} kWh`
        : "--",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/ac_dc1.svg",
      colorCode: " linear-gradient(180deg, #976421 0%, #FDA737 100%)",
    },
    {
      title: "Power Factor",
      energy: "25999",
      subtitle1: "R Phase Power Factor",
      value1: mfmData[0]?.device_data?.power_factor_R_phase
        ? `${mfmData[0]?.device_data?.power_factor_R_phase.toFixed(1)}`
        : "--",
      subtitle2: "Y Phase Power Factor",
      value2: mfmData[0]?.device_data?.power_factor_Y_phase
        ? `${mfmData[0]?.device_data?.power_factor_Y_phase.toFixed(1)}`
        : "--",
      subtitle3: "B Phase Power Factor",
      value3: mfmData[0]?.device_data?.power_factor_B_phase
        ? `${mfmData[0]?.device_data?.power_factor_B_phase.toFixed(1)}`
        : "--",
      subtitle4: "Average Power Factor",
      value4: mfmData[0]?.device_data?.power_factor_avg_3_phase
        ? `${mfmData[0]?.device_data?.power_factor_avg_3_phase.toFixed(1)}`
        : "--",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/ac_dc3.svg",
      colorCode: "linear-gradient(180deg, #04724D 0%, #08D892 100%)",
    },
  ];
  const lastActive = mfmData[0]?.device_details?.last_active;
  const formattedDate = lastActive ? lastActive.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d+(\+.*)?$/, (_, year, month, day, hour, minute, second) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let hour12 = parseInt(hour, 10);
    const ampm = hour12 >= 12 ? 'PM' : 'AM';
    if (hour12 > 12) hour12 -= 12;
    else if (hour12 === 0) hour12 = 12;
    return `${day} ${monthNames[parseInt(month, 10) - 1]}, ${year} ${hour12.toString().padStart(2, '0')}:${minute} ${ampm}`;
  }) : "--";
  return (
    <div className="home-container">
      <Grid className="grid-container" container>
        <Grid
          item
          lg={6.7}
          xs={12}
          sm={12}
          md={12}
          className="second_paper"
          sx={{ position: "relative" }}
        >
          <Grid className="grid-container" container>
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="paper_borderless"
            >
              <Paper className="paper_borderless" sx={{gap:"2rem !important"}}>
                <p className="Poppins_24px_black">LINE ABT - Meter</p>
                <div className="flex-start-column" style={{display:"flex", gap:"0.2rem"}}>
                      <p className="Poppins_14px_rem" style={{fontWeight:"400"}}>Last Updated on  <p className="Poppins_14px_rem" style={{ color: "#5E6064", fontWeight: "500", fontStyle: "italic", textAlign: "center" }}>
                 (Data refreshed every 2 mins)
            </p></p>

                      <p className="Poppins_18px_rem">
                      {formattedDate}
                      </p>
                    </div>
              </Paper>
            </Grid>

            {currentGrid.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={2.8}
                xs={12}
                sm={5.9}
                md={5.9}
                className="border_none_transparent"
              >
                <Paper
                  className="paper_padding_one"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <div className="icon_div">
                    <img src={ele.iconSRC} alt="abtRYB" />
                  </div>
                  <div className="flex-start-column">
                    <p className="Poppins_24px_black">{ele.value}</p>
                    <p className="raleway_18px_abt">{ele.phase}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {loadingState.mfmDataLoad && (
            <SimpleBackdrop open={loadingState.mfmDataLoad} />
          )}
        </Grid>

        <Grid item lg={2.45} xs={12} sm={12} md={6} className="abt-first-grid">
          <Paper className="paper_borderless">
            <p className="Poppins_24px_black">Line ABT Meter Alerts</p>
          </Paper>
          {meterAlerts.map((ele, index) => (
            <Paper key={index} className="paper_padding_one_override">
              <div>
                <p className="Poppins_16px_400">{ele.name}</p>
              </div>
              <div className="active_green">
                <p className="raleway_14px_rem" style={{ fontWeight: "700" }}>
                  🟢
                </p>
                <p className="raleway_14px_rem" style={{ fontWeight: "700" }}>
                  Active
                </p>
              </div>
            </Paper>
          ))}
        </Grid>
        <Grid
          item
          lg={2.55}
          xs={12}
          sm={12}
          md={5.9}
          className="paper_padding_one_center"
        >
          <Paper className="paper_column">
            <p className="Poppins_24px_black">Active Power</p>

            <GaugeChart
              id="gauge-chart1"
              animate={false}
              animDelay={0}
              nrOfLevels={20}
              colors={["#EA4228", "#F5CD19", "#5BE12C"]}
              percent={abtMeterData?.acPower?
                abtMeterData.acPower/10000
                  : 0
              }
              arcsLength={[0.3, 0.4, 0.3]}
              arcPadding={0.02}
              cornerRadius={0}
              textColor={"#90E900"}
              hideText={true}
              needleColor="#345243"
            />
            <p className="gauge-values Poppins_16px_white">
              {abtMeterData?.acPower
                ? abtMeterData.acPower.toFixed(2)
                : 0}{" "}
              kW
            </p>
            {/* <Box
              component="img"
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/gaugeSVG.svg"
              alt="guage"
              sx={{
                width: {
                  xs: "13vw",
                  sm: "19vw",
                  md: "24vw",
                  lg: "19.5vw",
                  xl: "18vw",
                },
              }}
            /> */}
          </Paper>
        </Grid>

        {PowerPlant.map((ele, index) => (
          <Grid
            key={index}
            item
            lg={index === 2 ? 4.9 : 3.46}
            xs={12}
            sm={12}
            md={index === 2 ? 12 : 5.9}
            className="grid_padding"
            sx={{
              height: "15rem",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Paper className="cummulative_paper">
              <div className="center_div">
                <div className="image_circle">
                  <img
                    src={ele.PlantIcon}
                    alt="plant"
                    style={{ width: "2rem" }}
                  />
                </div>
                <div className="title_div">
                  {/* <p className="Poppins_18px_rem">Cumulative</p> */}
                  <p className="Poppins_18px_rem">{ele.title}</p>
                </div>
              </div>
           
            </Paper>
            <Paper
              className="cummulative_paper"
              sx={{ justifyContent: "center" }}
            >
              <div
                className="div_info"
                style={{
                  background: ele.colorCode,
                }}
              >
                <p className="raleway_12px_white">{ele.subtitle1}</p>
                <p className="Poppins_16px_white">{ele.value1}</p>
              </div>
              <div
                className="div_info"
                style={{
                  background: ele.colorCode,
                }}
              >
                <p className="raleway_12px_white">{ele.subtitle2}</p>
                <p className="Poppins_16px_white">{ele.value2}</p>
              </div>
              {index === 2 && (
                <div
                  className="div_info"
                  style={{
                    background: ele.colorCode,
                  }}
                >
                  <p className="raleway_12px_white">{ele.subtitle3}</p>
                  <p className="Poppins_16px_white">{ele.value3}</p>
                </div>
              )}
              {index === 2 && (
                <div
                  className="div_info"
                  style={{
                    background: ele.colorCode,
                  }}
                >
                  <p className="raleway_12px_white">{ele.subtitle4}</p>
                  <p className="Poppins_16px_white">{ele.value4}</p>
                </div>
              )}
            </Paper>
            {loadingState.mfmParameter && (
              <SimpleBackdrop open={loadingState.mfmParameter} />
            )}
          </Grid>
        ))}
        <Grid item lg={12} xs={12} sm={12} md={12} className="exc-graph-grid"
         sx={{
          position: fullScreenStates.graph1 ? "fixed" : "relative",
          top: fullScreenStates.graph1 ? "9vh" : "auto",
          width: fullScreenStates.graph1 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph1 ? "92vh" : "auto",
          margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph1 ? 999 : "auto",
          background: "#fff",
        }}>
          <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph1 ? "100%" : "auto"}}>
            <BarPlotForMFM
              isValueData={energyGraphData}
              StartTimer={setIsStartTimeBarEnergy}
              StopTimer={setIsStopTimeBarEnergy}
              openDrawerValue={setDrawerOpen}
              indicateUnit="kWh"
              titleText="Energy Meter"
              calenderClick="on"
              yAxisText="Generated Energy (kWh)"
              tooltipName="Energy Generation"
              stringDurationFun={setStringDuration}
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeBarEnergy
                    ? isStartTimeBarEnergy
                    : new Date(),
                  endDate: isStopTimeBarEnergy
                    ? isStopTimeBarEnergy
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isDesktopView={isDesktopView}
              isFullScreen={fullScreenStates.graph1}
              toggleFullScreen={() => toggleFullScreen('graph1')}
              loadState={loadingState.energyMeter}
            />
          </Paper>
          {loadingState.energyMeter && (
            <SimpleBackdrop open={loadingState.energyMeter} />
          )}
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12} className="exc-graph-grid"
         sx={{
          position: fullScreenStates.graph2 ? "fixed" : "relative",
          top: fullScreenStates.graph2 ? "9vh" : "auto",
          width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
          height: fullScreenStates.graph2 ? "92vh" : "auto",
          margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
          zIndex: fullScreenStates.graph2 ? 999 : "auto",
          background: "#fff",
        }}>
          <Paper className="exc-graph-paper" sx={{height:fullScreenStates.graph2 ? "100%" : "auto"}}>
          {isFieldCompareVal || mfmFieldCompare.length !== 0 || deviceFieldValue.length !== 0 ? ( 
             <MultiDateLineGraph
              isValueData={mfmFieldCompare.length === 0 || deviceFieldValue.length === 0 ? [] : mfmFieldCompare}
              StartTimer={setIsStartTimeMfmFieldCompare}
              StopTimer={setIsStopTimeMfmFieldCompare}
              openDrawerValue={setDrawerOpen}
              titleText="ABT Fields Comparison"
              legendName=""
              calenderClick="on"
              selectedType={setIsFieldCompareVal}
              fieldsDropdata={deviceFieldValue}
              durationTypes={setStringDurationTwo}
              durationDropData={durationType}
              defaultValue={isFieldCompareVal ? isFieldCompareVal : deviceFieldValue[0] }
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeMfmFieldCompare
                    ? isStartTimeMfmFieldCompare
                    : new Date(),
                  endDate: isStopTimeMfmFieldCompare
                    ? isStopTimeMfmFieldCompare
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView}
                isFullScreen={fullScreenStates.graph2}
                toggleFullScreen={() => toggleFullScreen('graph2')}
                loadState={loadingState.mfmCompare}
            />
           ) :  <SimpleBackdrop open={loadingState.mfmCompare} />} 
          </Paper>
          {loadingState.mfmCompare && (
            <SimpleBackdrop open={loadingState.mfmCompare} />
          )}
        </Grid>
        <Grid
          item
          lg={4.6}
          xs={12}
          md={12}
          sm={12}
          className="paper_padding_one"
          sx={{
            alignItems: "center",
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Grid
            className="grid-container"
            container
            sx={{ position: "relative" }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="paper_borderless"
              sx={{
                marginBottom: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "0rem",
                  xxl: "0rem",
                },
              }}
            >
              <Paper
                className="paper_borderless"
                sx={{
                  gap: "2rem",

                  flexDirection: "column",
                }}
              >
                <div
                  className="icon_div"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
                    alt="powerMetrics"
                    style={{ width: "3vw", height: "-webkit-fill-available " }}
                  />
                </div>
                <div>
                  <p className="raleway_18px_abt">
                    Power and Frequency Metrics
                  </p>
                </div>
              </Paper>
            </Grid>
            {powerMetrics.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.85}
                xs={12}
                sm={3.9}
                md={3.9}
                className="metrix-grid"
              >
                <Paper className="metrix-paper">
                  <div className="metrix_div">
                    <p className="raleway_12px_rem">{ele.name}</p>

                    <p
                      className="Poppins_20px_rem" id="abt_value"
                      style={{ color: "#2f67f8" }}
                    >
                      {ele.value}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
            {loadingState.mfmParameter && (
              <SimpleBackdrop open={loadingState.mfmParameter} />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          sm={5.9}
          md={5.95}
          className="paper_padding_nogap"
          sx={{
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Paper className="paper_column">
            <p className="raleway_18px_abt">Average Voltage</p>

            <GaugeChart
              id="gauge-chart1"
              animate={false}
              animDelay={0}
              nrOfLevels={20}
              colors={["#EA4228", "#F5CD19", "#5BE12C"]}
              percent={
               abtMeterData?.acVoltage?abtMeterData?.acVoltage / 100000
                  : 0
              }
              arcsLength={[0.3, 0.4, 0.3]}
              arcPadding={0.02}
              cornerRadius={0}
              textColor={"#90E900"}
              needleColor="#345243"
              hideText={true}
            />
            <p className="gauge-values Poppins_16px_white">
              { abtMeterData?.acVoltage?abtMeterData?.acVoltage.toFixed(2)
                : 0}{" "}
              V
            </p>

            {/* <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/averageVoltage.svg"
              alt="guage"
              style={{ width: "13vw", height: "-webkit-fill-available " }}
            /> */}
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          sm={5.9}
          md={5.95}
          className="paper_padding_nogap"
          sx={{
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Paper className="paper_column">
            <p className="raleway_18px_abt">AC Current</p>
            <GaugeChart
              id="gauge-chart1"
              animate={false}
              animDelay={0}
              nrOfLevels={20}
              colors={["#EA4228", "#F5CD19", "#5BE12C"]}
              percent={abtMeterData?.acCurrent? abtMeterData?.acCurrent/ 100
                  : 0
              }
              arcsLength={[0.3, 0.4, 0.3]}
              arcPadding={0.02}
              cornerRadius={0}
              textColor={"#90E900"}
              needleColor="#345243"
              hideText={true}
            />
            <p className="gauge-values Poppins_16px_white">
              {abtMeterData?.acCurrent ? abtMeterData?.acCurrent.toFixed(2)
                : 0}{" "}
              A
            </p>
            {/* <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/acCurrent.svg"
              alt="guage"
              style={{ width: "13vw", height: "-webkit-fill-available " }}
            /> */}
          </Paper>
        </Grid>
        <Grid
          className="grid_padding"
          ref={gridRefTwo}
          item
          lg={3.3}
          xs={12}
          md={12}
          sm={12}
          sx={{
            borderRadius: "0.625rem !important",
          }}
        >
          <Grid
            container
            className="grid-container"
            sx={{
              height: "100%",
              position: "relative"
            }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="flex_start_container"
            >
              <Paper
                className="paper_borderless_center"
                sx={{
                  alignItems: "center",
                }}
              >
                <p className="raleway_18px_abt">Voltage and Current with THD</p>
              </Paper>
            </Grid>
            {phaseCurrentVolt.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.8}
                xs={12}
                sm={3.9}
                md={3.9}
                className="metrix-grid"
              >
                <Paper
                  className="metrix-paper"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <div className="metrix_div">
                    <p className="raleway_12px_rem">{ele.phase}</p>

                    <p
                      className="Poppins_16px_white"
                      style={{ color: "#2F67F8" }}
                    >
                      {ele.value}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
            {loadingState.mfmParameter && (
              <SimpleBackdrop open={loadingState.mfmParameter} />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="grid-container"
          sx={{ position: "relative" }}
        >
          <TableNoSearchBar
            columns={deviceTableData}
            data={deviceTableData}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
          />
          {loadingState.abtTable && (
            <SimpleBackdrop open={loadingState.abtTable} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AbtMeter;
