import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Backdrop, Modal, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import CloseIcon from "@mui/icons-material/Close";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "8vh 30px 20px 30px",
  backgroundColor: "white",
  color: "white",
};

const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
const CustomizedXaxis = (props) => {
  const {
    selectedDateRangeProp,
    isValueData,
    isFilter,
    stringDurationFun,
    isMobileAndTab,
    isFullScreen,
    toggleFullScreen,
    isDesktopView,
    isOnlyMobile,loadState
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [dayCount, setDayCount] = useState(6);
  const [isMobile, setIsMobile] = useState(false);
  const [isDurationType, setIsDurationType] = useState("30 MIN");
  const [durationType, setDurationType] = useState([
    "15 MIN",
    "30 MIN",
    "Hourly",
  ]);
  const [tempStartDateTime, setTempStartDateTime] = useState(new Date());
  const [tempStopDateTime, setTempStopDateTime] = useState(new Date());
  const formatToDayMonthRef = useRef((value) => value);

  useEffect(() => {
    if (!isEmptyObject(isValueData)) {
      const formatToDayMonth = (label) => {
        const labelString = String(label);

        if (dayCount === 1) {
          const [, timePart] = labelString.split(" ");
          if (!timePart) return "";
          const [hour, minute] = timePart.split(":");
          if (!minute) return "";
          const [time] = minute.split(" ");
          return `${hour}:${time}`;
        } else {
          if (labelString.includes("13:00")) {
            const [datePart] = labelString.split(" ");
            const date = new Date(datePart);
            const day = date.getDate();
            const month = date.toLocaleString("default", { month: "short" });
            return `${day} ${month}`;
          }
        }
        return "";
      };

      formatToDayMonthRef.current = formatToDayMonth;

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          categories: isValueData?.xAxis?.categories,
          labels: {
            formatter: loadState ? "" : formatToDayMonth, 
          },
        },
        chart: {
          type: isValueData?.chart?.type,
          toolbar: {
            autoSelected: "pan",
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: function (value) {
              const dateString =
                isValueData?.xAxis?.categories[value - 1] || "";

              if (dateString) {
                const [datePart, timePart] = dateString.split(" ");
                const [month, day, year] = datePart.split("/");
                const monthName = new Date(`${month}/01/2000`).toLocaleString(
                  "default",
                  { month: "short" }
                );
                return `${day} ${monthName} ${year} ${timePart}`;
              }
              return "";
            },
          },
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              const forecasted = w.config.series[0]?.data[dataPointIndex] || 0;
              const actual = w.config.series[1]?.data[dataPointIndex] || 0;
              if (seriesIndex === 0) {
                if (value !== null && value !== undefined) {
                  return `${value.toFixed(2)}`;
                }
              } else if (seriesIndex === 1) {
                if (value !== null && value !== undefined) {
                  return `${value.toFixed(2)}`;
                }
              } else {
                if (value === 0) {
                  return "No Performance";
                } else {
                  if (value !== null && value !== undefined) {
                    return `${actual < forecasted ? "-" : ""}${Math.abs(
                      value
                    ).toFixed(2)}`;
                  }
                }
              }
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        yaxis:
          isValueData?.yAxis?.map((axis) => ({
            ...axis,
            labels: {
              formatter: (value) => value.toFixed(2),
            },
          })) || [],
      }));
    }
  }, [isValueData]);

  const [options, setOptions] = useState({
    chart: {
      type: isValueData?.chart?.type,
      height: 350,
    },
    xaxis: {
      categories: isValueData?.xAxis?.categories,
      labels: {
        formatter: function (value) {
          return loadState ? "" : formatToDayMonthRef.current(value); // Show only the date for 12:00 PM
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    yaxis: {
      title: {
        text: isValueData?.yaxis?.title.text,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
      tickAmount: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },
    grid: {
      show: false,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function (value) {
          const dateString = isValueData?.xAxis?.categories[value - 1] || "";

          if (dateString) {
            const [datePart, timePart] = dateString.split(" "); // Split into date and time
            const [month, day, year] = datePart.split("/"); // Split date into day, month, and year

            // Convert month to short name (e.g., 01 => Jan)
            const monthName = new Date(`${month}/01/2000`).toLocaleString(
              "default",
              { month: "short" }
            );

            // Return formatted date in the desired format
            return `${day} ${monthName} ${year} ${timePart}`;
          }
          return "";
        },
      },
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const forecasted = w.config.series[0]?.data[dataPointIndex] || 0;
          const actual = w.config.series[1]?.data[dataPointIndex] || 0;
          if (seriesIndex === 0) {
            if (value !== null && value !== undefined) {
              return `${value.toFixed(2)}`;
            }
          } else if (seriesIndex === 1) {
            if (value !== null && value !== undefined) {
              return `${value.toFixed(2)}`;
            }
          } else {
            if (value === 0) {
              return "No Performance";
            } else {
              if (value !== null && value !== undefined) {
                return `${actual < forecasted ? "-" : ""}${Math.abs(
                  value
                ).toFixed(2)}`;
              }
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); // Exit full-screen when user exits
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
  const funDate = (date) => {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const parts = date.split(/[T:+-]/);
    const date1 = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
    return date1;
  };

  const convertToDateFormat = (dateString) => {
    // Parse the input date string "MM/DD/YYYY HH:mm" into a Date object
    const [datePart, timePart] = dateString.split(" ");
    const [month, day, year] = datePart.split("/");

    // Create a Date object with the parsed data
    const date = new Date(`${year}-${month}-${day}T${timePart}:00+05:30`); // India timezone (GMT+0530)

    // Set the time to 00:00:00 (midnight)
    date.setHours(0, 0, 0, 0);

    // Return the Date object
    return date;
  };

  const initialDateRange = selectedDateRangeProp.map((range) => {
    // Get the first category from xAxis if it exists
    const startDate = isValueData?.xAxis?.categories?.[0]
      ? convertToDateFormat(isValueData?.xAxis?.categories[0]) // Use the first category
      : funDate(range.startDate); // Otherwise, use the range.startDate

    // Get the last category from xAxis if it exists
    const endDate = isValueData?.xAxis?.categories?.[
      isValueData.xAxis.categories.length - 1
    ]
      ? convertToDateFormat(
          isValueData.xAxis.categories[isValueData.xAxis.categories.length - 1]
        ) // Use the last category
      : funDate(range.endDate); // Otherwise, use the range.endDate

    // Return the updated range object
    return {
      ...range,
      startDate, // Set the conditional startDate
      endDate, // Set the conditional endDate
    };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);

  const handleRefresh = () => {
    setDayCount(6);
    props.stringDurationFun("h");

    handleStartDateTimeChange(null);
    handleStopDateTimeChange(null);
    const initialDateRange = selectedDateRangeProp.map((range) => {
      // Get the first category from xAxis if it exists
      const startDate = isValueData?.xAxis?.categories?.[0]
        ? convertToDateFormat(isValueData?.xAxis?.categories[0]) // Use the first category
        : funDate(range.startDate); // Otherwise, use the range.startDate

      // Get the last category from xAxis if it exists
      const endDate = isValueData?.xAxis?.categories?.[
        isValueData.xAxis.categories.length - 1
      ]
        ? convertToDateFormat(
            isValueData.xAxis.categories[
              isValueData.xAxis.categories.length - 1
            ]
          ) // Use the last category
        : funDate(range.endDate); // Otherwise, use the range.endDate

      // Return the updated range object
      return {
        ...range,
        startDate, // Set the conditional startDate
        endDate, // Set the conditional endDate
      };
    });
    setSelectedDateRange(initialDateRange);
  };

  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };
  const handleTimings = () => {
    setOpenDrawer(true);
  };

  const handleStopDateTimeChange = (date) => {
    if (date === null) {
      props.StopTimer(date);
    } else {
      setTempStopDateTime(date);
      const currentDate = new Date();
      const selectedDate = new Date(date);
      if (selectedDate > currentDate) {
        date = currentDate.toISOString();
      }
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      let hours, minutes;
      if (selectedDate.toDateString() === currentDate.toDateString()) {
        hours = currentDate.getHours().toString().padStart(2, "0");
        minutes = currentDate.getMinutes().toString().padStart(2, "0");
      } else {
        hours = "23";
        minutes = "59";
      }
      const formattedTimestampStop = `${year}-${month}-${day}T${hours}:${minutes}:00%2B05:30`;
      props.StopTimer(formattedTimestampStop);
      setIsStopTimeEnergy(formattedTimestampStop);
      props.openDrawerValue(openDrawer);
    }
  };

  const handleStartDateTimeChange = (date) => {
    if (date === null) {
      props.StartTimer(date);
    } else {
      setTempStartDateTime(date);
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");

      const formattedTimestampStart = `${year}-${month}-${day}T00:00:00%2B05:30`;
      props.StartTimer(formattedTimestampStart);

      setIsStartTimeEnergy(formattedTimestampStart);
      props.openDrawerValue(openDrawer);
    }
  };
  const getDurationCode = (durationType) => {
    setIsDurationType(durationType);

    let availableFilters;
    if (dayCount === 1) {
      availableFilters = ["15 MIN", "30 MIN", "Hourly"];
    } else {
      availableFilters = [""];
    }

    setDurationType(availableFilters);

    switch (durationType) {
      case "15 MIN":
        return "15min";
      case "30 MIN":
        return "30min";
      case "Hourly":
        return "h";
      default:
        return "";
    }
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    return date > today;
  };
  const calculateDateRangeLength = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    const timeDifference = end.getTime() - start.getTime();

    const dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

    return dayCount;
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleApply = () => {
    let selectedDuration;

    if (dayCount === 1) {
      selectedDuration = isDurationType || "15 MIN";
      props.stringDurationFun(getDurationCode(selectedDuration));
    } else if (dayCount > 1) {
      props.stringDurationFun("h");

      selectedDuration = isDurationType || "Hourly";
    }

    handleStartDateTimeChange(tempStartDateTime);
    handleStopDateTimeChange(tempStopDateTime);
    setSelectedDateRange((prevSelectedDateRange) => [
      {
        ...prevSelectedDateRange[0],
        startDate: funDate(tempStartDateTime),
        endDate: funDate(tempStopDateTime),
      },
      ...prevSelectedDateRange.slice(1),
    ]);
    setOpenDrawer(false);
  };
  const handleClear = (event) => {
    event.preventDefault();
    setDayCount(1);
    props.stringDurationFun("h");

    const currentDate = new Date();
    setTempStopDateTime(currentDate);
    setTempStartDateTime(currentDate);
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p className="Poppins_18px_rem" style={{ color: "#212121" }}>
            {isValueData?.title?.text !== undefined
              ? isValueData.title.text
              : ""}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",

              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Refresh" arrow>
              <RefreshIcon className="icon-css" onClick={handleRefresh} />
            </Tooltip>
            <Tooltip title="Download" arrow>
              <DownloadIcon className="icon-css" onClick={downloadChart} />
            </Tooltip>

            <Tooltip title="Calendar" arrow>
              <CalendarMonthRoundedIcon
                className="icon-css"
                onClick={handleTimings}
              />
            </Tooltip>
            <Tooltip title="FullScreen" arrow>
              <button
                onClick={toggleFullScreen}
                style={{
                  width: "1.6vw !important",
                  height: "fit-content !important",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                {isFullScreen ? (
                  <FullscreenExitIcon className="icon-css" />
                ) : (
                  <FullscreenIcon className="icon-css" />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openDrawer}>
            <Box sx={boxStyle}>
              <div
                onClick={handleClose}
                className="handleclose-subdiv"
                style={{ position: "absolute" }}
              >
                <CloseIcon sx={{ color: "#2F67F8" }} />
              </div>

              <div className="calender-container">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="range_title"
                    style={{
                      width: "fit-content",
                      position: "relative",
                      top: "2rem",
                    }}
                  >
                    <p
                      className="raleway_20px_rem"
                      style={{ color: "black", fontWeight: "700" }}
                    >
                      Range Filters
                    </p>
                  </div>

                  <DateRangePicker
                    months={isMobile ? 1 : 2}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={selectedDateRange}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      const endDate = ranges.selection.endDate;
                      const lengthOfRange = calculateDateRangeLength(
                        startDate,
                        endDate
                      );
                      setDayCount(lengthOfRange);

                      if (startDate === endDate) {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      } else {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      }
                      setSelectedDateRange([ranges.selection]);
                    }}
                  />
                </div>
                {isFilter && (
                  <div className="intervals_container">
                    <div className="interval_filter_combined">
                      <p
                        className="raleway_20px_rem"
                        style={{
                          color: "black",
                          fontWeight: "700",
                          paddingLeft: "1rem",
                        }}
                      >
                        Interval Filters
                      </p>
                      <div className="filter_container">
                        {dayCount === 1 ? (
                          durationType.map((field, index) => {
                            return (
                              <div
                                key={index}
                                className="filter_button"
                                style={{
                                  backgroundColor:
                                    isDurationType === field
                                      ? "rgb(222 231 246)"
                                      : "transparent",
                                }}
                                onClick={() => getDurationCode(field)}
                              >
                                <p className="rdrStaticRangeLabel">
                                  {field
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <div className="expert_div">
                            <p className="raleway_20px_rem  c_red">
                              Filters are only available for single day
                              selection
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        className="btn_style"
                        style={{
                          backgroundColor: "#2F67F8",
                        }}
                        onClick={handleApply}
                      >
                        {" "}
                        <p className="raleway_20px_rem">Apply</p>
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        className="btn_style"
                        style={{
                          backgroundColor: "#FF472E",
                        }}
                        onClick={handleClear}
                      >
                        <p className="raleway_20px_rem">Clear</p>
                      </Button>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "end",
                      gap: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      className="btn_style"
                      style={{
                        backgroundColor: "#2F67F8",
                      }}
                      onClick={handleApply}
                    >
                      {" "}
                      <p className="raleway_20px_rem">Apply</p>
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      className="btn_style"
                      style={{
                        backgroundColor: "#FF472E",
                      }}
                      onClick={handleClear}
                    >
                      <p className="raleway_20px_rem">Clear</p>
                    </Button>
                  </div>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      )}

      <div
        style={{
          height:
            isOnlyMobile && isFullScreen
              ? "100vw"
              : isEmptyObject(isValueData)
              ? "350px"
              : "90%",
          width: isOnlyMobile && isFullScreen ? "84vh" : "100%",
          padding: "1vw",
          transform:
            isOnlyMobile && isFullScreen ? "rotate(90deg)" : "rotate(0deg)",
          marginTop: isOnlyMobile && isFullScreen ? "6rem" : "0rem",
          marginLeft: isOnlyMobile && isFullScreen ? "-7rem" : "0rem",
        }}
        ref={chartContainerRef}
        id="prediction-chart"
      >
        {isEmptyObject(isValueData) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">{loadState ? "" : "No Data"}</p>
          </div>
        ) : (
          <Chart
            id="unixChart"
            options={options}
            series={isValueData?.series}
            type="area"
            width={"100%"}
            height={
              isFullScreen && isMobileAndTab
                ? "300"
                : isFullScreen && isDesktopView
                ? "500"
                : "320"
            }
          />
        )}
      </div>
    </div>
  );
};

export default CustomizedXaxis;
