import React, { useState, useRef, useEffect, useMemo, memo } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import "./graphCSS.css";
import "./BarPlotForIOT.scss";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import Divider from "@mui/material/Divider";
import Dropdown from "react-dropdown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
const ImgBarchart = memo((props) => {
  const {
    isValueData,
    titleText,
    chartType,
    tooltipName,
    indicateUnit,
    stringDurationFun,
    minmax,
    selectedDateRangeProp,
    isFilter,
    isMobileAndTab,
    isDesktopView,
    isFullScreen,
    toggleFullScreen,
    yaxisText,
    annotation,
    annotationText,
    annotationTextTwo,
    annotationTwo,
  } = props;

  function generateTimeData() {
    const timeData = [];
    const currentDate = new Date();
    const dateStr = currentDate.toISOString().split("T")[0]; // Get YYYY-MM-DD format

    const startHour = 6; // 6 AM
    const endHour = 18; // 6 PM

    for (let hour = startHour; hour <= endHour; hour++) {
      const timestamp = new Date();
      timestamp.setHours(hour, 0, 0, 0);

      // Format timestamp as "YYYY-MM-DDTHH:mm:ss"
      const formattedTimestamp = `${dateStr}T${timestamp
        .toTimeString()
        .slice(0, 8)}`;

      const value = isValueData[hour - startHour]; // Matching index with isValueData

      timeData.push({ type: formattedTimestamp, value: value });
    }

    return timeData;
  }
  const timeData = generateTimeData();

  const seriesData = useMemo(() => {
    return timeData ? timeData.map((item) => item.value) : [];
  }, [isValueData]);
  const xaxisLabels = useMemo(() => {
    return timeData
      ? timeData.map((item) => {
          return item.type;
        })
      : [];
  }, [isValueData]);
  const [xaxisData, setXaxisData] = useState(xaxisLabels);

  const [series, setSeries] = useState([
    {
      name: tooltipName,
      data: seriesData,
    },
  ]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);
  const [isDurationType, setIsDurationType] = useState("Daily");

  const [options, setOptions] = useState({
    chart: { type: chartType },
    stroke: {
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        distributed: true,

        horizontal: false,
        columnWidth: "30%",
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: xaxisData,
      type: "datetime",
      labels: {
        formatter: function (value) {
          // Format the label as desired, e.g., 'HH:mm'
          return new Date(value).toLocaleString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: yaxisText,
      },
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },

        style: {
          colors: "#000000",
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      curve: "smooth",
      xaxis: { lines: { show: true } },
    },
    annotations: {
      yaxis: [
        annotationText
          ? {
              y: annotation,
              borderColor: "#FF4560",
              strokeDashArray: 5,
              label: {
                borderColor: "#FF4560",
                style: { color: "#fff", background: "#FF4560" },
                text: `${annotationText}: ${annotation} ${indicateUnit}`,
              },
            }
          : {},
        annotationTextTwo
          ? {
              y: annotationTwo,
              borderColor: "#0000FF ",
              strokeDashArray: 5,
              label: {
                borderColor: "#0000FF ",
                style: { color: "#fff", background: "#0000FF " },
                text: `${annotationTextTwo}: ${annotationTwo} ${indicateUnit}`,
              },
            }
          : {},
      ],
    },

    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return "";
          }
          return value.toFixed(2) + indicateUnit;
        },
      },
    },
    toolbar: {
      show: false,
    },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen();
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);

  useEffect(() => {
    setSeries([
      {
        name: tooltipName,
        data: seriesData,
      },
    ]);

    setXaxisData(xaxisLabels);
    setOptions((prevOptions) => ({
      ...prevOptions,

      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "30%",
        },
        area: {
          colors: (series) => {
            // Set the color of the area based on the value
            return series.map(
              (dataPoint) => (dataPoint < 0 ? "#ff0000" : "#0088ee") // Red for negative, Blue for positive
            );
          },
        },
      },
      annotations: {
        yaxis: [
          annotationText
            ? {
                y: annotation,
                borderColor: "#FF4560",
                strokeDashArray: 5,
                label: {
                  borderColor: "#FF4560",
                  style: { color: "#fff", background: "#FF4560" },
                  text: `${annotationText}: ${annotation} ${indicateUnit}`,
                },
              }
            : {},
          annotationTextTwo
            ? {
                y: annotationTwo,
                borderColor: "#0000FF ",
                strokeDashArray: 5,
                label: {
                  borderColor: "#0000FF ",
                  style: { color: "#fff", background: "#0000FF " },
                  text: `${annotationTextTwo}: ${annotationTwo} ${indicateUnit}`,
                },
              }
            : {},
        ],
      },

      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return "";
            }
            return value.toFixed(2) + indicateUnit;
          },
        },
      },
      yaxis: {
        ...prevOptions.yaxis,
        min: minmax?.[0],
        max: minmax?.[1],
        title: {
          text: yaxisText,
        },
        labels: {
          formatter: function (value) {
            return Math.round(value);
          },

          style: {
            colors: "#000000",
          },
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        labels: {
          formatter: function (value) {
            return new Date(value).toLocaleString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            });
          },
        },
        type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: "#000000",
          },
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft =
        scrollableContainerRef.current.scrollWidth;
    }
  }, [
    isValueData,
    isFullScreen,
    xaxisLabels,
    seriesData,
    tooltipName,
    indicateUnit,
    titleText,
  ]);

  const chartContainerRef = useRef(null);

  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);
    const svg64 = btoa(xml);
    const imgSrc = `data:image/svg+xml;base64,${svg64}`;
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
  const durationType = ["Daily", "Weekly", "Monthly", "Yearly"];
  const handleChangeDeviceDuration = (device) => {
    setIsDurationType(device);
    if (device === "Daily") {
      props.stringDurationFun("D");
    } else if (device === "Weekly") {
      props.stringDurationFun("W");
    } else if (device === "Monthly") {
      props.stringDurationFun("ME");
    } else if (device === "Yearly") {
      props.stringDurationFun("YE");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p className="Poppins_18px_rem" style={{ color: "#212121" }}>
            {isValueData?.title?.text ?? titleText}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Download" arrow>
            <DownloadIcon onClick={downloadChart} className="icon-css" />
          </Tooltip>

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
              className="icon-css"
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={toggleFullScreen}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon className="icon-css" />
              ) : (
                <FullscreenIcon className="icon-css" />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />

      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
          durationType={durationType}
          handleChangeDuration={handleChangeDeviceDuration}
          isDurationType={isDurationType}
          isFilter={isFilter}
        />
      )}

      <div
        style={{
          height: Object.keys(isValueData).length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {Object.keys(isValueData).length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "350px",

              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <div
            ref={scrollableContainerRef}
            className="scrollable_chat-container"
          >
            <div
              style={{
                width: xaxisData.length > 30 ? xaxisData.length * 50 : "100%",
              }}
            >
              <Chart
                options={options}
                series={series}
                type={chartType}
                height={
                  isFullScreen && isMobileAndTab
                    ? "300"
                    : isFullScreen && isDesktopView
                    ? "500"
                    : "350"
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default ImgBarchart;
