import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "../../ExecutiveDashboard/ExceutiveDashboard.scss";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import InverterDetails from "./InverterDetails";
import "./Inverter.scss";
import TableNoSearchBar from "../../../Common/TableNoSearchBar";
import { useHistory } from "react-router-dom";
import useErrorHandler from "../../../../utils/errorHandler";
import { useMobile } from "../../../Context/MobileContext/MobileContext";
import EnergyGenerated from "../../../Common/EnergyGenerated";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import TimestampFormatter from "../../../Common/TimestampFormatter";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ModalCalender from "../../../Common/ModalCalender";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
const invService = new CosmicInverterService();
const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
function Inverter({ activeInv }) {
  const circularProgressRef = useRef(null);
  const [loadingState, setLoadingState] = useState({
    powerinfo: false,
    deviceTable: false,
    invList: false,
    idealPower: false,
    fieldList: false,
    invPara: false,
    energyOne: false,
    rulresp: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [newInvArray, setNewInvArray] = useState([]);
  const [powerarray, setPowerArray] = useState({});
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceId, setDeviceId] = useState(activeInv.device_details.id);
  const [activeInverter, setActiveInverter] = useState(activeInv);
  const [idealGen, setIdealGen] = useState({});
  const [invEnergy, setInvEnergy] = useState({});
  const [defaultField, setDefaultField] = useState("");
  const [inverterParameters, setInverterParameters] = useState({});
  const [isRulData, setIsARulData] = useState([]);
  const [gridHeightTwo, setGridHeightTwo] = useState(0);

  const [openDrawerCal, setOpenDrawerCal] = useState(false);
  const [isStopTime, setIsStopTime] = useState("");

  const [isStartTime, setIsStartTime] = useState("");
  const emailType = localStorage.getItem("email");

  const { isMobile } = useMobile();
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 6);
  const formattedStartDate = `${startDate.getFullYear()}-${(
    startDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
  const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  
  const colorData = [
    { title: "Optimal", colorCode: "#5FAA5E" },
    { title: "Stable", colorCode: "#4AA9FD" },
    { title: "Warning", colorCode: "#EB9069" },
    { title: "Critical", colorCode: "#FD5757" },
  ];

  useEffect(() => {
    if (circularProgressRef.current) {
      const svgElement = circularProgressRef.current.querySelector("svg");
      if (svgElement) {
        svgElement.setAttribute("viewBox", "0 0 150 100");
      }
    }
  }, []);

  //      const demoPlant = plantListsArray[0]?.name === "Demo Plant "
  //  // Calculate 15% of output_power_kW
  // const fifteenPercentOutput = activeInverter?.device_data?.output_power_kW !== null ? activeInverter?.device_data?.output_power_kW * 0.15 : "--";

  const handleFetchError = useErrorHandler();
  const fetchApiDuration = 2 * 60 * 1000;
  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        handleDeviceTable(deviceId),
        handleInverterPowerInfo(deviceId),
        handleInvParameters(deviceId),
        handleIdealGeneration(deviceId),
        handleInverterEnergy(deviceId),
        handleFieldsList(deviceId),
      ]);
    };

    const fetchPeriodically = () => {
      const intervalId = setInterval(async () => {
        if (deviceId) {
          await Promise.all([
            handleInverterPowerInfo(deviceId),
            handleInvParameters(deviceId),
            handleIdealGeneration(deviceId),
            handleInverterEnergy(deviceId),
          ]);
        }
      }, fetchApiDuration);

      return intervalId;
    };

    fetchInitialData();
    const intervalId = fetchPeriodically();

    return () => clearInterval(intervalId);
  }, [deviceId]);

  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);

  useEffect(() => {
    handleInvertersList(id);
  }, [id]);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    const day = String(today.getDate()).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}-${day}`;
  };
  // useEffect(() => {
  //   if (activeInv?.device_details?.id) {
  //     if (isStartTime && isStopTime) {
  //       handleRULData(
  //         id,
  //         decodeURIComponent(isStartTime),
  //         decodeURIComponent(isStopTime)
  //       );
  //     } else {
  //       handleRULData(
  //         id,
  //         `${getTodayDate()}T00:00:00+05:30`,
  //         `${getTodayDate()}T23:59:59+05:30`
  //       );
  //     }
  //   }
  // }, [isStopTime, id, deviceId]);

  useEffect(() => {
    setActiveInverter(activeInv);
    setDeviceId(activeInv.device_details.id);
    handleDeviceTable(activeInv.device_details.id);
    handleInverterPowerInfo(activeInv.device_details.id);
  }, [activeInv]);
  const handleFieldsList = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter", device_id);
      if (res.status === 200) {
        setDefaultField(res.data[0]);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };
  const handleTimings = () => {
    setOpenDrawerCal(true);
  };
  const handleRULData = async (plantid, start, end) => {
    const payload = {
      equipment_name: activeInverter.device_details.description,
      device_id: `${deviceId}`,

      plant_id: plantid,
      start_time: start,
      end_time: end,
    };
    try {
      setLoadingState((prevState) => ({ ...prevState, rulresp: true }));

      const res = await invService.postRUL(payload);
      if (res.status === 200) {
        setIsARulData(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, rulresp: false }));
    } catch (error) {
      handleFetchError(error, "An error occurred during the RUL.");

      setLoadingState((prevState) => ({ ...prevState, rulresp: false }));
    }
  };
  const handleInverterPowerInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: true }));
      const res = await invService.getInverterOverview(id, "inverter");
      if (res.status === 200) {
        setPowerArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
    } catch (error) {
      setPowerArray([]);

      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleInvParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invPara: true }));

      const res = await invService.getInvParameter(id);
      if (res.status === 200) {
        setInverterParameters(res.data);
        setLoadingState((prevState) => ({ ...prevState, invPara: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invPara: false }));
      handleFetchError(error, "inverter parameters");
    }
  };
  const handleIdealGeneration = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, idealPower: true }));
      const res = await invService.getIdealGeneration(id);
      if (res.status === 200) {
        const data = JSON.parse(res.data);
        setIdealGen(data);
        setLoadingState((prevState) => ({ ...prevState, idealPower: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, idealPower: false }));
      setIdealGen({});

      handleFetchError(error, "Ideal Generation");
    }
  };

  const handleInverterEnergy = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, energyOne: true }));
      const res = await invService.getInvLevelEnergyGen(id);
      if (res.status === 200) {
        setInvEnergy(res.data);
        setLoadingState((prevState) => ({ ...prevState, energyOne: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, energyOne: false }));
      handleFetchError(error, "energy");
    }
  };

  const handleDeviceTable = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceTable: true }));
      const res = await invService.getDeviceTable(device_id, "inverter");
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedDate = item["Date(YYYY-MM-DD)"]
            ? item["Date(YYYY-MM-DD)"].split("T")[0]
            : "--";
          const formattedItem = { ...item, "Date(YYYY-MM-DD)": formattedDate };

          // Iterate through keys to apply toFixed(2) on numeric values
          for (let key in formattedItem) {
            if (typeof formattedItem[key] === "number") {
              formattedItem[key] = formattedItem[key].toFixed(2);
            }
          }
          return formattedItem;
        });

        setDeviceTableData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, deviceTable: false }));
      }
    } catch (error) {
      setDeviceTableData([]);

      setLoadingState((prevState) => ({ ...prevState, deviceTable: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id, "inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        setLoadingState((prevState) => ({ ...prevState, invList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatTitle = (title) => {
    return title
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  return (
    <>
      <Grid
        container
        className="inv_names_container"
        sx={{ backgroundColor: "transparent !important" }}
      >
        <Grid
          item
          // lg={
          //   emailType === "demo_us@smarttrak.co" ||
          //   emailType === "demo_in@smarttrak.co"
          //     ? 7
          //     : 5.8
          // }
          lg={5.8}
          xs={12}
          sm={12}
          md={12}
          className="only_flex-start"
          sx={{ position: "relative" }}
        >
          <Paper className="capacity_paper">
            <div className="flex-start_space-between">
              <div className="ideal_inv_box">
                <span className="Poppins_32px_rem" style={{ color: "#2F67F8" }}>
                  {activeInverter.device_details.name.charAt(0).toUpperCase() +
                    activeInverter.device_details.name.slice(1).toLowerCase()}
                  {` (${
                    activeInverter?.device_details?.device_specific_details
                      ?.capacity_kVA ?? "--"
                  }kVA)`}
                </span>
                <div className="space-btn-center">
                  <div className="flex-start-column">
                    <p
                      className="Poppins_14px_rem"
                      style={{
                        fontWeight: "400",
                        display: "flex",
                        gap: "0.5rem",
                      }}
                    >
                      Last Updated on{" "}
                      <p
                        className="Poppins_14px_rem"
                        style={{
                          color: "#5E6064",
                          fontWeight: "500",
                          fontStyle: "italic",
                          textAlign: "center",
                        }}
                      >
                        (Data refreshed every 2 mins)
                      </p>
                    </p>

                    <p className="Poppins_18px_rem">
                      {inverterParameters?.device_details?.last_active ? (
                        <TimestampFormatter
                          dateString={
                            inverterParameters?.device_details?.last_active
                          }
                        />
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>

                  <div></div>
                </div>
                <div className="flex_container gap_1vw">
                  <div className="only_column_gap">
                    <p className="raleway_16px_rem">Ideal Generated Power</p>
                    <div className="flex_baseline">
                      {idealGen.output_power !== null &&
                      !isEmptyObject(idealGen) ? (
                        <>
                          <p className="Poppins_36px_rem">
                            {
                              // demoPlant && idealGen.output_power === 0 ? fifteenPercentOutput :
                              idealGen.output_power.toFixed(2)
                            }
                          </p>
                          <p
                            className="Poppins_18px_rem"
                            style={{ fontFamily: "Raleway" }}
                          >
                            kW
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="Poppins_36px_rem">--</p>
                          <p
                            className="Poppins_18px_rem"
                            style={{ fontFamily: "Raleway" }}
                          >
                            kW
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="only_column_gap">
                    <p className="raleway_16px_rem">Generated Power</p>
                    <div className="flex_baseline">
                      {activeInverter.device_data !== null &&
                      activeInverter.device_data["output_power_kW"] !== null ? (
                        <>
                          <p className="Poppins_36px_rem">
                            {activeInverter.device_data.output_power_kW.toFixed(
                              2
                            )}
                          </p>
                          <p
                            className="Poppins_18px_rem"
                            style={{ fontFamily: "Raleway" }}
                          >
                            kW
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="Poppins_36px_rem">--</p>
                          <p
                            className="Poppins_18px_rem"
                            style={{ fontFamily: "Raleway" }}
                          >
                            kW
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    activeInverter?.device_data !== null &&
                    activeInverter?.device_data?.temperature_C !== 0
                      ? "space-between"
                      : "center",
                  padding: "1rem",
                  width: "34%",
                  borderRadius: "0.6rem",
                  border: "1px solid #D8D8D8",
                }}
                className="status_temp_container"
              >
                <div className="invstatus-container">
                  <div
                    className="green-status"
                    style={{
                      flexDirection:
                        activeInverter.device_details.status === true
                          ? "row"
                          : "row-reverse",
                      backgroundColor:
                        activeInverter.device_details.status === true
                          ? "green"
                          : "red",
                    }}
                  >
                    <p className="raleway_8px_rem">
                      {activeInverter.device_details.status === true
                        ? "ON"
                        : "OFF"}
                    </p>
                    <div
                      style={{
                        backgroundColor:
                          activeInverter.device_details.status === true
                            ? "rgb(113 213 69)"
                            : "#de6c64",
                        border:
                          activeInverter.device_details.status === true
                            ? "1px solid #21ad3d"
                            : "1px solid #c3392f",
                        width: "0.5rem",
                        height: "0.5rem",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                  <p className="Poppins_16px_rem">Status</p>
                </div>
                {activeInverter?.device_data !== null &&
                  activeInverter?.device_data?.temperature_C !== 0 && (
                    <div className="invTemp-container">
                      {activeInverter?.device_data !== null &&
                      activeInverter?.device_data?.temperature_C !== null ? (
                        <p className="Poppins_16px_rem">
                          {activeInverter.device_data.temperature_C}°C
                        </p>
                      ) : (
                        <p className="Poppins_16px_rem">--°C</p>
                      )}
                      <p className="Poppins_16px_rem">Temp</p>
                    </div>
                  )}
              </div>
            </div>
            <div className="inv_genPower">
              <div className="only_column_gap">
                <p className="raleway_16px_rem" style={{ color: "#212121" }}>
                  Today's Energy
                </p>
                <div className="flex_baseline">
                  {activeInverter.device_data !== null &&
                  activeInverter.device_data["todays_energy_kWh"] !== null ? (
                    <>
                      <p className="Poppins_36px_rem">
                        {activeInverter.device_data.todays_energy_kWh}
                      </p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        kWh
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="Poppins_36px_rem">--</p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        kWh
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="only_column_gap">
                <p className="raleway_16px_rem">Monthly Energy</p>
                <div className="flex_baseline">
                  {powerarray.past_30days_generated_energy_kWh !== null &&
                  powerarray.past_30days_generated_energy_kWh !== undefined ? (
                    <>
                      <p className="Poppins_36px_rem">
                        {powerarray.past_30days_generated_energy_kWh.toFixed(2)}
                      </p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        kWh
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="Poppins_36px_rem">--</p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        kWh
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="only_column_gap">
                <p className="raleway_16px_rem" style={{ color: "#212121" }}>
                  Total Run Time
                </p>
                <div className="flex_baseline">
                  {activeInverter.device_data !== null &&
                  activeInverter.device_data["time_run_total_h"] !== null ? (
                    <>
                      <p className="Poppins_36px_rem">
                        {activeInverter.device_data.time_run_total_h}
                      </p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        h
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="Poppins_36px_rem">--</p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontFamily: "Raleway" }}
                      >
                        h
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Paper>
          {loadingState.powerinfo && (
            <SimpleBackdrop open={loadingState.powerinfo} />
          )}
        </Grid>
        <Grid
          item
          // lg={
          //   emailType === "demo_us@smarttrak.co" ||
          //   emailType === "demo_in@smarttrak.co"
          //     ? 4.9
          //     : 3
          // }
          lg={3}
          xs={12}
          // md={
          //   emailType === "demo_us@smarttrak.co" ||
          //   emailType === "demo_in@smarttrak.co"
          //     ? 12
          //     : 5.9
          // }
          md={5.9}
          sm={12}
          className="donut_grid_exc"
          id="power_analysis_id"
        >
          <EnergyGenerated customWidth="12vw" isValueData={invEnergy} />

          {loadingState.energyOne && (
            <SimpleBackdrop open={loadingState.energyOne} />
          )}
        </Grid>
        <Grid
            className="total_power_container"
            item
            lg={3}
            xs={12}
            md={6}
            sx={{ position: "relative" }}
          >
            <Paper className="inv-insight-one">
              <p
                className="Poppins_18px_rem"
                style={{ fontWeight: "600", paddingTop: "1rem" }}
              >
                Remaining Useful Life
              </p>
              <div
                className="semicircle-scale"
                style={{ position: "relative" }}
                id="circularBarTwo"
              >
                <div ref={circularProgressRef}>
                  <CircularProgressbar
                    value={
                      inverterParameters?.device_details?.rul_predicted
                        ? inverterParameters?.device_details?.rul_predicted
                        : 0
                    }
                    circleRatio={0.5}
                    strokeLineCap="square"
                    styles={{
                      root: {
                        transform: "rotate(0.75turn)",
                      },
                      path: { stroke: "#90E900" },
                      trailColor: "grey",
                      backgroundColor: "red",
                    }}
                  />
                </div>
                <p className="Poppins_36px_rem">{`${
                  inverterParameters?.device_details?.rul_predicted
                    ? inverterParameters?.device_details?.rul_predicted
                    : 0
                }%`}</p>
              </div>
              <p
                className="Poppins_16px_rem"
                style={{ fontWeight: "400", paddingBottom: "1rem" }}
              >
                {`RUL is estimated based on the readings from the last week:
                from ${formattedStartDate} 03:30:00 to ${formattedEndDate}
                08:30:001`}
              </p>
            </Paper>
            {loadingState.invPara && (
              <SimpleBackdrop open={loadingState.invPara} />
            )}
          </Grid>
        {/* {emailType === "demo_us@smarttrak.co" ||
        emailType === "demo_in@smarttrak.co" ? (
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="donut_grid_exc"
            id="power_analysis_id"
          >
            <Grid container className="grid-container">
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="exc-graph-paper"
              >
                <Paper className="exc-graph-paper" id="justify-start">
                  <p className="Poppins_18px_rem">Remaining Useful Life </p>
                </Paper>
              </Grid>

              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="exc-graph-paper"
              >
                <Paper className="exc-graph-paper flex_crct gap_1vw ">
                  <div className="expert_div   metric-box">
                    <p className="Poppins_18px_rem equipment_width">Equipment ID :</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "1" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={[]}
                          isMulti={false}
                          isDisabled={true}
                          value={
                            activeInverter && activeInverter.device_details
                              ? {
                                  value:
                                    activeInverter.device_details.description,
                                  label:
                                    activeInverter.device_details.description,
                                }
                              : { value: "--", label: "--" }
                          }
                          placeholder=""
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="expert_div   metric-box">
                    <p className="Poppins_18px_rem div_width">Timeframe :</p>
                    <Tooltip title="Calendar" arrow>
                      <CalendarMonthRoundedIcon
                        onClick={handleTimings}
                        className="icon-css"
                      />
                    </Tooltip>
                    {openDrawerCal && (
                      <ModalCalender
                        openDrawer={openDrawerCal}
                        setOpenDrawer={setOpenDrawerCal}
                        handleStopDateChange={setIsStopTime}
                        handleStartDateChange={setIsStartTime}
                        singledaySelect={false}
                        futuredaydisable={true}
                        selectedDateRangeVal={[
                          {
                            startDate: isStartTime ? isStartTime : new Date(),
                            endDate: isStopTime ? isStopTime : new Date(),
                            key: "selection",
                          },
                        ]}
                      />
                    )}
                    <div
                      className="timeframe_cal cursor_p"
                      onClick={handleTimings}
                    >
                      <p className="Poppins_14px_rem font_400 ">
                        {isStartTime === isStopTime
                          ? new Date().toISOString().split("T")[0]
                          : `${isStartTime.split("T")[0]} to ${
                              isStopTime.split("T")[0]
                            }`}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                lg={2.9}
                xs={12}
                sm={12}
                md={5.9}
                className="exc-graph-paper"

              >
                <Paper className="energy-paper " id="justify-start">
                  <div className="donut_grid_exc height_100">
                    <p className="Poppins_18px_rem">Equipment Status </p>
                    <div className="expert_div flex_col align_item div_width  gap_1vw">
                      {colorData.map((item, index) => (
                        <div className="foo_one div_width" key={index}>
                          <p className="raleway_14px_rem">{item.title}</p>
                          <p
                            className="padd_1vw border_20"
                            style={{ backgroundColor: item.colorCode }}
                          ></p>
                        </div>
                      ))}
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                lg={3.3}
                xs={12}
                sm={12}
                md={6}
                className="exc-graph-paper"
                sx={{
                  position: "relative",

                }}
              >
                <Paper className="energy-paper" id="justify-start">
                  <div className="donut_grid_exc height_100">
                    <p className="Poppins_18px_rem"> Mean Time To Failure</p>
                    <div className="expert_div flex_col  div_width  gap_1vw height_100">
                      <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/hour_svgs.svg" />
                     
                      <p
                        className="Poppins_48px_rem font_600"
                        style={{ color: "#ff0000" }}
                      >
                        {isRulData?.mean_time_to_failures_ms &&
                          `${isRulData?.mean_time_to_failures_ms.toFixed(2)}
                          ${isRulData?.unit}`}
                      </p>
                    </div>
                  </div>
                </Paper>
                {loadingState.rulresp && (
                  <SimpleBackdrop open={loadingState.rulresp} />
                )}
              </Grid>
              <Grid
                    item
                    lg={5.5}
                    xs={12}
                    sm={12}
                    md={12}
                    className="exc-graph-paper"
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Paper
                      className="energy-paper height_100"
                      id="rul_tables"
                    >
                      <div className="expert_div flex_col align_item div_width gap_1vw ">
                        <TableContainer
                          className="rul-scrollbar-abt"
                          component={Paper}
                          minHeight={650}
                          sx={{ zIndex: "0" }}
                        >
                          <div className="rul_table_max">
                            <Table
                              stickyHeader
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead id="alarmCell" className="padd_1vw">
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    className="alaram-check"
                                    sx={{
                                      backgroundColor: "#E6EEF5 !important",
                                    }}
                                  >
                                    <p className="Poppins_16px_rem padd_1vw">
                                      Fault Category
                                    </p>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    className="alaram-check"
                                    sx={{
                                      backgroundColor: "#E6EEF5 !important",
                                    }}
                                  >
                                    <p className="Poppins_16px_rem">
                                      {" "}
                                      Fault Probability (%)
                                    </p>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {isRulData?.tabledata?.length > 0 ? (
                                  isRulData.tabledata.map((row, index) => (
                                    <TableRow
                                      className="padd_1vw"
                                      key={index}
                                      sx={{
                                        textTransform: "capitalize",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          cursor: "pointer",
                                          
                                        }}
                                        align="left"
                                      >
                                        <p className="Poppins_16px_rem font_400 padd_1vw">
                                          {" "}
                                          {formatTitle(row.title)}
                                        </p>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          cursor: "pointer",
                                          //  borderBottom:
                                          //    index === 2
                                          //      ? "0px solid rgba(224, 224, 224, 1)"
                                          //      : "1px solid rgba(224, 224, 224, 1)",
                                        }}
                                        align="left"
                                      >
                                        <p className="Poppins_16px_rem font_400 ">
                                          {row.val}
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow
                                    className="padd_1vw"
                                    sx={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      colSpan={3}
                                      sx={{
                                        cursor: "pointer",
                                        borderBottom:
                                          "0px solid rgba(224, 224, 224, 1)",
                                      }}
                                      align="center"
                                    >
                                      <p className="Poppins_14px_rem font_400 ">
                                        No Data Available
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>
                        </TableContainer>
                      </div>
                    </Paper>
                    {loadingState.rulresp && (
                      <SimpleBackdrop open={loadingState.rulresp} />
                    )}
                  </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            className="total_power_container"
            item
            lg={3}
            xs={12}
            md={6}
            sx={{ position: "relative" }}
          >
            <Paper className="inv-insight-one">
              <p
                className="Poppins_18px_rem"
                style={{ fontWeight: "600", paddingTop: "1rem" }}
              >
                Remaining Useful Life
              </p>
              <div
                className="semicircle-scale"
                style={{ position: "relative" }}
                id="circularBarTwo"
              >
                <div ref={circularProgressRef}>
                  <CircularProgressbar
                    value={
                      inverterParameters?.device_details?.rul_predicted
                        ? inverterParameters?.device_details?.rul_predicted
                        : 0
                    }
                    circleRatio={0.5}
                    strokeLineCap="square"
                    styles={{
                      root: {
                        transform: "rotate(0.75turn)",
                      },
                      path: { stroke: "#90E900" },
                      trailColor: "grey",
                      backgroundColor: "red",
                    }}
                  />
                </div>
                <p className="Poppins_36px_rem">{`${
                  inverterParameters?.device_details?.rul_predicted
                    ? inverterParameters?.device_details?.rul_predicted
                    : 0
                }%`}</p>
              </div>
              <p
                className="Poppins_16px_rem"
                style={{ fontWeight: "400", paddingBottom: "1rem" }}
              >
                {`RUL is estimated based on the readings from the last week:
                from ${formattedStartDate} 03:30:00 to ${formattedEndDate}
                08:30:001`}
              </p>
            </Paper>
            {loadingState.invPara && (
              <SimpleBackdrop open={loadingState.invPara} />
            )}
          </Grid>
        )} */}
      </Grid>
      <InverterDetails deviceId={deviceId} defaultVal={defaultField} />
      <Grid
        item
        lg={12}
        xs={12}
        sm={12}
        md={12}
        className="inv_table_container"
      >
        <TableNoSearchBar
          columns={deviceTableData}
          data={deviceTableData}
          page={page}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          handlePageChange={handleChangePage}
          handleRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}

export default Inverter;
