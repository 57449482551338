import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import { Backdrop, Modal, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import MonthRangeSelection from "../Common/MonthRangeSelection";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "8vh 30px 20px 30px",
  backgroundColor: "white",
  color: "white",
};

const NewLineTime = (props) => {
  const {
    legendName,
    isValueData,
    titleText,
    calenderClick,
    stringDurationFun,
    selectedDateRangeProp,
    isFilter,
    separateYaxis,
    isMobileAndTab,
    isDesktopView,
    isFullScreen,
    toggleFullScreen,loadState
  } = props;

  const [tempStartDateTime, setTempStartDateTime] = useState(new Date());
  const [tempStopDateTime, setTempStopDateTime] = useState(new Date());
  const [isDurationType, setIsDurationType] = useState("30 MIN");
  const [series, setSeries] = useState([]);
  const [xaxisData, setXaxisData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [dayCount, setDayCount] = useState(1);
  const [previousDayCount, setPreviousDayCount] = useState(1);
  const [previousMonthCount, setPreviousMonthCount] = useState(1);

  const [durationType, setDurationType] = useState([
    "2 MIN",
    "15 MIN",
    "30 MIN",
    "Hourly",
  ]);
  const [monthCount, setMonthCount] = useState(0);
  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);

  const getYAxisConfig = (name, opposite, min, max) => ({
    title: {
      text: name,
      style: { color: "#000000" },
    },
    opposite,
    labels: {
      style: { color: "#000000" },
      formatter: (value) => value.toFixed(2),
    },
    min: min,
    max: max,
  });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (
      (isStartTimeEnergy && isStopTimeEnergy) ||
      (tempStartDateTime && tempStopDateTime)
    ) {

      let availableFilters = [];

      if ((dayCount > 1 && dayCount <= 6) || (previousDayCount > 1 && previousDayCount <= 6)) {
        availableFilters = ["Daily"];
      } else if ((dayCount > 6 && dayCount <= 62 && monthCount < 2) || (previousDayCount > 6 && previousDayCount <= 62 && previousMonthCount < 2)) {
        availableFilters = ["Daily", "Weekly"];
      } else if (dayCount > 62 && dayCount < 365 && monthCount < 2) {
        availableFilters = ["Weekly", "Monthly"];
      } else if (dayCount >= 365 && monthCount > 12) {
        availableFilters = ["Monthly", "Yearly"];
      } else if (dayCount < 2 && monthCount < 2 && previousMonthCount < 2) {
        availableFilters = ["2 MIN", "15 MIN", "30 MIN", "Hourly"];
      } else if (monthCount > 1 || previousMonthCount > 1) {
        availableFilters = ["Monthly"]
      }

      setDurationType(availableFilters);

      if (
        availableFilters.length > 0 &&
        !availableFilters.includes(isDurationType)
      ) {
        setIsDurationType(availableFilters[0]);
      }
    }
  }, [
    isDurationType,
    isStopTimeEnergy,
    isStartTimeEnergy,
    tempStartDateTime,
    tempStopDateTime, monthCount, dayCount
  ]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); // Exit full-screen when user exits
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
  useEffect(() => {
    if (isValueData && isValueData.series) {
      if (
        (isStartTimeEnergy && isStopTimeEnergy) ||
        (tempStartDateTime && tempStopDateTime)
      ) {
        const newYaxis = {
          title: {
            text:
              isValueData?.yaxis?.title?.text !== undefined
                ? isValueData.yaxis.title.text
                : "",
            style: {
              color: "black",
            },
          },
          min: isValueData.min,
          max: isValueData.max,
        };
        setOptions((prevOptions) => ({
          ...prevOptions,
          chart: {
            type: isValueData?.chart?.type,
            toolbar: {
              autoSelected: "zoom",
              show: true,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
              },
            },
            zoom: {
              enabled: true,
            },
          },
          yaxis: separateYaxis
            ? isValueData.series.map((series, index) =>
              getYAxisConfig(
                series.name,
                series.separateYAxis,
                series.min,
                series.max
              )
            )
            : newYaxis,
        }));
        setSeries(isValueData.series);
        setXaxisData(isValueData.categories);
      } else {
        const newYaxis = {
          title: {
            text:
              isValueData?.yaxis?.title?.text !== undefined
                ? isValueData.yaxis.title.text
                : "",
            style: {
              color: "black",
            },
          },
          min: isValueData.min,
          max: isValueData.max,
        };
        setOptions((prevOptions) => ({
          ...prevOptions,
          chart: {
            type: isValueData?.chart?.type,
            toolbar: {
              autoSelected: "zoom",
              show: true,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
              },
            },
            zoom: {
              enabled: true,
            },
          },
          yaxis: separateYaxis
            ? isValueData.series.map((series, index) =>
              getYAxisConfig(
                series.name,
                series.separateYAxis,
                series.min,
                series.max
              )
            )
            : newYaxis,
        }));
        setSeries(isValueData.series);
        setXaxisData(isValueData.categories);
      }
    }
  }, [isValueData]);

  const [options, setOptions] = useState({
    chart: {
      type: isValueData?.chart?.type,
      toolbar: {
        autoSelected: "zoom",
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: xaxisData,

      type: (dayCount === 1 && monthCount < 2) || (previousDayCount === 1 && previousMonthCount < 2) ? "datetime" : undefined,

      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
    },

    fill: {
      type: isValueData?.chart?.type === "area" ? "gradient" : "solid",
      gradient: {
        shade: "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },

    grid: {
      show: isValueData?.chart?.type === "area" ? false : true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: isValueData?.chart?.type === "area" ? false : true,
        },
      },
    },
    legend: {
      // position: "top",
      showForSingleSeries: true,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2) + isValueData.series.units;
        },
      },
    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        type: isValueData?.chart?.type,
        toolbar: {
          autoSelected: "zoom",
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        zoom: {
          enabled: true,
        },
      },
      stroke: {
        width: isValueData?.chart?.type === "area" ? 2 : 0,
      },
      grid: {
        ...prevOptions.grid,

        show: isValueData?.chart?.type === "area" ? false : true,
        borderColor: "#BDBDBD",
        strokeDashArray: 5,
        position: "back",
        xaxis: {
          lines: {
            show: isValueData?.chart?.type === "area" ? false : true,
          },
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        type: (dayCount === 1 && monthCount < 2) || (previousDayCount === 1 && previousMonthCount < 2) ? "datetime" : undefined,

        labels: {
          datetimeUTC: false,

          style: {
            colors: "#000000",
          },
        },
      },
      fill: {
        type: isValueData?.chart?.type === "area" ? "gradient" : "solid",

        gradient: {
          shade: "light",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return "";
            }
            return value.toFixed(2);
          },
        },
      },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: "#000000",
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft =
        scrollableContainerRef.current.scrollWidth;
    }
  }, [isValueData, isFullScreen, xaxisData, legendName, titleText]);

  const handleRefresh = () => {
    const currentDate = new Date();
    setDayCount(1);
    setMonthCount(1);

    // Format the start and stop times
    const formattedStartDateTime = `${currentDate.toISOString().split("T")[0]
      }T00:00:00%2B05:30`;
    const formattedStopDateTime = `${currentDate.toISOString().split("T")[0]
      }T23:59:59%2B05:30`;

    // Update state with the new start and stop times
    setTempStartDateTime(currentDate);
    setTempStopDateTime(currentDate);

    // Update the date range to reflect the current date
    setSelectedDateRange([
      {
        startDate: currentDate,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setIsDurationType("30 MIN");
    props.stringDurationFun("30min");

    props.StartTimer(formattedStartDateTime);
    props.StopTimer(formattedStopDateTime);
  };
  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };

  const handleStopDateTimeChange = (date) => {
    setTempStopDateTime(date);
    const currentDate = new Date();
    const selectedDate = new Date(date);

    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    let hours, minutes;
    if (selectedDate.toDateString() === currentDate.toDateString()) {
      hours = currentDate.getHours().toString().padStart(2, "0");
      minutes = currentDate.getMinutes().toString().padStart(2, "0");
    } else {
      hours = "23";
      minutes = "59";
    }
    const formattedTimestampStop = `${year}-${month}-${day}T${hours}:${minutes}:00%2B05:30`;

    props.StopTimer(formattedTimestampStop);
    setIsStopTimeEnergy(formattedTimestampStop);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    setTempStartDateTime(date);
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const formattedTimestampStart = `${year}-${month}-${day}T00:00:00%2B05:30`;
    props.StartTimer(formattedTimestampStart);
    setIsStartTimeEnergy(formattedTimestampStart);
    props.openDrawerValue(openDrawer);
  };

  const handleClear = (event) => {
    event.preventDefault();
    setDayCount(1);
    setMonthCount(0);
    const currentDate = new Date();
    setTempStopDateTime(currentDate);
    setTempStartDateTime(currentDate);
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const funDate = (date) => {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const parts = date.split(/[T:+-]/);
    const date1 = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
    return date1;
  };

  const initialDateRange = selectedDateRangeProp.map((range) => {
    return {
      ...range,
      startDate: isStartTime || funDate(range.startDate),
      endDate: isStopTime || funDate(range.endDate),
    };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  useEffect(() => {
    if (isStartTime && isStopTime) {
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0], // Keep other properties intact
          startDate: isStartTime,
          endDate: isStopTime,
        },
      ]);

      if (isStartTime.getFullYear() === isStopTime.getFullYear() && isStartTime.getMonth() === isStopTime.getMonth()) {
        setMonthCount(1); // Same month
      }

      setMonthCount((isStopTime.getFullYear() - isStartTime.getFullYear()) * 12 + (isStopTime.getMonth() - isStartTime.getMonth()) + 1);
    }

  }, [isStartTime, isStopTime])
  useEffect(() => {
    if (selectedDateRange.length > 0) {
      const { startDate, endDate } = selectedDateRange[0];
      const { dayCount, monthCount } = calculateDayAndMonthCount(startDate, endDate);

      setPreviousDayCount(dayCount);
      setPreviousMonthCount(monthCount);
    }
  }, [selectedDateRange]);
  const calculateDayAndMonthCount = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    // Calculate dayCount
    const timeDifference = end.getTime() - start.getTime();
    const dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

    // Calculate monthCount
    const monthCount =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth()) +
      1;

    return { dayCount, monthCount };
  };
  const handleApply = () => {
    let selectedDuration;

    if (dayCount === 1) {
      switch (durationType) {
        case "2 MIN":
          return "2min";
        case "15 MIN":
          return "15min";
        case "30 MIN":
          return "30min";
        case "Hourly":
          return "h";
      }
      selectedDuration = isDurationType || "2 MIN";
    } else if (dayCount > 1 && dayCount <= 6) {
      selectedDuration = isDurationType || "Daily";
    } else if (dayCount > 6 && dayCount <= 62) {
      selectedDuration = isDurationType || "Weekly";
    } else if (dayCount > 62 && dayCount < 365) {
      selectedDuration = isDurationType || "Monthly";
    } else if (dayCount >= 365) {
      selectedDuration = isDurationType || "Yearly";
    } else if (monthCount > 1) {
      selectedDuration = ["Monthly"]
    }

    if (monthCount < 2) {
      if (selectedDuration) {
        props.stringDurationFun(getDurationCode(selectedDuration));
      }
      handleStartDateTimeChange(tempStartDateTime);
      handleStopDateTimeChange(tempStopDateTime);
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0],
          startDate: funDate(tempStartDateTime),
          endDate: funDate(tempStopDateTime),
        },
        ...prevSelectedDateRange.slice(1),
      ]);
    } else {
      props.stringDurationFun("ME")
      handleStartDateTimeChange(isStartTime);
      handleStopDateTimeChange(isStopTime);
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0],
          startDate: funDate(isStartTime),
          endDate: funDate(isStopTime),
        },
        ...prevSelectedDateRange.slice(1),
      ]);
    }

    setOpenDrawer(false);
  };
  const getDurationCode = (durationType) => {
    setIsDurationType(durationType);

    let availableFilters = [];
    if ((dayCount > 1 && dayCount <= 6) || (previousDayCount > 1 && previousDayCount <= 6)) {
      availableFilters = ["Daily"];
    } else if ((dayCount > 6 && dayCount <= 62 && monthCount < 2) || (previousDayCount > 6 && previousDayCount <= 62 && previousMonthCount < 2)) {
      availableFilters = ["Daily", "Weekly"];
    } else if (dayCount > 62 && dayCount < 365 && monthCount < 2) {
      availableFilters = ["Weekly", "Monthly"];
    } else if (dayCount >= 365 && monthCount > 12) {
      availableFilters = ["Monthly", "Yearly"];
    } else if (dayCount < 2 && monthCount < 2 && previousMonthCount < 2) {
      availableFilters = ["2 MIN", "15 MIN", "30 MIN", "Hourly"];
    } else if (monthCount > 1 || previousMonthCount > 1) {
      availableFilters = ["Monthly"]
    }

    setDurationType(availableFilters);

    switch (durationType) {
      case "2 MIN":
        return "2min";
      case "15 MIN":
        return "15min";
      case "30 MIN":
        return "30min";
      case "Hourly":
        return "h";
      case "Daily":
        return "D";
      case "Weekly":
        return "W";
      case "Monthly":
        return "ME";
      case "Yearly":
        return "YE";
      default:
        return "";
    }
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    return monthCount > 1 || date > today;
  };

  const calculateDateRangeLength = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    const timeDifference = end.getTime() - start.getTime();

    const dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

    return dayCount;
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p className="Poppins_18px_rem" style={{ color: "#212121" }}>
            {isValueData?.title?.text !== undefined
              ? isValueData.title.text
              : ""}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <div className="apexcharts-toolbar"></div>
            <Tooltip title="Refresh" arrow>
              <RefreshIcon className="icon-css" onClick={handleRefresh} />
            </Tooltip>
            <Tooltip title="Download" arrow>
              <DownloadIcon onClick={downloadChart} className="icon-css" />
            </Tooltip>

            <Tooltip title="Calendar" arrow>
              <CalendarMonthRoundedIcon
                onClick={handleTimings}
                className="icon-css"
              />
            </Tooltip>
            <Tooltip title="FullScreen" arrow>
              <button
                onClick={toggleFullScreen}
                style={{
                  width: "1.6vw !important",
                  height: "fit-content !important",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "#00529A",
                }}
              >
                {isFullScreen ? (
                  <FullscreenExitIcon className="icon-css" />
                ) : (
                  <FullscreenIcon className="icon-css" />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openDrawer}>
            <Box sx={boxStyle}>
              <div
                onClick={handleClose}
                className="handleclose-subdiv"
                style={{ position: "absolute" }}
              >
                <CloseIcon sx={{ color: "#2F67F8" }} />
              </div>

              <div className="calender-container" style={{
                marginTop: monthCount > 1 ? "2rem" : "0rem"
              }}>
                <div id="custom_date_picker" style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="range_title"
                    style={{
                      width: "fit-content",
                      position: "relative",
                      display: "flex",
                      gap: "2.8rem",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p
                      className="raleway_20px_rem"
                      style={{ color: "black", fontWeight: "700", width: "36%" }}
                    >
                      Range Filters
                    </p>
                    <div style={{ width: "54%" }}>
                      <MonthRangeSelection
                        onMonthCountChange={setMonthCount}
                        onDayCountChange={setDayCount}
                        isStartTimeChange={setIsStartTime}
                        isStopTimeChange={setIsStopTime}
                      />
                    </div>
                  </div>

                  <DateRangePicker
                    months={1}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={selectedDateRange}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      const endDate = ranges.selection.endDate;
                      const lengthOfRange = calculateDateRangeLength(
                        startDate,
                        endDate
                      );
                      setDayCount(lengthOfRange);
                      if (startDate === endDate) {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      } else {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      }
                      setSelectedDateRange([ranges.selection]);
                    }}
                  />
                </div>
                {isFilter && (
                  <div className="intervals_container"
                    style={{
                      top: monthCount > 1 ? "0rem" : "2rem", width: monthCount > 1 ? "100%" : "auto"
                    }}>
                    <div className="interval_filter_combined">
                      <p
                        className="raleway_20px_rem"
                        style={{
                          color: "black",
                          fontWeight: "700",
                          paddingLeft: "1rem",
                        }}
                      >
                        Interval Filters
                      </p>
                      <div className="filter_container">
                        {durationType.map((field, index) => {
                          return (<div key={index} className="filter_button" style={{
                            backgroundColor: isDurationType === field ? 'rgb(222 231 246)' : 'transparent',
                          }} onClick={() => getDurationCode(field)}>
                            <p className="rdrStaticRangeLabel">{field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase())}</p>
                          </div>)
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        className="btn_style"
                        style={{
                          backgroundColor: "#2F67F8",
                        }}
                        onClick={handleApply}
                      >
                        {" "}
                        <p className="raleway_20px_rem">Apply</p>
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        className="btn_style"
                        style={{
                          backgroundColor: "#FF472E",
                        }}
                        onClick={handleClear}
                      >
                        <p className="raleway_20px_rem">Clear</p>
                      </Button>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "end",
                      gap: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      className="btn_style"
                      style={{
                        backgroundColor: "#2F67F8",
                      }}
                      onClick={handleApply}
                    >
                      {" "}
                      <p className="raleway_20px_rem">Apply</p>
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      className="btn_style"
                      style={{
                        backgroundColor: "#FF472E",
                      }}
                      onClick={handleClear}
                    >
                      <p className="raleway_20px_rem">Clear</p>
                    </Button>
                  </div>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      )}

      <div
        style={{
          height: isValueData.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
          display: isValueData.length === 0 && "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={chartContainerRef}
      >
        {isValueData && isValueData.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">{loadState ? "" : "No Data"}</p>
          </div>
        ) : (
          <div
            ref={scrollableContainerRef}
            className="scrollable_chat-container"
          >
            <div
              style={{
                width: isMobile
                  ? isValueData?.categories.length > 0
                    ? isValueData?.categories.length * 60
                    : "100%"
                  : isValueData?.categories.length > 20 &&
                    isValueData?.chart?.type === "bar"
                    ? isValueData?.categories.length * 60
                    : "100%",
              }}
              id="prediction-chart_two"
            >
              <Chart
                options={options}
                series={series}
                type={isValueData?.chart?.type}
                height={
                  isFullScreen && isMobileAndTab
                    ? "300"
                    : isFullScreen && isDesktopView
                      ? "500"
                      : "350"
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewLineTime;