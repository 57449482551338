import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "./LandingPage.scss";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);

  const [open, setOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSoftwareMenuOpen, setIsSoftwareMenuOpen] = useState(false);
  const [isHardwareMenuOpen, setIsHardwareMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const [isNavEle, setIsNavEle] = useState("Home");
  const [softwareSol, setSoftwareSol] = useState(false);
  const [hardwareSol, setHardwareSol] = useState(false);
  const history = useHistory();
  const [softwareSolMob, setSoftwareSolMob] = useState(false);
  const [hardwareSolMob, setHardwareSolMob] = useState(false);
  const location = useLocation();

  const softwareProds = [
    { name: "PowerOptix" },
    { name: "Forecasting" },
    { name: "Fault Prediction" },
    { name: "Digital Twin" },
    { name: "Remote Management" },
    { name: "Drone Integration" },
  ];
  const hardwareProds = [
    { name: "Tracker Controller" },
    { name: "Solar Tracker" },

    { name: "SmartIQ Edge" },
  ];
  useEffect(() => {
    const path = location.pathname;
    if (path === "/" || path === "/privacypolicy" || path === "/terms_conditions") {
      setIsNavEle("Home");
    } else if (
      path === "/case-studies" ||
      path === "/case-study/Solar-industry/solar-forecast" ||
      path === "/case-study/Solar-industry/solar-3d" ||
      path === "/case-study/Solar-industry/solar-drone" ||
      path === "/case-study/life-science" ||  path === "/case-study/digital-twin" ||
      path === "/case-study/manufacturing" ||
      path === "/case-study/robotics" ||
      path === "/case-study/process-industry"
    ) {
      setIsNavEle("Case Studies");
    } else if (
      path === "/product-details" ||
      path === "/products-solutions" ||
      path === "/products/smartiq_edge" ||
      path === "/products/poweroptix" ||
      path === "/products/three_d_twin" ||
      path === "/products/energy_forecasting" ||
      path === "/products/drone_integration" ||
      path === "/products/fault_prediction" ||
      path === "/products/remote_management" ||
      path === "/products/tracker_controller"||path === "/products/solar_tracker"
    ) {
      setIsNavEle("Products");
    } else if (
      path === "/our-solution" ||
      path === "/our-services/0" ||
      path === "/our-services/1" ||
      path === "/our-services/2"
    ) {
      setIsNavEle("Solutions");
    } else if (path === "/about-us") {
      setIsNavEle("About us");
    }
      else if (path === "/careers") {
        setIsNavEle("Careers");
      
    }
  }, [location.pathname, isNavEle]);
  const handleMenuClick = (ele) => {
    setIsNavEle(ele);
    if (ele === "Home") {
      history.push("/");
    } else if (ele === "Case Studies") {
      history.push("/case-studies");
    } else if (ele === "Products") {
      setSoftwareSol(false);
      setHardwareSol(false);
      if (isMobile) {
        setIsSubMenuOpen((prev) => !prev);
      } else {
        setIsSubMenuOpen(true);

        setOpen((prevOpen) => !prevOpen);
      }
    } else if (ele === "Solutions") {
      history.push("/our-solution");
    } else if (ele === "About us") {
      history.push("/about-us");
    } else if (ele === "Careers") {
      history.push("/careers");
    }
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const subMenuClick = (ele) => {
    if (ele === "Software") {
      setSoftwareSol(true);
      setHardwareSol(false);
    } else if (ele === "Hardware") {
      setHardwareSol(true);
      setSoftwareSol(false);
    }
  };
  const subMenuClickMobile = (ele) => {
    if (ele === "Software") {
      setSoftwareSolMob((prev) => !prev);
      setHardwareSolMob(false);
      setIsHardwareMenuOpen(false);

      setIsSoftwareMenuOpen((prev) => !prev);
    } else if (ele === "Hardware") {
      setHardwareSolMob((prev) => !prev);
      setSoftwareSolMob(false);
      setIsHardwareMenuOpen((prev) => !prev);
      setIsSoftwareMenuOpen(false);
    }
  };

  const handleCloseProducts = () => {
    setIsSubMenuOpen(false);
    setOpen(false);
  };
  const handleCloseFirstSubMenu = () => {
    setSoftwareSol(false);
  };
  const handleCloseSecondSubMenu = () => {
    setHardwareSol(false);
  };
  const handleSoftProdsClick = (name) => {
    if (name === "PowerOptix") {
      history.push("/products/poweroptix");
    } else if (name === "Forecasting") {
      history.push("/products/energy_forecasting");
    } else if (name === "Fault Prediction") {
      history.push("/products/fault_prediction");
    } else if (name === "Digital Twin") {
      history.push("/products/three_d_twin");
    } else if (name === "Remote Management") {
      history.push("/products/remote_management");
    } else if (name === "Drone Integration") {
      history.push("/products/drone_integration");
    }
  };
  const handleHardProdsClick = (name) => {
    if (name === "Tracker Controller") {
      history.push("/products/tracker_controller");
    }
    else if (name === "Solar Tracker") {
      history.push("/products/solar_tracker");
    } else if (name === "SmartIQ Edge") {
      history.push("/products/smartiq_edge");
    }
  };
  const handleLoginClick = () => {
    history.push("/signin");
  };

  return (
    <>
      <AppBar
        position="static"
        style={{
          minWidth: "100%",
          background: "#ecf2f7",
          boxShadow: "none",
        }}
      >
        <Toolbar className="maintool-Bar">
          <div>
            <a href="/">
              <img
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg"
                style={{ width: "14vw" }}
                alt="imagelogo"
                className="smarttrak-logo"
              />
            </a>
          </div>
          <div className={isMobile ? "nav-items-mobile" : "nav-item"}>
            <p
              className={
                isNavEle === "Home" ? "selected-nav-element" : "nav-elements"
              }
              onClick={() => handleMenuClick("Home")}
            >
              Home
            </p>

            <p
              className={
                isNavEle === "Case Studies"
                  ? "selected-nav-element"
                  : "nav-elements"
              }
              onClick={() => handleMenuClick("Case Studies")}
            >
              Case Studies
            </p>

            {!isMobile && (
              <p
                style={{ position: "relative" }}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                className={
                  isNavEle === "Products"
                    ? "selected-nav-element pro_arr"
                    : "nav-elements pro_arr"
                }
                onMouseEnter={() => handleMenuClick("Products")}
                onMouseLeave={handleCloseProducts}
              >
                Products
                {isSubMenuOpen ? (
                  <ExpandLessIcon
                    style={{
                      marginLeft: "0.5vw",
                      color: isNavEle === "Products" ? "#00529a" : "black",
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{
                      marginLeft: "0.5vw",
                      color: isNavEle === "Products" ? "#00529a" : "black",
                    }}
                  />
                )}
                {open && !isMobile && (
                  <div className="dropdown_main_container">
                    <div className="dropdown-pro" onClick={handleClose}>
                      <div
                        className="menu-pro"
                        onMouseEnter={() => subMenuClick("Software")}
                        onMouseLeave={handleCloseFirstSubMenu}
                      >
                        <p className="Raleway_25px c_blue font_500">Software</p>
                      </div>

                      <div
                        className="menu-pro"
                        onMouseEnter={() => subMenuClick("Hardware")}
                        onMouseLeave={handleCloseSecondSubMenu}
                      >
                        <p className="Raleway_25px c_blue font_500">Hardware</p>
                      </div>
                    </div>
                    {softwareSol && (
                      <div
                        className="software_prods_container"
                        onMouseEnter={() => setSoftwareSol(true)}
                      >
                        {softwareProds.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="soft_prods_div"
                              onClick={() => handleSoftProdsClick(item.name)}
                            >
                              <p className="Raleway_25px c_white font_500">
                                {item.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {hardwareSol && (
                      <div
                        className="software_prods_container"
                        style={{ top: "12vh" }}
                        onMouseEnter={() => setHardwareSol(true)}
                      >
                        {hardwareProds.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="soft_prods_div"
                              onClick={() => handleHardProdsClick(item.name)}
                            >
                              <p className="Raleway_25px c_white font_500">
                                {item.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </p>
            )}

            {isMobile && (
              <div
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                className={
                  isNavEle === "Products"
                    ? "selected-nav-element "
                    : "nav-elements"
                }
                onClick={() => handleMenuClick("Products")}
              >
                <p style={{ margin: 0 }}>Products</p>
                {isMobile ? (
                  isSubMenuOpen ? (
                    <ExpandLessIcon
                      style={{
                        marginLeft: "0.5vw",
                        color: isNavEle === "Products" ? "#00529a" : "black",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{
                        marginLeft: "0.5vw",
                        color: isNavEle === "Products" ? "#00529a" : "black",
                      }}
                    />
                  )
                ) : null}
              </div>
            )}

            {isMobile && isSubMenuOpen && (
              <div className="dropdown-pro-mobile">
                <div
                  className="menu-pro-mobile"
                  onClick={() => subMenuClickMobile("Software")}
                >
                  <p className="product-elements">Software</p>
                  {isSoftwareMenuOpen ? (
                    <ExpandLessIcon style={{ marginLeft: "0.5vw" }} />
                  ) : (
                    <ExpandMoreIcon style={{ marginLeft: "0.5vw" }} />
                  )}
                </div>
                {softwareSolMob && (
                  <div
                    className="software_prods_container"
                    style={{
                      width: "100%",
                      left: "0vw",
                      top: "0vh",
                      position: "relative",
                    }}
                  >
                    {softwareProds.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="soft_prods_div"
                          onClick={() => handleSoftProdsClick(item.name)}
                        >
                          <p className="Raleway_25px c_white font_500">
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className="menu-pro-mobile"
                  onClick={() => subMenuClickMobile("Hardware")}
                >
                  <p className="product-elements">Hardware</p>
                  {isHardwareMenuOpen ? (
                    <ExpandLessIcon style={{ marginLeft: "0.5vw" }} />
                  ) : (
                    <ExpandMoreIcon style={{ marginLeft: "0.5vw" }} />
                  )}
                </div>
                {hardwareSolMob && (
                  <div
                    className="software_prods_container"
                    style={{
                      width: "100%",
                      left: "0vw",
                      top: "0vh",
                      position: "relative",
                    }}
                  >
                    {hardwareProds.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="soft_prods_div"
                          onClick={() => handleHardProdsClick(item.name)}
                        >
                          <p className="Raleway_25px c_white font_500">
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            <p
              className={
                isNavEle === "Solutions"
                  ? "selected-nav-element"
                  : "nav-elements"
              }
              onClick={() => handleMenuClick("Solutions")}
            >
              Solutions
            </p>
            <p
              className={
                isNavEle === "About us"
                  ? "selected-nav-element"
                  : "nav-elements"
              }
              onClick={() => handleMenuClick("About us")}
            >
              About Us
            </p>
            <p
              className={
                isNavEle === "Careers"
                  ? "selected-nav-element"
                  : "nav-elements"
              }
              onClick={() => handleMenuClick("Careers")}
            >
           
              Careers
            </p>

            <div className="expert_div" id="nav_new">
              <Button
                type="submit"
                variant="contained"
                onClick={handleLoginClick}
                className="login-btn"
                style={{
                  cursor: "pointer",
                  fontFamily: "Raleway",
                  backgroundColor: "#00529A",
                  borderRadius: "3vw",
                  padding: "1vh 3vw",
                  textTransform: "capitalize",
                  marginTop: "1vh",
                  fontSize: "1.1vw",
                  boxShadow: "none",
                }}
              >
                Login
              </Button>
            </div>
          </div>

          <button
            className="mobile-menu-icon"
            onClick={() => setIsMobile(!isMobile)}
          >
            {isMobile ? (
              <CloseIcon />
            ) : (
              <img
                src="https://s3.amazonaws.com/smarttrak.co/landing-images/hamburger-menu.svg"
                alt="menu"
              />
            )}
          </button>
        </Toolbar>
      </AppBar>
      <Divider style={{ border: "0.5px solid #E6E6E6" }} />
    </>
  );
}

export default Navbar;
