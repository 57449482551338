import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import ReactApexChart from "react-apexcharts";
import ProgressRect from "../../../Common/ProgressRect ";
import "./Battery.scss";
import BatteryGreen from "../../../Common/BatteryGreen";
import ImgBarchart from "../../../Graphs/ImgBarchart";
import Speedometer, {
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
} from "react-speedometer";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import NegativeGraph from "../../../Graphs/NegativeGraph";
function Battery() {
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph1: false,
    graph5: false,
    graph6: false,
  });
  const [pieChart, setPieChart] = useState({
    series: [750, 200, 50], // Updated series values
    options: {
      chart: {
        width: 380,
        type: "pie",
      },

      dataLabels: {
        enabled: false, // Hide values by default
      },
      labels: ["Balanced", "Partially Balanced", "Imbalanced"], // Updated labels
      colors: ["#467F23", "#D6F527", "#F00F0F"],
      legend: {
        position: "right",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex]; // Value of the slice
          const label = w.config.labels[seriesIndex]; // Label of the slice
          const total = series.reduce((acc, val) => acc + val, 0); // Calculate total
          const percentage = ((value / total) * 100).toFixed(1); // Calculate percentage
          const color = w.config.colors[seriesIndex]; // Get color for the slice

          return `
            <div class="tooltippie">
              <span class="raleway_12px_rem" style="color: #212121;">
                ${label}<br />
                <span style="color: ${color};">
                  ${value} cells (${percentage}%)
                </span>
              </span>
            </div>
          `;
        },
      },
      responsive: [
        {
          breakpoint: 1200, // For large screens
          options: {
            chart: {
              width: 380,
            },
            legend: {
              position: "right",
            },
          },
        },
        {
          breakpoint: 768, // For tablets
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "right",
            },
          },
        },
        {
          breakpoint: 480, // For mobile screens
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "right",
            },
          },
        },
      ],
    },
  });
  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  const faults = [
    {
      name: "Lithium Plating",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_one.svg",
    },
    {
      name: "Over Temp",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_two.svg",
    },
    {
      name: "Over Current",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_three.svg",
    },
    {
      name: "Over Voltage",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_four.svg",
    },
    {
      name: "Accelerated Degradation",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_five.svg",
    },
    {
      name: "Thermal Runaway",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_six.svg",
    },
  ];

  return (
    <div
      className="home-container gap_1vw  flex_col"
      style={{ display: "flex" }}
    >
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            id="justify-start"
          >
            <p className="Poppins_24px_black c_lightblue">
              Battery Monitoring System
            </p>
          </Paper>
        </Grid>

        <Grid
          item
          lg={4.9}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper className="exc-graph-paper">
            <Grid container className="grid-container">
              <Grid
                item
                lg={12}
                xs={12}
                md={5.9}
                sm={12}
                className="donut_grid_exc"
              >
                <Paper className="energy-paper" id="donut_paper">
                  <p className="Poppins_18px_rem">State Of Charge</p>
                </Paper>
                <Paper className="energy-paper  expert_div">
                  <ProgressRect />
                </Paper>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                md={5.9}
                sm={12}
                className="donut_grid_exc"
              >
                <Paper className="energy-paper" id="donut_paper">
                  <p className="Poppins_18px_rem">Capacity</p>
                </Paper>
                <Paper className="energy-paper  expert_div">
                  <div className="battery_shadow  expert_div flex_col gap_1vw">
                    <div className="expert_div">
                      <img
                        src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newProductPage/SolarCharging.svg"
                        alt="SolarCharging"
                      />
                    </div>
                    <div className="expert_div gap_1vw">
                      <img
                        src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newProductPage/Battery.svg"
                        alt="battery"
                      />
                      <p className="Poppins_16px_rem  font_500"> 12.5 kWh</p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid
          item
          lg={7}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
             isValueData={[
              20, 40, 56, 60, 40, 76, 65, 80, 75, 60, 65, 34, 32,
            ]}
              
              chartType="line"
              yaxisText="SoH Percentage"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 100]}
              openDrawerValue={true}
              titleText="State of Health"
              indicateUnit="%"
              tooltipName="Cycles"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>

        <Grid
          item
          lg={7}
          xs={12}
          sm={12}
          md={7}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph1 ? "fixed" : "relative",
          //   top: fullScreenStates.graph1 ? "9vh" : "auto",
          //   width: fullScreenStates.graph1
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph1 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph1 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <ImgBarchart
             
                
              isValueData={[
                31, 32, 30, 33, 34, 35, 36, 37, 39.5, 37, 34, 34, 32,
              ]}
              chartType="area"
              yaxisText="Temperature"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 100]}
              openDrawerValue={true}
              titleText="Battery Temperature"
              indicateUnit="°C"
              tooltipName="Temperature"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid item lg={4.9} xs={12} md={4.9} sm={12} className="donut_grid_exc">
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem">RUL</p>
          </Paper>
          <Paper className="energy-paper  expert_div">
            <BatteryGreen />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            id="justify-start"
          >
            <p className="Poppins_24px_black c_lightblue">
              Charging & Discharging Metrics
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <NegativeGraph
              isValueData={[
                {
                  type: "Jun",
                  value: 20,
                },
                {
                  type: "Jul",
                  value: -20,
                },
                {
                  type: "Aug",
                  value: 45,
                },
                {
                  type: "Sep",
                  value: -45,
                },
                {
                  type: "Oct",
                  value: 60,
                },
                {
                  type: "Nov",
                  value: -60,
                },
                {
                  type: "Dec",
                  value: 75,
                },
              ]}
              chartType="area"
              yaxisText="Current"
              StartTimer={""}
              StopTimer={""}
              minmax={[-80, 100]}
              openDrawerValue={true}
              titleText="Charge/Discharge Current"
              indicateUnit="A"
              tooltipName="Time"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <NegativeGraph
              isValueData={[
                {
                  type: "Jun",
                  value: 20,
                },
                {
                  type: "Jul",
                  value: -20,
                },
                {
                  type: "Aug",
                  value: 45,
                },
                {
                  type: "Sep",
                  value: -45,
                },
                {
                  type: "Oct",
                  value: 60,
                },
                {
                  type: "Nov",
                  value: -60,
                },
                {
                  type: "Dec",
                  value: 75,
                },
              ]}
              chartType="area"
              yaxisText="Power"
              StartTimer={""}
              StopTimer={""}
              minmax={[-80, 100]}
              openDrawerValue={true}
              titleText="Charge/Discharge Power"
              indicateUnit="W"
              tooltipName="Time"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className="exc-graph-paper">
          <Grid
            container
            className="grid-container"
            sx={{
              backgroundColor: "#ffff",
              padding: "1rem",
              border: "0.0625rem solid #d9d9d9",
              borderRadius: "0.5rem",
            }}
          >
            <Grid
              item
              lg={3.9}
              xs={12}
              md={3.9}
              sm={12}
              className="donut_grid_exc"
              id="voltage_grid_bg"
            >
              <Paper className="energy-paper flex-start_div">
                <p className="Poppins_18px_rem">Charging Voltage</p>
              </Paper>

              <Paper className="energy-paper  expert_div">
                <img
                  src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/battery_one.svg"
                  alt="battery"
                />
                {/* <Speedometer
                  min={100}
                  max={500}
                  angle={180}
                  domain={[100, 500]}
                  value={400}
                  fontFamily="squada-one"
                  textColor="#000"
                >
                  <Background
                    angle={360}
                    color="rgba(104, 100, 100, 0.58)
"
                  />
                  <Arc arcWidth={14} />
                  <Needle
                    baseOffset={-30}
                    circleRadius={0}
                    color="#000"
                    offset={50}
                    circleColor={"#000"}
                  />
                  <Progress arcWidth={14} />
                  <Marks step={50} color="#000" style={{ color: "#000" }} />
                </Speedometer> */}
                <Paper className="energy-paper flex-start_div">
                  <p className="Poppins_24px_black ">400V</p>
                </Paper>
              </Paper>
            </Grid>
            <Grid
              item
              lg={3.9}
              xs={12}
              md={3.9}
              sm={12}
              className="donut_grid_exc"
              id="voltage_grid_bg"
            >
              <Paper className="energy-paper flex-start_div">
                <p className="Poppins_18px_rem">Charge Cycles Completed</p>
              </Paper>
              <Paper className="energy-paper  expert_div">
                {/* <CircularProgressbarWithChildren
                  value={100}
                  strokeWidth={0.5}
                  styles={buildStyles({
                    textColor: "#333333",
                    pathColor: "#5FB838",
                  })}
                >
                  <div style={{ width: "84%" }}>
                    <CircularProgressbar
                      strokeWidth={6}
                      value={100}
                      styles={buildStyles({
                        textColor: "#333333",
                        textSize: "20px",
                        pathColor: "#5FB838",
                      })}
                    />
                  </div>
                </CircularProgressbarWithChildren>
                <div style={{ position: "absolute", top: "45%", left: "45%" }}>
                  <img
                    style={{ width: 20, marginTop: -5 }}
                    src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/battery_svg.svg"
                    alt="battery"
                  />
                  <div style={{ fontSize: 20, marginTop: 5 }}>
                    <strong>1200</strong> <br />
                    <strong>Cycles</strong>
                  </div>
                </div> */}
                <img
                  src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/battery_two.svg"
                  alt="battery"
                />
              </Paper>
              <Paper className="energy-paper flex-start_div">
                <p className="Poppins_24px_black ">1200 cycles</p>
              </Paper>
            </Grid>
            <Grid
              item
              lg={3.9}
              xs={12}
              md={3.9}
              sm={12}
              className="donut_grid_exc"
              id="voltage_grid_bg"
            >
              <Paper className="energy-paper flex-start_div">
                <p className="Poppins_18px_rem">Fast Charge Count</p>
              </Paper>
              <Paper className="energy-paper  expert_div">
                {/* <CircularProgressbarWithChildren
                  value={65}
                  styles={buildStyles({
                    textColor: "#333333",
                    textSize: "20px",
                    pathColor: "#008000",
                  })}
                >
                  <img
                    style={{ width: 40, marginTop: -5 }}
                    src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/fast_pin.svg"
                    alt="battery"
                  />
                  <div style={{ fontSize: 20, marginTop: 5 }}>
                    <strong>65 instances</strong> <br />
                  </div>
                </CircularProgressbarWithChildren> */}
                <img
                  src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/battery_three.svg"
                  alt="battery"
                />
              </Paper>
              <Paper className="energy-paper flex-start_div">
                <p className="Poppins_24px_black ">15 Instances</p>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            id="justify-start"
          >
            <p className="Poppins_24px_black c_lightblue">
              Electrical Parameters
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper  expert_div">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_one.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">Grid Voltage</p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">230V</p>
                <p className="c_gray Poppins_18px_rem">AC</p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper ">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_two.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">Grid Current</p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">12</p>
                <p className="c_gray Poppins_18px_rem">A</p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper ">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_three.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">Power Factor</p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">0.95</p>
                <p className="c_gray Poppins_18px_rem">lagging</p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper ">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_four.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">Active Power</p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">5.8</p>
                <p className="c_gray Poppins_18px_rem">kW</p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper ">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_five.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">
                String Voltages
              </p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">420V</p>
                <p className="c_gray Poppins_18px_rem">Strings</p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          md={3.9}
          sm={12}
          className="donut_grid_exc"
          id="elec_grid_shadow"
        >
          <Paper className="energy-paper ">
            <div className="expert_div div_width flex_col gap_1vw">
              <img
                className="align_self"
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/elec_six.svg"
                alt="battery"
              />
              <p className="expert_div text_c Poppins_18px_rem">
                DC Bus Voltage
              </p>
              <div className="foo_one div_width">
                <p className="c_gray Poppins_18px_rem">750V</p>
                <p className="c_gray Poppins_18px_rem">DC</p>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            id="justify-start"
          >
            <p className="Poppins_24px_black c_lightblue">
              Environmental Metrics
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
            isValueData={[
              350, 400, 560, 600, 650, 800, 750, 700, 750, 700, 650, 700, 800,
            ]}
              
              chartType="area"
              yaxisText="Irradiance"
              StartTimer={""}
              StopTimer={""}
              minmax={[200, 1000]}
              openDrawerValue={true}
              titleText="Solar Irradiance"
              indicateUnit="W/m²"
              tooltipName="Solar Irradiance"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              
              isValueData={[
                2, 5, 10, 13, 15, 10, 15, 17, 15, 12, 10, 13, 15,
              ]}
                
              chartType="area"
              yaxisText="Power"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 20]}
              openDrawerValue={true}
              titleText="Array Output Power"
              indicateUnit="kW"
              tooltipName="Power"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={5.9}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            id="justify-start"
          >
            <p className="Poppins_24px_black c_lightblue">
              Fault Detection & Safety
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={4.6}
          xs={12}
          md={12}
          sm={12}
          className="donut_grid_exc"
          id="voltage_grid_bg"
        >
          <Paper className="energy-paper flex-start_div">
            <p className="Poppins_18px_rem align_self">Fault Events</p>
          </Paper>
          <Paper className="energy-paper  expert_div">
            <Grid container className="grid-container">
              {faults.length > 0 &&
                faults.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={5.8}
                      xs={12}
                      sm={12}
                      md={1.8}
                      className="exc-graph-grid"
                      sx={{ border: "none !important" }}
                    >
                      <Paper className="exc-graph-paper">
                        <div className="expert_div div_width flex_col gap_1vw">
                          <div
                            className={`expert_div padd_1vw button_fault ${
                              index === 1 || index === 4
                                ? "button-44"
                                : "button-33"
                            }`}
                          >
                            <img src={item.info} alt="faults" />
                          </div>
                          <p className="Poppins_16px_rem c_gray text_cen ">
                            {item.name}
                          </p>
                        </div>
                      </Paper>
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={3.4} xs={12} md={4.9} sm={12} className="donut_grid_exc">
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem">Inverter Efficiency</p>
          </Paper>
          <Paper className="energy-paper  expert_div" id="wid_50">
            <CircularProgressbarWithChildren
              value={78}
              strokeWidth={6}
              styles={buildStyles({
                textColor: "#333333",
                pathColor: "#008000",
                strokeLinecap: "round",
                path: {
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round",
                },
                trail: {
                  strokeLinecap: "round",
                },
              })}
            >
              <div style={{ width: "70%" }}>
                <CircularProgressbar
                  background
                  value={100}
                  styles={buildStyles({
                    textColor: "#333333",
                    textSize: "20px",
                    pathColor: "#A1EC26",
                    backgroundColor: "#A1EC26",
                    backgroundPadding: 5,
                  })}
                />
              </div>
            </CircularProgressbarWithChildren>
            <div style={{ position: "absolute" }}>
              <img
                style={{ width: "100%" }}
                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/inv_eff.svg"
                alt="battery"
              />
            </div>
          </Paper>
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem  text_cen">
              Inverter Power: <span className="c_gray">78%</span>
            </p>
          </Paper>
        </Grid>
        <Grid item lg={3.8} xs={12} md={6.9} sm={12} className="donut_grid_exc">
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem">Cell Balancing Status</p>
          </Paper>
          <Paper className="energy-paper  expert_div" id="apex_pie">
            <ReactApexChart
              options={pieChart.options}
              series={pieChart.series}
              type="pie"
              width={400}
            />
          </Paper>
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem  text_cen">
              Total Number of Cells:{" "}
              <span className="c_gray"> 1,000 cells</span>
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Battery;
