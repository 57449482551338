import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IMGBarVal from "../../Graphs/IMGBarVal";
import CustomCircularBar from "../../Common/CustomCircularBar";
import Divider from "@mui/material/Divider";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function DeatailedAnalysis() {
    
  const location = useLocation();
  const FolData = location.state?.eleData;
  const indexData = location.state?.index??"";

  const [folderData, setFolderData] = useState({
    count: "",
    defect: "",
    pass: "",
    fail: "",
    commondefect: "",
  });
  useEffect(() => {
    if (FolData) {
      setFolderData(FolData);
    }
  }, [FolData]);
  
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
  });
  const { isOnlyMobile, isMobile, isMobileAndTab, isDesktopView } = useMobile();
  const history = useHistory();

  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  const handleBackClick = () => {
    history.push("/iot-home/img-upload");
  };
  return (
    <div className="home-container">
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ justifyContent: "flex-start !important", gap: "1rem" }}
          >
            <img
              src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/arrow_forward.svg"
              style={{ width: "1.3rem", cursor: "pointer" }}
              alt="arrow"
              onClick={handleBackClick}
            />
            <p
              className="Poppins_24px_rem"
              style={{ fontWeight: "500", color: "#212121" }}
            >
              {`EL Test ${indexData+1}`}
            </p>
          </Paper>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={3} className="exc-graph-paper ">
          <Paper className="powerplant-sec-paper gap_2vw   align_item">
            <p className="Poppins_24px_black font_500 c_skyblue">
            Overview
            </p>
          </Paper>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={3} className="exc-graph-grid ">
          <Paper className="powerplant-sec-paper gap_2vw  flex_col ">
            <div className="flex_col  expert_div   img_70  gap_1vw padd_1vw">
              {/* <div className="expert_div div_width">
                <p className="Poppins_24px_black font_500 c_skyblue">
                  Overview
                </p>
              </div> */}
              <div className="foo_one div_width">
                <p className="Poppins_18px_rem font_500">Total Image Count</p>
                <p className="Poppins_18px_rem font_700">
                  {folderData.total_files ? folderData.total_files : 1250}
                </p>
              </div>
              <div className="foo_one div_width">
                <p className="Poppins_18px_rem font_500">Defect Rate</p>
                <p className="Poppins_18px_rem font_700 c_red">
                  {folderData.percentage_defective
                    ? folderData.percentage_defective
                    : "12"}
                  %
                </p>
              </div>
              <div className="foo_one div_width">
                <p className="Poppins_18px_rem font_500">Pass / Fail RateS</p>
                <p className="Poppins_18px_rem font_700"> 980 / 270</p>
              </div>
              <div className="foo_one div_width">
                <p className="Poppins_18px_rem font_500">
                  Average Processing Time per Image
                </p>
                <p className="Poppins_18px_rem font_700">3.5 sec</p>
              </div>
              <div className="foo_one div_width">
                <p className="Poppins_18px_rem font_500">
                  Most Common Defect Type
                </p>
                <p className="Poppins_18px_rem font_700 c_red">Microcracks</p>
              </div>
            </div>
          </Paper>
        </Grid>
      
        <Grid item lg={12} xs={12} sm={12} md={3} className="exc-graph-paper ">
          <Paper className="powerplant-sec-paper gap_2vw   align_item">
            <p className="Poppins_24px_black font_500 c_skyblue">
              Detailed Analysis
            </p>
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 4 }}
          lg={3.8}
          xs={12}
          md={12}
          sm={12}
          className="donut_grid_exc"
          id="power_analysis_id"
        >
          <Paper className="energy-paper" id="donut_paper">
            <p className="Poppins_18px_rem">Defect Severity Distribution</p>
          </Paper>
          <Paper className="energy-paper center_div_nogap">
            <CustomCircularBar
              centerText="71"
              values={[34, 23, 14]}
              nameData={["Critical", "Moderate", "Minor"]}
              unit="%"
              unitData=""
              title="Count"
            />
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 14, lg: 9 }}
          lg={4}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph1 ? "fixed" : "relative",
          //   top: fullScreenStates.graph1 ? "9vh" : "auto",
          //   width: fullScreenStates.graph1
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph1 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph1 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <IMGBarVal
              isValueData={[
                {
                  type: "Microcracks",
                  value: 34,
                },
                {
                  type: "Hotspots",
                  value: 45,
                },
                {
                  type: "Diode Failure",
                  value: 16,
                },
              ]}
              minmax={[10,60]}

              chartType="bar"
              yaxisText="Number of defects"
              StartTimer={""}
              StopTimer={""}
              openDrawerValue={true}
              titleText="Defect Type Analysis"
              indicateUnit=""
              tooltipName="Defect Type"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              
            />
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 14, lg: 9 }}
          lg={4}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph2 ? "fixed" : "relative",
          //   top: fullScreenStates.graph2 ? "9vh" : "auto",
          //   width: fullScreenStates.graph2
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph2 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph2 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <IMGBarVal
              isValueData={[
                {
                  type: "Jun",
                  value: 99,
                },
                {
                  type: "July",
                  value: 96,
                },
                {
                  type: "Aug",
                  value: 91,
                },

                {
                  type: "Sep",
                  value: 90,
                },
                {
                  type: "Oct",
                  value: 89,
                },
                {
                  type: "Nov",
                  value: 88,
                },
                {
                  type: "Dec",
                  value: 87,
                },
              ]}
              minmax={[80,100]}

              chartType="area"
              StartTimer={""}
              StopTimer={""}
              openDrawerValue={true}
              titleText="Panel Health Trend Analysis"
              indicateUnit=""
              tooltipName="Time"
              stringDurationFun={""}
              yaxisText="Health index"
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 14, lg: 9 }}
          lg={3.8}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph1 ? "fixed" : "relative",
          //   top: fullScreenStates.graph1 ? "9vh" : "auto",
          //   width: fullScreenStates.graph1
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph1 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph1 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <IMGBarVal
              isValueData={[
                {
                  type: "Resolution Compliance",
                  value: 55,
                },
                {
                  type: "Noise Levels",
                  value: 50,
                },
              ]}
              minmax={[20,80]}

              chartType="bar"
              yaxisText="Number of images"
              StartTimer={""}
              StopTimer={""}
              openDrawerValue={true}
              titleText="Image Quality Distribution"
              indicateUnit=""
              tooltipName="Quality Metrics"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 14, lg: 9 }}
          lg={4}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph1 ? "fixed" : "relative",
          //   top: fullScreenStates.graph1 ? "9vh" : "auto",
          //   width: fullScreenStates.graph1
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph1 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph1 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <IMGBarVal
              isValueData={[
                {
                  type: "False Positive",
                  value: 45,
                },
                {
                  type: "False Negative",
                  value: 40,
                },
              ]}
              chartType="bar"
              yaxisText="Percentage"
              StartTimer={""}
              StopTimer={""}
              openDrawerValue={true}
              minmax={[0,100]}

              titleText="False Positive & False Negative Rates"
              indicateUnit="%"
              tooltipName="Detection Accuracy Metrics "
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          order={{ xs: 14, lg: 9 }}
          lg={4}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          // sx={{
          //   position: fullScreenStates.graph1 ? "fixed" : "relative",
          //   top: fullScreenStates.graph1 ? "9vh" : "auto",
          //   width: fullScreenStates.graph1
          //     ? "-webkit-fill-available !important"
          //     : "auto",
          //   height: fullScreenStates.graph1 ? "92vh" : "auto",
          //   margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
          //   zIndex: fullScreenStates.graph1 ? 999 : "auto",
          //   background: "#fff",
          // }}
        >
          <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
            <IMGBarVal
              isValueData={[
                {
                  type: "Downtime",
                  value: 1000,
                },
                {
                  type: "Production Loss",
                  value: 1500,
                },
                {
                  type: "Repair Cost",
                  value: 2500,
                },
              ]}
              chartType="bar"
              yaxisText="Cost"
              StartTimer={""}
              StopTimer={""}
              minmax={[200,4000]}
              openDrawerValue={true}
              titleText="Impact Assessment"
              indicateUnit="$"
              tooltipName="Types of Impact"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default DeatailedAnalysis;
