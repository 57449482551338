import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useHistory, useLocation } from "react-router-dom";
import "./UpperBar.scss";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Popover from "@mui/material/Popover";
import { Drawer } from "@mui/material";
import CommonText from "../Common/CommonText";
import DashboardIcon from "../SVG/DashboardIcon";
import MonitoringIcon from "../SVG/MonitoringIcon";
import ReportsIcon from "../SVG/ReportsIcon";
import AlarmssIcon from "../SVG/AlarmsIcon";
import ReportFailureIcon from "../SVG/ReportsFailureIcon";
import PlantViewIcon from "../SVG/PlantViewIcon";
import ThreeDIcon from "../SVG/ThreeDIcon";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { UserService } from "../Services/UserService";
import InverterIcon from "../SVG/InverterIcon";
import useErrorHandler from "../../utils/errorHandler";
import FileIcon from "../SVG/FileIcon";
import AbtIcon from "../SVG/AbtIcon";
import ImgUpload from "../SVG/ImgUpload";
import BatteryIcon from "../SVG/BatteryIcon";
import TrackerIcon from "../SVG/TrackerIcon";
import VCBPanelIcon from "../SVG/VCBPanelIcon";
import HTPanelIcon from "../SVG/HTPanelIcon";
import Bioprocess from "../SVG/Bioprocess";
const userService = new UserService();

function UpperBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPlantEl, setAnchorPlantEl] = useState(null);
  const [anchorMobileEl, setAnchorMobileEl] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [activeProfileTab, setActiveProfileTab] = useState("");
  const [selectedTab, setSelectedTab] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isDevicesMenuOpen, setIsDevicesMenuOpen] = useState(false);
  const [plantListsArray, setPlantListsArray] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  const roleType = localStorage.getItem("role");
  const emailType = localStorage.getItem("email");

  const handleFetchError = useErrorHandler();

  const profileItems = [
    {
      name: "My Profile",
      icon: PersonOutlineOutlinedIcon,
      path: "/iot-home/profile",
    },
    {
      name: "Subscriptions",
      icon: PersonOutlineOutlinedIcon,
      path: "/iot-home/profile",
    },
    {
      name: "My Plants",
      icon: SolarPowerIcon,
      path: "/iot-home/profile",
    },
    {
      name: "Help & Support",
      icon: HelpOutlineIcon,
      path: "iot-home/profile",
    },
    {
      name: "Invite Users",
      icon: GroupAddIcon,
      path: "/iot-home/invite-users",
    },
    {
      name: "Logout Account",
      icon: LogoutRoundedIcon,
      path: "/signin",
      color: "#D55F5A",
    },
  ];
  const monitoringDevices = [
    {
      name: "Inverter",
      path_url: "",
      icon: InverterIcon,
      fillColor: "#757575",
      path1:
        "M20 4H19.5V1H22C22.276 1 22.5 0.776 22.5 0.5C22.5 0.224 22.276 0 22 0H16C15.724 0 15.5 0.224 15.5 0.5C15.5 0.776 15.724 1 16 1H18.5V4H5C2.519 4 0.5 6.019 0.5 8.5V15.5C0.5 17.456 1.754 19.124 3.5 19.743V21.5C3.5 21.776 3.724 22 4 22C4.276 22 4.5 21.776 4.5 21.5V19.972C4.664 19.99 4.831 20 5 20H20C20.169 20 20.336 19.99 20.5 19.972V21.5C20.5 21.776 20.724 22 21 22C21.276 22 21.5 21.776 21.5 21.5V19.743C23.246 19.124 24.5 17.456 24.5 15.5V8.5C24.5 6.019 22.481 4 20 4ZM23.5 15.5C23.5 17.43 21.93 19 20 19H5C3.07 19 1.5 17.43 1.5 15.5V8.5C1.5 6.57 3.07 5 5 5H20C21.93 5 23.5 6.57 23.5 8.5V15.5ZM2.5 2.5C2.5 2.224 2.724 2 3 2H9C9.276 2 9.5 2.224 9.5 2.5C9.5 2.776 9.276 3 9 3H3C2.724 3 2.5 2.776 2.5 2.5ZM17.5 8C15.294 8 13.5 9.794 13.5 12C13.5 14.206 15.294 16 17.5 16C19.706 16 21.5 14.206 21.5 12C21.5 9.794 19.706 8 17.5 8ZM17.5 15C16.016 15 14.781 13.917 14.542 12.5H17C17.276 12.5 17.5 12.276 17.5 12C17.5 11.724 17.276 11.5 17 11.5H14.542C14.781 10.083 16.016 9 17.5 9C19.154 9 20.5 10.346 20.5 12C20.5 13.654 19.154 15 17.5 15ZM7.5 8C5.294 8 3.5 9.794 3.5 12C3.5 14.5 5.5 16 7.5 16C9.706 16 11.5 14.206 11.5 12C11.5 9.794 9.706 8 7.5 8ZM7.5 15C6.853 15 6.254 14.793 5.763 14.444L7.5 12.707C7.695 12.512 7.695 12.195 7.5 12C7.305 11.805 6.988 11.805 6.793 12L5.056 13.737C4.706 13.247 4.5 12.647 4.5 12C4.5 10.346 5.846 9 7.5 9C9.154 9 10.5 10.346 10.5 12C10.5 13.654 9.154 15 7.5 15Z",
    },
    {
      name: "MFM",
      path_url: "",
      icon: InverterIcon,
      fillColor: "#757575",
      path1:
        "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z",
    },
    {
      name: "ABT",
      icon: AbtIcon,
      fillColor: "#757575",
      path1:
        "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z",
    },
    {
      name: "SMB",
      path_url: "",
      icon: InverterIcon,
      fillColor: "#757575",
      path1:
        "M20 0H3C1.622 0 0.5 1.122 0.5 2.5V17.5C0.5 18.708 1.36 19.717 2.5 19.95V24H20.5V19.95C21.64 19.718 22.5 18.708 22.5 17.5V2.5C22.5 1.122 21.378 0 20 0ZM3.5 23V20H19.5V23H3.5ZM21.5 17.5C21.5 18.327 20.827 19 20 19H3C2.173 19 1.5 18.327 1.5 17.5V2.5C1.5 1.673 2.173 1 3 1H20C20.827 1 21.5 1.673 21.5 2.5V17.5ZM4.5 12H18.5V4H4.5V12ZM5.5 5H17.5V11H5.5V5ZM6.5 15.5C6.5 16.052 6.052 16.5 5.5 16.5C4.948 16.5 4.5 16.052 4.5 15.5C4.5 14.948 4.948 14.5 5.5 14.5C6.052 14.5 6.5 14.948 6.5 15.5ZM8.5 15H18.5V16H8.5V15Z",
    },
    {
      name: "Trackers",
      icon: TrackerIcon,
      fillColor: "#757575",

      },
    {
      name: "VCB Panel",
      path_url: "",
      icon: VCBPanelIcon,
      fillColor: "#757575",
      },
    {
      name: "HT Panel",
      path_url: "",
      icon: HTPanelIcon,
      fillColor: "#757575",
      },

    {
      name: "Weather Dashboard",
      path_url: "",
      icon: InverterIcon,
      fillColor: "#757575",
      path1:
        "M7.6235 19H16.3765C16.5392 18.9955 16.6972 19.0549 16.8168 19.1654C16.9363 19.2758 17.0079 19.4287 17.0163 19.5913C17.0247 19.7538 16.9691 19.9132 16.8615 20.0354C16.7539 20.1575 16.6028 20.2328 16.4405 20.245L16.3765 20.248H7.6235C7.46078 20.2525 7.30276 20.1931 7.18321 20.0826C7.06366 19.9722 6.99206 19.8193 6.9837 19.6567C6.97535 19.4942 7.0309 19.3348 7.13849 19.2126C7.24608 19.0905 7.39718 19.0152 7.5595 19.003L7.6235 19ZM4.6235 16.5H19.376C19.5365 16.4992 19.691 16.5603 19.8076 16.6706C19.9242 16.7808 19.9939 16.9317 20.0021 17.092C20.0104 17.2522 19.9566 17.4095 19.8519 17.5311C19.7472 17.6527 19.5997 17.7293 19.44 17.745L19.376 17.748H4.624C4.46354 17.7488 4.30896 17.6877 4.19236 17.5774C4.07576 17.4672 4.0061 17.3163 3.99787 17.156C3.98963 16.9958 4.04344 16.8385 4.14812 16.7169C4.2528 16.5953 4.40031 16.5187 4.56 16.503L4.6235 16.5ZM12 3.5C15.1685 3.5 16.966 5.5975 17.2275 8.13H17.3075C19.3465 8.13 20.9995 9.779 20.9995 11.8125C20.9995 13.846 19.3465 15.495 17.3075 15.495H6.6925C4.653 15.495 3 13.8465 3 11.8125C3 9.7785 4.653 8.13 6.692 8.13H6.772C7.035 5.5805 8.831 3.5 12 3.5ZM12 4.7475C9.8695 4.7475 8.0125 6.4715 8.0125 8.8525C8.0125 9.23 7.6845 9.5265 7.3085 9.5265H6.5985C5.3015 9.5265 4.25 10.583 4.25 11.8865C4.25 13.1905 5.3015 14.2475 6.5985 14.2475H17.4015C18.6985 14.2475 19.75 13.1905 19.75 11.887C19.75 10.5835 18.6985 9.526 17.4015 9.526H16.6915C16.3155 9.526 15.9875 9.23 15.9875 8.853C15.9875 6.441 14.1305 4.748 12 4.748",
    },
    {
      name: "Battery",
      icon: BatteryIcon,
      fillColor: "#757575",
      },
  ];
  const sidebarTabs = [
    {
      name: "Executive Dashboard",
      icon: DashboardIcon,
      path_url: "/iot-home",
    },
    {
      name: "Device Monitoring",
      icon: MonitoringIcon,
    },
    {
      name: "Reporting Hub",
      icon: ReportsIcon,
      path_url: "/iot-home/reports",
    },
    {
      name: "Early Warning System",
      icon: AlarmssIcon,
      path_url: "/iot-home/alarms",
    },
    {
      name: "Fault Analysis",
      icon: ReportFailureIcon,
      path_url: "/iot-home/fault_analysis",
    },
    {
      name: "Plant Layout",
      icon: PlantViewIcon,
      path_url: "/iot-home/plant-layout",
    },
    {
      name: "3D Digital Twin",
      icon: ThreeDIcon,
      path_url: "/iot-home/three-d-twin",
    },
    {
      name: "AI Doc Explorer",
      icon: FileIcon,
      path_url: "/iot-home/file-upload",
    },
    {
      name: "Image Analysis",
      icon: ImgUpload,
      path_url: "/iot-home/img-upload",
    },
    
  ];
  if (emailType === "demo_us@smarttrak.co") {
    sidebarTabs.splice(7, 0, {
      name: "Bioprocess",
      icon: Bioprocess,
    });
  }

  useEffect(() => {
    handleUserDetails();
  }, []);

  useEffect(() => {
    getPlantsData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setActiveTabBasedOnLocation(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("plantId");
    history.push("/signin");
  };
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePlantClick = (event) => {
    setAnchorPlantEl(event.currentTarget);
  };
  const handleMobilePlantClick = (event) => {
    setAnchorMobileEl(event.currentTarget);
  };
  const handlePlantPopoverClose = (plant) => {
    localStorage.setItem("plantId", plant?.id);
    const event = new CustomEvent("plantIdChange", {
      detail: { plantId: plant?.id },
    });
    window.dispatchEvent(event);
    setAnchorPlantEl(null);
  };

  const handlePopoverClose = (val, id) => {
    setAnchorEl(null);
    switch (val) {
      case "My Profile":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
            id: id,
          },
        });
        break;
      case "Subscriptions":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
            id: id,
          },
        });
        break;
      case "Help & Support":
        history.push({
          pathname: `/iot-home/profile`,
          state: {
            id: id,
          },
        });

        break;
      default:
        break;
    }
  };
  const handleUserDetails = async () => {
    try {
      setLoading(true);
      const res = await userService.getUserDetails();
      if (res.status === 200) {
        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleFetchError(error, "user details");
    }
  };
  const getPlantsData = async () => {
    try {
      setLoading(true);
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleFetchError(error, "plants data");
    }
  };

  const handleInviteUsers = () => {
    history.push(`/iot-home/invite-users`);
    setOpenDrawer(false);
  };

  const setActiveTabBasedOnLocation = (pathname) => {
    const tabMapping = {
      "/iot-home": "Executive Dashboard",
      "/iot-home/reports": "Reporting Hub",
      "/iot-home/alarms": "Early Warning System",
      "/iot-home/fault_analysis": "Fault Analysis",
      "/iot-home/plant-layout": "Plant Layout",
      "/iot-home/three-d-twin": "3D Digital Twin",
      "/iot-home/file-upload": "AI Doc Explorer",
      "/iot-home/img-upload": "Image Analysis",
      "/iot-home/bioprocess": "Bioprocess",


      "/iot-home/dev-monitoring/inv-dashboard": "Device Monitoring",
      "/iot-home/dev-monitoring/mfm": "Device Monitoring",
      "/iot-home/dev-monitoring/mfm-data": "Device Monitoring",
      "/iot-home/dev-monitoring/abt": "Device Monitoring",
      "/iot-home/dev-monitoring/smb": "Device Monitoring",
      "/iot-home/dev-monitoring/smb-details": "Device Monitoring",
      "/iot-home/dev-monitoring/trackers": "Device Monitoring",
      "/iot-home/dev-monitoring/trackers/home": "Device Monitoring",
      "/iot-home/dev-monitoring/vcb-panel": "Device Monitoring",
      "/iot-home/dev-monitoring/vcb/vcb-details": "Device Monitoring",
      "/iot-home/dev-monitoring/ht-panel": "Device Monitoring",
      "/iot-home/dev-monitoring/weather-dashboard": "Device Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Device Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Device Monitoring",
    };

    const activeTab = tabMapping[pathname] || "";
    setActiveTab(activeTab);
    const deviceTabMapping = {
      "/iot-home/dev-monitoring/inv-dashboard": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Inverter",
      "/iot-home/dev-monitoring/mfm": "MFM",
      "/iot-home/dev-monitoring/mfm-data": "MFM",
      "/iot-home/dev-monitoring/abt": "ABT",
      "/iot-home/dev-monitoring/smb": "SMB",
      "/iot-home/dev-monitoring/smb/smb-details": "SMB",
      "/iot-home/dev-monitoring/trackers": "Trackers",
      "/iot-home/dev-monitoring/trackers/home": "Trackers",
      "/iot-home/dev-monitoring/vcb-panel": "VCB Panel",
      "/iot-home/dev-monitoring/vcb/vcb-details": "VCB Panel",
      "/iot-home/dev-monitoring/ht-panel": "HT Panel",
      "/iot-home/dev-monitoring/weather-dashboard": "Weather Dashboard",
      "/iot-home/dev-monitoring/battery": "Battery",

    };
    const selectedTab = deviceTabMapping[pathname];
    setSelectedTab(selectedTab);
  };
  useEffect(() => {
    handleMenuItemClick(setActiveTab);
  }, [setActiveTab]);

  const handleMenuClick = () => {
    setOpenMenu(true);
  };
  const handleProfileClick = () => {
    setIsProfileMenuOpen((prev) => !prev);
  };
  const handleMonitoringClick = () => {
    setIsDevicesMenuOpen((prev) => !prev);
  };

  const handleProfileItemClick = (name, path, id) => {
    setActiveProfileTab(name);
    setOpenMenu(false);
    history.push({ pathname: path, state: { id: id } });
  };

  const handleMenuItemClick = async (tab) => {
    setActiveTab(tab);
    if (tab !== "Device Monitoring") {
      setOpenMenu(false);
    }
    switch (tab) {
      case "Executive Dashboard":
        history.push("/iot-home");
        break;
      case "Reporting Hub":
        history.push("/iot-home/reports");
        break;
      case "Early Warning System":
        history.push("/iot-home/alarms");
        break;
      case "Fault Analysis":
        history.push("/iot-home/fault_analysis");
        break;
      case "Plant Layout":
        history.push("/iot-home/plant-layout");
        break;
      case "3D Digital Twin":
        history.push("/iot-home/three-d-twin");
        break;
      case "AI Doc Explorer":
        history.push("/iot-home/file-upload");
        break;
      case "Image Analysis":
        history.push("/iot-home/img-upload");
        break;
        case "Bioprocess":
          history.push("/iot-home/bioprocess");
          break;

      case "Device Monitoring":
        setOpenDrawer(!openDrawer);
        break;
      default:
        break;
    }
  };

  const handleDevicesMenuClick = async (deviceName) => {
    const routes = {
      Inverter: "/iot-home/dev-monitoring/inv-dashboard",
      MFM: "/iot-home/dev-monitoring/mfm",
      ABT: "/iot-home/dev-monitoring/abt",
      SMB: "/iot-home/dev-monitoring/smb",
      Trackers: "/iot-home/dev-monitoring/trackers",
      "VCB Panel": "/iot-home/dev-monitoring/vcb-panel",
      "HT Panel": "/iot-home/dev-monitoring/ht-panel",
      "Weather Dashboard": "/iot-home/dev-monitoring/weather-dashboard",
      Battery: "/iot-home/dev-monitoring/battery",

    };

    if (routes[deviceName]) {
      history.push(routes[deviceName]);
      setOpenDrawer(false);
      setOpenMenu(false);
    }
    setSelectedTab(deviceName);
  };

  return (
    <div>
      {/* {loading && <SimpleBackdrop open={loading} />} */}
      <AppBar className="upperbar_main" position="static">
        <Toolbar className="toolBar">
          <div className="icon-container">
            <div className="plantdropdown" onClick={handlePlantClick}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                  alt="plant"
                  style={{ width: "1.8vw" }}
                />
              </div>
              <div className="green-tab">
                <p className="Poppins_12px_rem" style={{ fontWeight: "400" }}>
                  {plantListsArray.length > 0 ? plantListsArray[0].name : "--"}
                </p>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/dropDown.svg"
                  alt="plant"
                  style={{ width: "1vw" }}
                />
              </div>
            </div>

            <div className="avatar_div" onClick={handleAvatarClick}>
              <div className="green-tab">
                <p className="Poppins_24px_rem font_400" style={{fontFamily:'Poppins-Regular', textTransform:"capitalize"}}>{userData.first_name && userData.last_name
                    ? userData?.first_name.charAt(0) : "--"}</p>
              </div>
            </div>
            {plantListsArray.length > 0 && (
              <Popover
                open={Boolean(anchorPlantEl)}
                anchorEl={anchorPlantEl}
                onClose={() => setAnchorPlantEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "left",
                  horizontal: "center",
                }}
                sx={{
                  borderRadius: "10px !important",
                }}
              >
                <div className="dropdown-div">
                  {plantListsArray.map((ele, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handlePlantPopoverClose(ele)}
                      style={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                        alt="plant"
                        style={{
                          width: "1.8vw",
                          borderRadius: "50%",
                          border: "2px solid black",
                        }}
                      />
                      <p className="Poppins_12px_rem">{ele.name}</p>
                    </MenuItem>
                  ))}
                </div>
              </Popover>
            )}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "left",
                horizontal: "center",
              }}
              sx={{
                borderRadius: "10px !important",
              }}
            >
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flexStart",
                    flexDirection: "column",
                  }}
                >
                  <CommonText
                    type="Bold_700"
                    text={
                      userData.first_name && userData.last_name
                        ? `${userData.first_name} ${userData.last_name}`
                        : "--"
                    }
                  />

                  <CommonText
                    type="Regular_400"
                    text={
                      userData.role
                        ? userData.role.charAt(0).toUpperCase() +
                          userData.role.slice(1).toLowerCase()
                        : "--"
                    }
                    styles={{ textTransform: "capitalize", color: "#969696" }}
                  />
                </div>
                <Divider
                  sx={{ mt: 0.6, mb: 0.6 }}
                  style={{ border: "1px solid #969696" }}
                />

                <MenuItem
                  onClick={() => handlePopoverClose("My Profile", 0)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineOutlinedIcon style={{ color: "#00529A" }} />
                  <CommonText type="Regular_400" text="My Profile" />
                </MenuItem>

                <MenuItem
                  onClick={() => handlePopoverClose("Subscriptions", 1)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineOutlinedIcon style={{ color: "#00529A" }} />
                  <CommonText type="Regular_400" text="Subscriptions" />
                </MenuItem>
                <MenuItem
                  onClick={() => handlePopoverClose("My Plants", 2)}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/myplants.svg"
                    alt="plant"
                    style={{ width: "20px" }}
                  />
                  <CommonText type="Regular_400" text="My Plants" />
                </MenuItem>
                <MenuItem
                  onClick={() => handlePopoverClose("Help & Support", 3)}
                  value={"Help & Support"}
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <HelpOutlineIcon style={{ color: "#00529A" }} />

                  <CommonText type="Regular_400" text="Help & Support" />
                </MenuItem>
                {roleType === "EXECUTIVE" && (
                  <MenuItem
                    onClick={handleInviteUsers}
                    value={"Invite Users"}
                    style={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/mdi_invite.svg"
                      alt="plant"
                      style={{ width: "20px" }}
                    />
                    <CommonText type="Regular_400" text="Invite Users" />
                  </MenuItem>
                )}

                <MenuItem
                  onClick={handleLogout}
                  value={"Logout"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px",
                    color: "#FAAD43",
                  }}
                >
                  <LogoutRoundedIcon style={{ color: "#00529A" }} />

                  <CommonText type="Regular_400" text="Logout" />
                </MenuItem>
              </div>
            </Popover>
          </div>
          {isMobile && (
            <>
              <div className="menu_container" onClick={handleMenuClick}>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/menu.svg"
                  alt="menuicon"
                />
              </div>
            </>
          )}
        </Toolbar>
        <Drawer
          anchor="right"
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "80%",
              backgroundColor: "#ffff",
              padding: "1rem",
            },
          }}
        >
          <div className="profile_container">
            <div>
              <img
                src="https://s3.amazonaws.com/smarttrak.co/v2Images/Avatar.svg"
                style={{ width: "3rem" }}
                alt="profile"
              />
            </div>
            <div className="profile_data">
              <p
                className="Poppins_10px_rem"
                style={{ color: "#757575", fontWeight: "500" }}
              >
                {userData.role
                  ? userData.role.charAt(0).toUpperCase() +
                    userData.role.slice(1).toLowerCase()
                  : "--"}
              </p>
              <p
                className="Poppins_14px_rem"
                style={{ color: "#000000", fontWeight: "500" }}
              >
                {userData.first_name && userData.last_name
                  ? `${userData.first_name} ${userData.last_name}`
                  : "--"}
              </p>
              <div
                className="plantdropdown_two"
                onClick={handleMobilePlantClick}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                    alt="plant"
                    style={{ width: "1.5rem" }}
                  />
                </div>
                <div className="green-tab">
                  <p className="Poppins_8px_rem">
                    {plantListsArray.length > 0
                      ? plantListsArray[0].name
                      : "--"}
                  </p>
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/dropDown.svg"
                    alt="plant"
                    style={{ width: "0.5rem" }}
                  />
                </div>
              </div>
            </div>
            {plantListsArray.length > 0 && (
              <Popover
                open={Boolean(anchorMobileEl)}
                anchorEl={anchorMobileEl}
                onClose={() => setAnchorMobileEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "left",
                  horizontal: "center",
                }}
                sx={{
                  borderRadius: "10px !important",
                }}
              >
                <div className="dropdown-div" style={{ padding: "0rem" }}>
                  {plantListsArray.map((ele, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handlePlantPopoverClose(ele.name)}
                      style={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerPlant.svg"
                        alt="plant"
                        style={{
                          width: "1.5rem",
                          borderRadius: "50%",
                          border: "2px solid black",
                        }}
                      />
                      <p className="Poppins_8px_rem">{ele.name}</p>
                    </MenuItem>
                  ))}
                </div>
              </Popover>
            )}
          </div>
          <Divider
            sx={{ margin: "1.5rem 0rem" }}
            style={{ border: "2px solid #F6F6F6" }}
          />
          <div className="profile_dropdown" onClick={handleProfileClick}>
            <p
              className="Poppins_14px_rem"
              style={{ fontWeight: "500", color: "#757575" }}
            >
              Profile
            </p>
            {isProfileMenuOpen ? (
              <ExpandLessIcon style={{ marginLeft: "0.5vw" }} />
            ) : (
              <ExpandMoreIcon style={{ marginLeft: "0.5vw" }} />
            )}
          </div>
          {isMobile && isProfileMenuOpen && (
            <div
              className="dropdown-pro-mobile"
              style={{ background: "transparent", marginTop: "0rem" }}
            >
              {profileItems && (
                <div
                  className="profile_items_container"
                  style={{
                    width: "100%",
                    left: "0vw",
                    top: "0vh",
                    position: "relative",
                  }}
                >
                  {profileItems.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="profile_items_div"
                        style={{
                          backgroundColor:
                            activeProfileTab === item.name
                              ? "#F6F6F6"
                              : "transparent",
                        }}
                        onClick={() =>
                          handleProfileItemClick(item.name, item.path, index)
                        }
                      >
                        {item.icon ? (
                          <item.icon
                            sx={{
                              color:
                                item.name === "Logout Account"
                                  ? "#D55F5A"
                                  : activeProfileTab === item.name
                                  ? "black"
                                  : "#757575",
                            }}
                          />
                        ) : (
                          <img src={item.img_url} alt="icons" />
                        )}
                        <p
                          className="Poppins_14px_rem"
                          style={{
                            fontWeight: "500",
                            color:
                              item.name === "Logout Account"
                                ? "#D55F5A"
                                : activeProfileTab === item.name
                                ? "black"
                                : "#757575",
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div className="tabs_container">
            {sidebarTabs.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleMenuItemClick(item.name)}
                  className="sidebar_tabs"
                  style={{
                    flexDirection:
                      isDevicesMenuOpen && item.name === "Device Monitoring"
                        ? "column"
                        : "row",
                    backgroundColor:
                      activeTab !== "Device Monitoring" &&
                      (activeTab === item.name ? "#F6F6F6" : "transparent"),
                  }}
                >
                  {item.name === "Device Monitoring" ? (
                    <div className="sidebar_tabs" style={{ padding: "0rem" }}>
                      {item.icon ? (
                        <item.icon
                          width="1.7rem"
                          height="1.7rem"
                          strokeColor={
                            activeTab === item.name ? "black" : "#757575"
                          }
                        />
                      ) : (
                        <div>--</div>
                      )}
                      <div
                        className="profile_dropdown"
                        style={{ padding: "0rem" }}
                        onClick={
                          item.name === "Device Monitoring"
                            ? handleMonitoringClick
                            : undefined
                        }
                      >
                        <p
                          className="Poppins_14px_rem"
                          style={{ fontWeight: "500", color: "#757575" }}
                        >
                          {item.name}
                        </p>
                        {item.name === "Device Monitoring" &&
                          (isDevicesMenuOpen ? (
                            <ExpandLessIcon style={{ marginLeft: "0.5vw" }} />
                          ) : (
                            <ExpandMoreIcon style={{ marginLeft: "0.5vw" }} />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <>
                      {item.icon ? (
                        <item.icon
                          width="1.7rem"
                          height="1.7rem"
                          strokeColor={
                            activeTab === item.name ? "black" : "#757575"
                          }
                        />
                      ) : (
                        <div>--</div>
                      )}
                      <p
                        className="Poppins_14px_rem"
                        style={{
                          fontWeight: "500",
                          color: activeTab === item.name ? "black" : "#757575",
                        }}
                      >
                        {item.name}
                      </p>
                    </>
                  )}
                  {isMobile &&
                    item.name === "Device Monitoring" &&
                    isDevicesMenuOpen && (
                      <div
                        className="dropdown-pro-mobile"
                        style={{ background: "transparent", marginTop: "0rem" }}
                      >
                        {monitoringDevices &&
                          item.name === "Device Monitoring" && (
                            <div
                              className="profile_items_container"
                              style={{
                                width: "100%",
                                left: "0vw",
                                top: "0vh",
                                position: "relative",
                                marginLeft: "0rem",
                              }}
                            >
                              {monitoringDevices.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="profile_items_div"
                                    style={{
                                      backgroundColor:
                                        selectedTab === item.name
                                          ? "#F6F6F6"
                                          : "transparent",
                                    }}
                                    onClick={() =>
                                      handleDevicesMenuClick(item.name)
                                    }
                                  >
                                    {item.icon ? (
                                      <item.icon
                                        width="1.7rem"
                                        fillColor={
                                          selectedTab === item.name
                                            ? "black"
                                            : item.fillColor
                                        }
                                        fillColorPath2={item.fillColorPath2}
                                        strokeColorPath2={
                                          selectedTab === item.name
                                            ? "black"
                                            : item.strokeColorPath2
                                        }
                                        height="1.7rem"
                                        path1={item.path1}
                                        path2={item.path2}
                                      />
                                    ) : (
                                      <img src={item.img_url} alt="icons" />
                                    )}
                                    <p
                                      className="Poppins_14px_rem"
                                      style={{
                                        fontWeight: "500",
                                        color:
                                          selectedTab === item.name
                                            ? "black"
                                            : "#757575",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        </Drawer>
      </AppBar>
    </div>
  );
}

export default UpperBar;
