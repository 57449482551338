import React, { useState, useRef, useEffect, useMemo, memo } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import "./graphCSS.css"
import "./BarPlotForIOT.scss";
import { Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Backdrop, Modal, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import MonthRangeSelection from "../Common/MonthRangeSelection";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "8vh 30px 20px 30px",
  backgroundColor: "white",
  color: "white",
};
const InvBarPlot = memo((props) => {
  const {
    isValueData,
    titleText,
    tooltipName,
    indicateUnit, stringDurationFun,
    selectedDateRangeProp, isFilter,
    isMobileAndTab,
    isDesktopView,
    isFullScreen, toggleFullScreen, dailyData, loadState
  } = props;
  const seriesData = useMemo(() => {
    return isValueData ? isValueData.map((item) => item.value) : [];
  }, [isValueData]);

  const xaxisLabels = useMemo(() => {
    return isValueData ? isValueData.map((item) => {
      const date = new Date(item.timestamp_edge);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return `${day} ${month}`;
    }) : [];
  }, [isValueData]);

  const [xaxisData, setXaxisData] = useState(xaxisLabels);
  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [dayCount, setDayCount] = useState(1);
  const [durationType, setDurationType] = useState([
    "Daily",
    "Weekly",
    "Monthly",
    "Yearly",
  ]);
  const [monthCount, setMonthCount] = useState(0);
  const [series, setSeries] = useState([
    {
      name: tooltipName,
      data: seriesData,
    },
  ]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const [tempStartDateTime, setTempStartDateTime] = useState(new Date());
  const [tempStopDateTime, setTempStopDateTime] = useState(new Date());
  const [isDurationType, setIsDurationType] = useState("Daily");
  const handleClear = (event) => {
    event.preventDefault();
    setDayCount(1);
    setMonthCount(0);
    const currentDate = new Date();
    setTempStopDateTime(currentDate);
    setTempStartDateTime(currentDate);
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const funDate = (date) => {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const parts = date.split(/[T:+-]/);
    const date1 = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
    return date1;
  };
  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");

  const initialDateRange = selectedDateRangeProp.map((range) => {
    return {
      ...range,
      startDate: isStartTime || funDate(range.startDate),
      endDate: isStopTime || funDate(range.endDate),
    };
  });
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const [options, setOptions] = useState({
    chart: { type: "bar" },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: xaxisLabels.length <= 2 ? "5%" : "30%",
      },
    },
    colors: ["#90E900"],
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: xaxisData,
      labels: {
        style: {
          colors: "#000000",
        },
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: "Value in k",
      },
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },

        style: {
          colors: "#000000",
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: { lines: { show: true } },
    },
    annotations: {
      yaxis: dailyData?.average
        ? [
          {
            y: dailyData?.average,
            borderColor: "#FF4560",
            strokeDashArray: 5,
            label: {
              borderColor: "#FF4560",
              style: { color: "#fff", background: "#FF4560" },
              text: `Average: ${dailyData?.average.toFixed(2)} ${indicateUnit}`,
            },
          },
        ]
        : [],
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => (
        `<div class="arrow_box">
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">Date:</span> ${w.globals.labels[dataPointIndex]}</span><br>
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">${tooltipName}:</span> ${parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2)} ${indicateUnit}</span><br>
          </div>`
      ),
    },
    toolbar: {
      show: false,
    },
  });

  useEffect(() => {
    if (
      (isStartTimeEnergy && isStopTimeEnergy) ||
      (tempStartDateTime && tempStopDateTime)
    ) {
      let availableFilters = [];
      if (dayCount < 7 && monthCount < 2) {
        availableFilters = ["Daily"];
      } else if (dayCount > 6 && dayCount <= 62 && monthCount < 2) {
        availableFilters = ["Daily", "Weekly"];
      } else if (dayCount > 62 && dayCount < 365) {
        availableFilters = ["Weekly", "Monthly"];
      } else if (dayCount >= 365) {
        availableFilters = ["Monthly", "Yearly"];
      } else if (monthCount > 1) {
        availableFilters = ["Monthly"]
      }

      setDurationType(availableFilters);

      if (
        availableFilters.length > 0 &&
        !availableFilters.includes(isDurationType)
      ) {
        setIsDurationType(availableFilters[0]);
      }
    }
  }, [
    isDurationType,
    isStopTimeEnergy,
    isStartTimeEnergy,
    tempStartDateTime,
    tempStopDateTime,
    monthCount
  ]);


  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen();
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);

  useEffect(() => {
    setSeries([
      {
        name: tooltipName,
        data: seriesData,
      },
    ]);

    setXaxisData(xaxisLabels);
    setOptions((prevOptions) => ({
      ...prevOptions,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: xaxisLabels.length <= 2 ? "5%" : "30%",
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisLabels.map((dateTime, index) => {
          if (xaxisLabels.length >= 1) {
            if (isDurationType === "Monthly") {
              const date = new Date(dateTime);
              const month = date.toLocaleString("default", { month: "short" });
              return `${month}`;
            } if (xaxisLabels.length >= 4) {
              const date = new Date(dateTime);
              const month = date.toLocaleString("default", { month: "short" });
              const day = date.getDate();
              return `${day} ${month}`;

            } else {
              const date = new Date(dateTime);
              const month = date.toLocaleString("default", { month: "short" });
              const day = date.getDate();
              return ` ${day} ${month}`;
            }
          }
        }),
        labels: {
          style: {
            colors: "#000000",
          },
        },
      },
      annotations: {
        yaxis: dailyData?.average
          ? [
            {
              y: dailyData?.average,
              borderColor: "#FF4560",
              strokeDashArray: 5,
              label: {
                borderColor: "#FF4560",
                style: { color: "#fff", background: "#FF4560" },
                text: `Average: ${dailyData?.average.toFixed(2)} ${indicateUnit}`,
              },
            },
          ]
          : [],
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => (
          `<div class="arrow_box">
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">Date:</span> ${w.globals.labels[dataPointIndex]}</span><br>
            <span class="raleway_12px_rem"><span style="color: #2F67F8;">${tooltipName}:</span> ${parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2)} ${indicateUnit}</span><br>
          </div>`
        ),
      },
      yaxis: {
        ...prevOptions.yaxis,

        title: {
          text: `Value in ${indicateUnit}`,
        },
        labels: {
          formatter: function (value) {
            return Math.round(value);
          },

          style: {
            colors: "#000000",
          },
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
    }

  }, [
    isValueData,
    isFullScreen,
    xaxisLabels,
    seriesData,
    tooltipName,
    indicateUnit,
    titleText,

  ]);
  useEffect(() => {
    if (isStartTime && isStopTime) {
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0], // Keep other properties intact
          startDate: isStartTime,
          endDate: isStopTime,
        },
      ]);

      if (isStartTime.getFullYear() === isStopTime.getFullYear() && isStartTime.getMonth() === isStopTime.getMonth()) {
        setMonthCount(1); 
      }
      setMonthCount((isStopTime.getFullYear() - isStartTime.getFullYear()) * 12 + (isStopTime.getMonth() - isStartTime.getMonth()) + 1);
    }

  }, [isStartTime, isStopTime])
  const chartContainerRef = useRef(null);

  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);
    const svg64 = btoa(xml);
    const imgSrc = `data:image/svg+xml;base64,${svg64}`;
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);

  };
  const handleStopDateTimeChange = (date) => {
    setTempStopDateTime(date);
    const currentDate = new Date();
    const selectedDate = new Date(date);

    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    let hours, minutes;
    if (selectedDate.toDateString() === currentDate.toDateString()) {
      hours = currentDate.getHours().toString().padStart(2, "0");
      minutes = currentDate.getMinutes().toString().padStart(2, "0");
    } else {
      hours = "23";
      minutes = "59";
    }
    const formattedTimestampStop = `${year}-${month}-${day}T${hours}:${minutes}:00%2B05:30`;

    props.StopTimer(formattedTimestampStop);
    setIsStopTimeEnergy(formattedTimestampStop);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    setTempStartDateTime(date);
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const formattedTimestampStart = `${year}-${month}-${day}T00:00:00%2B05:30`;
    props.StartTimer(formattedTimestampStart);
    setIsStartTimeEnergy(formattedTimestampStart);
    props.openDrawerValue(openDrawer);
  };
  const handleApply = () => {
    let selectedDuration;

    if (dayCount < 7 && monthCount < 2) {
      selectedDuration = isDurationType || "Daily";
    } else if (dayCount > 6 && dayCount <= 62 && monthCount < 2) {
      selectedDuration = isDurationType || "Weekly";
    } else if ((dayCount > 62 && dayCount < 365)) {
      selectedDuration = isDurationType || "Monthly";
    } else if (dayCount >= 365) {
      selectedDuration = isDurationType || "Yearly";
    }

    if (monthCount < 2) {
      if (selectedDuration) {
        props.stringDurationFun(getDurationCode(selectedDuration));
      }
      handleStartDateTimeChange(tempStartDateTime);
      handleStopDateTimeChange(tempStopDateTime);
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0],
          startDate: funDate(tempStartDateTime),
          endDate: funDate(tempStopDateTime),
        },
        ...prevSelectedDateRange.slice(1),
      ]);
    } else {
      props.stringDurationFun("ME")
      handleStartDateTimeChange(isStartTime);
      handleStopDateTimeChange(isStopTime);
      setSelectedDateRange((prevSelectedDateRange) => [
        {
          ...prevSelectedDateRange[0],
          startDate: funDate(isStartTime),
          endDate: funDate(isStopTime),
        },
        ...prevSelectedDateRange.slice(1),
      ]);
    }

    setOpenDrawer(false);
  };
  const getDurationCode = (durationType) => {
    setIsDurationType(durationType);

    let availableFilters;
    if (dayCount < 7 && monthCount < 2) {
      availableFilters = ["Daily"];
    } else if ((dayCount > 6 && dayCount <= 62 && monthCount < 2)) {
      availableFilters = ["Daily", "Weekly"];
    } else if (dayCount > 62 && dayCount < 365) {
      availableFilters = ["Weekly", "Monthly"];
    } else if (dayCount >= 365) {
      availableFilters = ["Monthly", "Yearly"];
    } else if (monthCount > 1) {
      availableFilters = ["Monthly"]
    } else {
      availableFilters = [];
    }

    setDurationType(availableFilters);

    switch (durationType) {
      case "Daily":
        return "D";
      case "Weekly":
        return "W";
      case "Monthly":
        return "ME";
      case "Yearly":
        return "YE";
      default:
        return "";
    }
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    return monthCount > 1 || date > today;
  };
  const calculateDateRangeLength = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    const timeDifference = end.getTime() - start.getTime();

    const dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

    return dayCount;
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: "#212121" }}
          >
            {isValueData?.title?.text ?? titleText}
          </p>{" "}
        </div>
        <div style={{
          display: "flex", gap: "10px",

          alignItems: "center",
          justifyContent: "center"
        }}>

          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>


          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
              className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={toggleFullScreen}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                  className='icon-css'
                />
              ) : (
                <FullscreenIcon className='icon-css' />
              )}
            </button>
          </Tooltip>
        </div>

      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />

      {openDrawer && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openDrawer}>
            <Box sx={boxStyle}>
              <div
                onClick={handleClose}
                className="handleclose-subdiv"
                style={{ position: "absolute" }}
              >
                <CloseIcon sx={{ color: "#2F67F8" }} />
              </div>

              <div className="calender-container" style={{
                marginTop: monthCount > 1 ? "2rem" : "0rem"
              }}>
                <div id="custom_date_picker" style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="range_title"
                    style={{
                      width: "fit-content",
                      position: "relative",
                      display: "flex",
                      gap: "2.8rem",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p
                      className="raleway_20px_rem"
                      style={{ color: "black", fontWeight: "700", width: "36%" }}
                    >
                      Range Filters
                    </p>
                    <div style={{ width: "54%" }}>
                      <MonthRangeSelection
                        onMonthCountChange={setMonthCount}
                        isStartTimeChange={setIsStartTime}
                        isStopTimeChange={setIsStopTime}
                      />
                    </div>
                  </div>

                  <DateRangePicker
                    months={1}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={selectedDateRange}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      const endDate = ranges.selection.endDate;
                      const lengthOfRange = calculateDateRangeLength(
                        startDate,
                        endDate
                      );
                      setDayCount(lengthOfRange);
                      if (startDate === endDate) {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      } else {
                        setTempStartDateTime(startDate);
                        setTempStopDateTime(endDate);
                      }
                      setSelectedDateRange([ranges.selection]);
                    }}
                  />
                </div>
                {isFilter && (
                  <div className="intervals_container"
                    style={{
                      top: monthCount > 1 ? "0rem" : "2rem", width: monthCount > 1 ? "100%" : "auto"
                    }}>
                    <div className="interval_filter_combined">
                      <p
                        className="raleway_20px_rem"
                        style={{
                          color: "black",
                          fontWeight: "700",
                          paddingLeft: "1rem",
                        }}
                      >
                        Interval Filters
                      </p>
                      <div className="filter_container">

                        {durationType.map((field, index) => {
                          return (
                            <div
                              key={index}
                              className="filter_button"
                              style={{
                                backgroundColor:
                                  isDurationType === field
                                    ? "rgb(222 231 246)"
                                    : "transparent",
                              }}
                              onClick={() => getDurationCode(field)}
                            >
                              <p className="rdrStaticRangeLabel">
                                {field
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </p>
                            </div>
                          );
                        })}

                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        className="btn_style"
                        style={{
                          backgroundColor: "#2F67F8",
                        }}
                        onClick={handleApply}
                      >
                        {" "}
                        <p className="raleway_20px_rem">Apply</p>
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        className="btn_style"
                        style={{
                          backgroundColor: "#FF472E",
                        }}
                        onClick={handleClear}
                      >
                        <p className="raleway_20px_rem">Clear</p>
                      </Button>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "end",
                      gap: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      className="btn_style"
                      style={{
                        backgroundColor: "#2F67F8",
                      }}
                      onClick={handleApply}
                    >
                      {" "}
                      <p className="raleway_20px_rem">Apply</p>
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      className="btn_style"
                      style={{
                        backgroundColor: "#FF472E",
                      }}
                      onClick={handleClear}
                    >
                      <p className="raleway_20px_rem">Clear</p>
                    </Button>
                  </div>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      )}

      <div
        style={{
          height: Object.keys(isValueData).length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",

        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >

        {Object.keys(isValueData).length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "350px",

              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">{loadState ? "" : "No Data"}</p>
          </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
            <div style={{ width: xaxisData.length > 30 ? xaxisData.length * 50 : '100%' }}>
              {dailyData?.sum ? <p>Total Energy : <span style={{ fontWeight: "700" }}>{dailyData?.sum} {indicateUnit}</span><span> (as per selected date range)</span></p> : ""}
              <Chart options={options} series={series} type={isValueData?.chart?.type || "bar"} height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "330"} />
            </div>
          </div>)}
      </div>
    </div>
  );
});

export default InvBarPlot;