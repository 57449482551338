import React, { useState, useEffect } from "react";
import "./ExceutiveDashboard.scss";
import WeatherBoard from "../../Common/WeatherBoard";
import "../../Common/FontSizes.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import BarPlotForIOT from "../../Graphs/BarPlotForIOT";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import { UserService } from "../../Services/UserService";
import { useHistory } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Chatbot from "../../Common/Chatbot";
import TableNoCheckBox from "../../Common/TableNoCheckBox";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ModalCalender from "../../Common/ModalCalender";
import "../../Graphs/graphCSS.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import "react-circular-progressbar/dist/styles.css";
import { styled } from "@mui/material/styles";
import CustomCircularBar from "../../Common/CustomCircularBar";
import useErrorHandler from "../../../utils/errorHandler";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import VoiceChat from "../../Common/VoiceChat";
import TimestampFormatter from "../../Common/TimestampFormatter";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import EnergyGenerated from "../../Common/EnergyGenerated";
import LossesDonutChart from "../../Common/LossesDonutChart";
import DummyGraph from "../../Graphs/DummyGraph";
import TrackerIcon from "../../SVG/TrackerIcon";
import InverterIcon from "../../SVG/InverterIcon";
import AbtIcon from "../../SVG/AbtIcon";
import CustomizedXaxis from "../../Graphs/CustomizedXaxis";
import NewLineTime from "../../Graphs/NewLineTime";
import RadialBar from "../../Graphs/RadialBar";
import OperationalEfficiency from "../../Common/OperationalEfficiency";
const invService = new CosmicInverterService();
const userService = new UserService();

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#F4F4F4 !important",
    border: "1px solid #2F67F8 !important",
    color: "black", // Change text color if needed
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#2F67F8 !important",
  },
});
function ExecutiveDashboard() {
  const [perfRatioStartTime, setPerfRatioStartTime] = useState("");
  const [perfRatioStopTime, setPerfRatioStopTime] = useState("");
  const [pastGenStartTime, setPastGenStartTime] = useState("");
  const [pastGenStopTime, setPastGenStopTime] = useState("");
  const [isStartTimeCUF_AC, setIsStartTimeCUF_AC] = useState("");
  const [isStopTimeCUF_AC, setIsStopTimeCUF_AC] = useState("");
  const [isStartTimeCUF_DC, setIsStartTimeCUF_DC] = useState("");
  const [isStopTimeCUF_DC, setIsStopTimeCUF_DC] = useState("");
  const [isWeatherData, setIsWeatherData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [averagePerformaceRatio, setAveragePerformaceRatio] = useState("");
  const [averageCUF_DC, setAverageCUF_DC] = useState("");
  const [averageCUF_AC, setAverageCUF_AC] = useState("");
  const fetchApiDuration = 2 * 60 * 1000;
  const [loadingState, setLoadingState] = useState({
    perfRatio: false,
    cufDC: false,
    cufAC: false,
    irrPOA: false,
    plantTable: false,
    fieldList: false,
    mfmFieldList: false,
    weatherPara: false,
    plantData: false,
    weatherData: false,
    powerinfo: false,
    invList: false,
    pastData: false,
    deviceStatus: false,
    reportCSV: false,
    forecast: false,
    plantEnergyOne: false,
    trackerList: false,
  });
  const jsonData = {
    title: "Operational Efficiency Metrics",
    energy: "25999",
    subtitle1: "CUF-AC Capacity",
    value1: averageCUF_AC ? Math.floor(averageCUF_AC * 100) / 100 : 0, 
    subtitle2: "CUF-DC Capacity",
    value2: averageCUF_DC ? Math.floor(averageCUF_DC * 100) / 100 : 0,
    subtitle3: "Performance Ratio",
    value3: averagePerformaceRatio ? Math.floor(averagePerformaceRatio * 100) / 100 : 0,
    PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_other_icon.svg",
    colorCode: "#04724D",
    unit:"%"
  };
  
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
  });
  const [isStartTimeActiveIrr, setIsStartTimeActiveIrr] = useState("");
  const [isStopTimeActiveIrr, setIsStopTimeActiveIrr] = useState("");
  const [isStartTimePrediction, setIsStartTimePrediction] = useState("");
  const [isStopTimePrediction, setIsStopTimePrediction] = useState("");
  const [predictedData, setPredictedData] = useState({});
  const [performanceValue, setPerformanceValue] = useState({});
  const [pastGenData, setPastGenData] = useState({});
  const [CUF_DC_Value, setCUF_DC_Value] = useState({});
  const [CUF_AC_Value, setCUF_AC_Value] = useState({});
  const [stringDurationTwo, setStringDurationTwo] = useState("30min");
  const [stringDurationThree, setStringDurationThree] = useState("h");
  const [activePowerIrr, setActivePowerIrr] = useState([]);
  const [plantListsArray, setPlantListsArray] = useState([]);
  const [powerarray, setPowerArray] = useState({});
  const [totalPowerGen, setTotalPowerGen] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [weatherInfoData, setWeatherInfoData] = useState({});
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [isStartTime, setIsStartTime] = useState("");
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const lossesOptions = ["Inverter", "SMB"];
  const [isDefaultLossOption, setIsDefaultLossOption] = useState("Inverter");
  const [isDeviceFields, setIsDeviceFields] = useState("output_power_kW");
  const [isDeviceDurations, setIsDeviceDurations] = useState("hour");
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [openDrawerCal, setOpenDrawerCal] = useState(false);
  const [isStopTime, setIsStopTime] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openVoiceChat, setOpenVoiceChat] = useState(false);
  const [allTrackerDetail, setAllTrackerDetail] = useState({});
  const [plantEnergy, setPlantEnergy] = useState({});

  const [openCalender, setOpenCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newInvArray, setNewInvArray] = useState([]);
  const [isAbtData, setIsAbtData] = useState([]);
  const [presentPower, setPresentPower] = useState(0);
  const [isPanelData, setIsPanelData] = useState([]);
  const [isStartTimeCalender, setIsStartTimeCalender] = useState("");
  const [isStopTimeCalender, setIsStopTimeCalender] = useState("");

  const [showPopup, setShowPopup] = useState(true);
  const [showChatIcons, setShowChatIcons] = useState(false);
  const { isOnlyMobile, isMobile, isMobileAndTab, isDesktopView } = useMobile();
  const { enqueueSnackbar } = useSnackbar();

  let warningDisplayed = false;
  const history = useHistory();
  const deviceDurationValue = ["hour", "minute"];

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(currentDate.getDate()).padStart(2, "0");
  const defaultEndValue = `${year1}-${month1}-${day1}T00:00:00Z`;
  const handleFetchError = useErrorHandler();
  const totalPowerGenPercent = (totalPowerGen / totalCapacity) * 100;

  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  const inverterNames = newInvArray
    .filter(
      (item) =>
        item.device_details !== null && item.device_details.name !== null
    )
    .map((item) => item.device_details.name);
  const filteredInverterArray = newInvArray.filter(
    (item) =>
      item.device_data !== null &&
      item.device_data["output_power_kW"] !== null &&
      item.device_details !== null
  );

  useEffect(() => {
    handleFieldsList();
    handleTrackersList();
  }, []);

  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      await getPlantsData();
      if (id) {
        await Promise.all([
          handleInvertersList(id),
          handlePlantEnergy(id),
          handleWeatherParameters(id),
          handleInverterPowerInfo(id),
          handleDeviceStatus(id),
          handleWeatherInfo(id),
        ]);
      }
    };
    const fetchDataPeriodically = () => {
      const intervalId = setInterval(async () => {
        if (id) {
          await Promise.all([
            handleInvertersList(id),
            handlePlantEnergy(id),
            handleWeatherParameters(id),
            handleInverterPowerInfo(id),
            handleDeviceStatus(id),
            handleWeatherInfo(id),
          ]);
        }
        await getPlantsData();
      }, fetchApiDuration);

      fetchInitialData();

      return () => clearInterval(intervalId);
    };

    fetchDataPeriodically();
  }, [id]);

  useEffect(() => {
    const fetchActivePowerIrrPOA = async () => {
      if (
        id &&
        ((isStartTimeActiveIrr && isStopTimeActiveIrr) || stringDurationTwo)
      ) {
        await handleActivePowerIrrPOA(
          id,
          stringDurationTwo || "30min",
          isStartTimeActiveIrr,
          isStopTimeActiveIrr
        );
      }
    };
    fetchActivePowerIrrPOA();
  }, [id, isStopTimeActiveIrr, stringDurationTwo]);
  useEffect(() => {
    const fetchForecatedGenData = async () => {
      if (
        id &&
        ((isStartTimePrediction && isStopTimePrediction) || stringDurationThree)
      ) {
        await handleForecastedGen(
          id,
          stringDurationThree || "h",
          isStartTimePrediction,
          isStopTimePrediction
        );
      }
    };
    fetchForecatedGenData();
  }, [id, isStopTimePrediction, stringDurationThree]);

  const fetchIntervalData = (
    fetchDataFn,
    id,
    startTime,
    endTime,
    intervalSetter
  ) => {
    const fetchData = async () => {
      await fetchDataFn(id, startTime, endTime);
    };
    fetchData();
    const intervalId = setInterval(fetchData, fetchApiDuration);

    intervalSetter(intervalId);
  };

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handleCUF_AC_Graph,
        id,
        isStartTimeCUF_AC,
        isStopTimeCUF_AC,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, isStopTimeCUF_AC, isStartTimeCUF_AC]);

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handleCUF_DC_Graph,
        id,
        isStartTimeCUF_DC,
        isStopTimeCUF_DC,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, isStopTimeCUF_DC, isStartTimeCUF_DC]);

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handlePerfRatio,
        id,
        perfRatioStartTime,
        perfRatioStopTime,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, perfRatioStartTime, perfRatioStopTime]);
  useEffect(() => {
    if (isStartTimeCalender && isStopTimeCalender) {
      handleDailyCSVReport(id, isStartTimeCalender, isStopTimeCalender);
    }
  }, [isStopTimeCalender, id]);
  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handlePastGenData,
        id,
        pastGenStartTime,
        pastGenStopTime,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, pastGenStartTime, pastGenStopTime]);

  useEffect(() => {
    if (id) {
      if (isStartTime && isStopTime) {
        handlePlantTable(
          id,
          isDeviceFields,
          isDeviceDurations,
          isStartTime,
          isStopTime
        );
      } else {
        const fetchData = () => {
          handlePlantTable(id, isDeviceFields, isDeviceDurations);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isDeviceDurations, isDeviceFields, isStopTime, id]);
  const handleCalenderTimings = () => {
    setOpenCalender(true);
  };

  const weatherInfo = [
    {
      title: "Humidity",
      temp: `${weatherInfoData?.humidity ?? "--"} %`,

      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Vector.svg",
    },
    {
      title: "Wind Speed",
      temp: `${weatherInfoData?.windSpeed ?? "--"} km/h`,
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Wind.svg",
    },
    {
      title: "Air Temp",
      temp: `${weatherInfoData?.airTemperature ?? "--"} °C`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Cloud+Wind.svg",
    },
    {
      title: "Irradiance",
      temp: `${
        isWeatherData[0]?.device_data?.irradiance
          ? isWeatherData[0]?.device_data?.irradiance
          : "--"
      } W/m²`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Solarirradiance.svg",
    },
  ];
  const panelWeatherInfo = [
    {
      title: "Ambient Temp",
      temp: `${
        isPanelData[0]?.device_data?.ambient_temperature_degree_C ?? "--"
      } °C`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/ambient_temp.png",
    },
    {
      title: "Module Temp",
      temp: `${
        isPanelData[0]?.device_data?.module_temperature_degree_C ?? "--"
      } °C`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/module_temp.png",
    },
  ];
  const historyList = [
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
  ];
  const PowerPlant = [
    {
      title: "Exported Power Efficiency",
      energy: "25999",
      subtitle1: "Plant Capacity",
      value1:
        Array.isArray(plantListsArray) && plantListsArray.length > 0
          ? plantListsArray[0]?.plant_specific_details?.["Plant AC Capacity kW"]
            ? `${plantListsArray[0].plant_specific_details["Plant AC Capacity kW"]} kW`
            : "--"
          : "--",

      subtitle2: "ABT Power (kW)",
      value2:
        presentPower != null && !isNaN(presentPower)
          ? presentPower === 0
            ? "0 kW"
            : `${presentPower.toFixed(0)} kW`
          : "--",
      PlantIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_dc_icon.svg",
      colorCode: "#00529A",
      name: "ABT Power",
    },
    {
      title: "Inverter Power Efficiency",
      subtitle1: "Plant Capacity",
      value1:
        Array.isArray(plantListsArray) && plantListsArray.length > 0
          ? plantListsArray[0]?.plant_specific_details?.["Plant AC Capacity kW"]
            ? `${plantListsArray[0].plant_specific_details["Plant AC Capacity kW"]} kW`
            : "--"
          : "--",
      subtitle2: "Inverter Power (kW)",
      value2:
        totalPowerGen != null && !isNaN(totalPowerGen)
          ? totalPowerGen === 0
            ? "0 kW"
            : `${totalPowerGen.toFixed(0)} kW`
          : "--",
      PlantIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_ac_icon.svg",
      colorCode: "#04724D",
      name: "Inverter Power",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(
    Array.from({ length: PowerPlant.length }, () => null)
  );

  const NotificationData = [
    {
      title: "Security alert:",
      disc: "Unauthorized access attempt detected",
    },
    {
      title: "Performance issue detected:",
      disc: "Equipment malfunction reported",
    },
    {
      title: "Weather alert:",
      disc: "Cloudy conditions impacting energy production",
    },
    {
      title: "Scheduled maintenance reminder:",
      disc: "Maintenance required",
    },
    {
      title: "System health alert:",
      disc: "Battery status critical",
    },
    {
      title: "Energy production trend:",
      disc: "Decreasing output observed",
    },
  ];
  const invStatus = [
    {
      title: "Total Running Inverters",
      value:
        deviceStatus.filter(
          (device) => device.type === "inverter" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
              (device) => device.type === "inverter" && device.status === true
            ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "inverter").length > 0
          ? deviceStatus.filter((device) => device.type === "inverter").length
          : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/invStatus.svg",
    },
    {
      title: "Total Running MFMs",
      value:
        deviceStatus.filter(
          (device) => device.type === "mfm" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
              (device) => device.type === "mfm" && device.status === true
            ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "mfm").length > 0
          ? deviceStatus.filter((device) => device.type === "mfm").length
          : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/mfmStatus.svg",
    },
    {
      title: "Total Running ABT" + "\u00A0" + "\u00A0" + "\u00A0" + "\u00A0",

      value:
        deviceStatus.filter(
          (device) => device.type === "abt" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
              (device) => device.type === "abt" && device.status === true
            ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "abt").length > 0
          ? deviceStatus.filter((device) => device.type === "abt").length
          : "0",

      svgIcon: AbtIcon,
      fillColor: "black",
      path1:
        "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z",
    },
    {
      title: "Total Running SMBs" + "\u00A0" + "\u00A0" + "\u00A0" + "\u00A0",
      value: "30",
      total: "30",
      svgIcon: InverterIcon,
      fillColor: "black",
      path1:
        "M20 0H3C1.622 0 0.5 1.122 0.5 2.5V17.5C0.5 18.708 1.36 19.717 2.5 19.95V24H20.5V19.95C21.64 19.718 22.5 18.708 22.5 17.5V2.5C22.5 1.122 21.378 0 20 0ZM3.5 23V20H19.5V23H3.5ZM21.5 17.5C21.5 18.327 20.827 19 20 19H3C2.173 19 1.5 18.327 1.5 17.5V2.5C1.5 1.673 2.173 1 3 1H20C20.827 1 21.5 1.673 21.5 2.5V17.5ZM4.5 12H18.5V4H4.5V12ZM5.5 5H17.5V11H5.5V5ZM6.5 15.5C6.5 16.052 6.052 16.5 5.5 16.5C4.948 16.5 4.5 16.052 4.5 15.5C4.5 14.948 4.948 14.5 5.5 14.5C6.052 14.5 6.5 14.948 6.5 15.5ZM8.5 15H18.5V16H8.5V15Z",
    },
    {
      title:
        "Total Running Trackers" + "\u00A0" + "\u00A0" + "\u00A0" + "\u00A0",
      value: allTrackerDetail?.active_trackers
        ? allTrackerDetail.active_trackers
        : "0",
      total:
        allTrackerDetail?.active_trackers || allTrackerDetail?.inactive_trackers
          ? allTrackerDetail.active_trackers +
            allTrackerDetail.inactive_trackers
          : "0",
      svgIcon: TrackerIcon,
    },
  ];

  const handleTrackersList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, trackerList: true }));
      const res = await invService.getTrackerLists();
      if (res.status === 200) {
        setAllTrackerDetail(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, trackerList: false }));
      handleFetchError(error, "Trackers List");
    }
  };

  const handleChatBot = () => {
    setOpenDrawer(true);
    setOpenVoiceChat(false);
  };
  const handleVoiceOver = () => {
    setOpenVoiceChat(true);
    setOpenDrawer(false);
  };
  const handleDeviceStatus = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: true }));
      const res = await invService.getDeviceSttaus(id);
      if (res.status === 200) {
        setDeviceStatus(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: false }));
      handleFetchError(error, "Device Status");
    }
  };
  const handleDailyCSVReport = async (
    id,
    isStartTimeCalender,
    isStopTimeCalender
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportCSV: true }));

      const res = await invService.getDailyCSVReport(
        id,
        isStartTimeCalender,
        isStopTimeCalender,
        true
      );
      if (res.status === 200) {
        const csvData = res.data;
        downloadCSV(csvData, isStartTimeCalender, isStopTimeCalender);
      }
      setLoadingState((prevState) => ({ ...prevState, reportCSV: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportCSV: false }));
      handleFetchError(error, "report dowload");
    }
  };
  const downloadCSV = (csvData, start, end) => {
    const csvContent = "data:text/csv;charset=utf-8," + csvData;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      start && end
        ? `${
            isStartTimeCalender.split("T")[0] !==
            isStopTimeCalender.split("T")[0]
              ? `${isStartTimeCalender.split("T")[0]}_${
                  isStopTimeCalender.split("T")[0]
                }`
              : isStartTimeCalender.split("T")[0]
          }.csv`
        : `${defaultEndValue.split("T")[0]}.csv`
    );

    document.body.appendChild(link);
    link.click();
  };

  const handlePlantEnergy = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantEnergyOne: true }));
      const res = await invService.getPlantEnergyGen(id);
      if (res.status === 200) {
        setPlantEnergy(res.data);
        setLoadingState((prevState) => ({
          ...prevState,
          plantEnergyOne: false,
        }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantEnergyOne: false }));
      handleFetchError(error, "energy");
    }
  };

  const handlePastGenData = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, pastData: true }));
      const reqData = {};

      if (start_time && end_time) {
        reqData.start_time = decodeURIComponent(start_time);
        reqData.end_time = decodeURIComponent(end_time);
        reqData.graph_type = "energy";
      }
      const res = await invService.getMultiBarData(id, reqData);
      if (res.status === 200) {
        setPastGenData(res.data?.charts?.energy);
      }
      setLoadingState((prevState) => ({ ...prevState, pastData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, pastData: false }));
      handleFetchError(error, "exported energy");
    }
  };
  const handlePerfRatio = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: true }));
      const reqData = {};

      if (start_time && end_time) {
        reqData.start_time = decodeURIComponent(start_time);
        reqData.end_time = decodeURIComponent(end_time);
        reqData.graph_type = "performance_ratio";
      }
      const res = await invService.getMultiBarData(id, reqData);
      if (res.status === 200) {
        setPerformanceValue(res.data?.charts?.performance_ratio);
        setAveragePerformaceRatio(
          res.data?.current_values?.performance_ratio?.actual
        );
      }
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
    } catch (error) {
      setPerformanceValue({});
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      handleFetchError(error, "performance ratio");
    }
  };
  const handleCUF_DC_Graph = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, cufDC: true }));
      const reqData = {};

      if (start_time && end_time) {
        reqData.start_time = decodeURIComponent(start_time);
        reqData.end_time = decodeURIComponent(end_time);
        reqData.graph_type = "cuf_dc";
      }
      const res = await invService.getMultiBarData(id, reqData);
      if (res.status === 200) {
        setCUF_DC_Value(res.data?.charts?.cuf_dc);
        setAverageCUF_DC(res.data?.current_values?.cuf_dc?.actual);
      }
      setLoadingState((prevState) => ({ ...prevState, cufDC: false }));
    } catch (error) {
      setCUF_DC_Value({})
      setLoadingState((prevState) => ({ ...prevState, cufDC: false }));
      handleFetchError(error, "CUF-DC");
    }
  };
  const handleCUF_AC_Graph = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, cufAC: true }));
      const reqData = {};

      if (start_time && end_time) {
        reqData.start_time = decodeURIComponent(start_time);
        reqData.end_time = decodeURIComponent(end_time);
        reqData.graph_type = "cuf_ac";
      }
      const res = await invService.getMultiBarData(id, reqData);
      if (res.status === 200) {
        setCUF_AC_Value(res.data?.charts?.cuf_ac);
        setAverageCUF_AC(res.data?.current_values?.cuf_ac?.actual);
      }
      setLoadingState((prevState) => ({ ...prevState, cufAC: false }));
    } catch (error) {
      setCUF_AC_Value({});
      setLoadingState((prevState) => ({ ...prevState, cufAC: false }));
      handleFetchError(error, "CUF-AC");
    }
  };
  const handleForecastedGen = async (
    id,
    stringDurationThree,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, forecast: true }));
      const res = await invService.getPredictedData(
        id,
        stringDurationThree,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setPredictedData(res.data);
        setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      handleFetchError(error, "Actual Vs Forecasted Generation");
    }
  };

  const handleActivePowerIrrPOA = async (
    id,
    stringDurationTwo,
    isStartTimeActiveIrr,
    isStopTimeActiveIrr
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, irrPOA: true }));
      const res = await invService.getActivePowerIrrPOA(
        id,
        stringDurationTwo,
        isStartTimeActiveIrr,
        isStopTimeActiveIrr
      );
      if (res.status === 200) {
        setActivePowerIrr(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, irrPOA: false }));
    } catch (error) {
      setActivePowerIrr([])
      setLoadingState((prevState) => ({ ...prevState, irrPOA: false }));
      handleFetchError(error, "Power Vs Inverter Vs Irradiance POA");
    }
  };

  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id, "inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        const totalOutputPower = res.data.reduce((total, device) => {
          if (
            device.device_data &&
            device.device_data.output_power_kW !== null
          ) {
            return total + device.device_data.output_power_kW;
          } else {
            return total;
          }
        }, 0);

        const sumCapacity = res.data.reduce((total, inverter) => {
          const capacityKW =
            inverter.device_details?.device_specific_details?.capacity_kW || 0;
          return total + capacityKW;
        }, 0);

        setTotalCapacity(sumCapacity);
        setTotalPowerGen(totalOutputPower);
      }
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };
  const handleInverterPowerInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: true }));
      const res = await invService.getPlantOverview(id);
      if (res.status === 200) {
        setPowerArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleWeatherInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));
      const res = await invService.getWeatherInfo(id);
      if (res.status === 200) {
        const timestamp = res.data.dt * 1000;
        const date = new Date(timestamp);
        const month = date.toLocaleString("default", { month: "long" });
        const dayAbbreviation = date.toLocaleString("en-US", {
          weekday: "long",
        });
        setWeatherInfoData({
          weatherConditions: res.data.weather[0].main,
          weatherUrl: res.data.weather[0].icon,

          temperature: res.data.main.feels_like,
          airTemperature: res.data.main.temp,

          minTemp: res.data.main.temp_min,
          maxTemp: res.data.main.temp_max,
          humidity: res.data.main.humidity,
          windSpeed: res.data.wind.speed,
          day: dayAbbreviation,
          date: date.getDate(),
          month: month,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      handleFetchError(error, "Weather Data");
    }
  };

  const getPlantsData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantData: true }));
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
      handleFetchError(error, "Plants Data");
    }
  };
  const handleWeatherParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherPara: true }));
      const res = await invService.getDeviceLevelData(id, "wms");
      if (res.status === 200) {
        setIsWeatherData(res.data);
      }
      const resAbt = await invService.getDeviceLevelData(id, "abt");
      if (resAbt.status === 200) {
        setIsAbtData(resAbt.data);
        setPresentPower(
          resAbt.data[0]?.device_data !== null
            ? resAbt.data[0]?.device_data?.three_phase_apparent_power_KVA
            : 0
        );
      }
      const resPanel = await invService.getDeviceLevelData(id, "panel");
      if (resPanel.status === 200) {
        setIsPanelData(resPanel.data);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherPara: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherPara: false }));
      handleFetchError(error, "Weather Parameters");
    }
  };
  const handleComponents = async (ele) => {
    if (ele === 0) {
      history.push("/iot-home/dev-monitoring/inv-dashboard");
    } else if (ele === 1) {
      history.push("/iot-home/dev-monitoring/mfm");
    } else if (ele === 2) {
      history.push("/iot-home/dev-monitoring/abt");
    } else if (ele === 3) {
      history.push("iot-home/dev-monitoring/smb");
    } else if (ele === 4) {
      history.push("iot-home/dev-monitoring/trackers");
    }
  };
  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDeviceFields = (device) => {
    setIsDeviceFields(device.value);
    handlePlantTable(id, device.value, isDeviceDurations);
  };
  const handleChangeDeviceDuration = (device) => {
    setIsDeviceDurations(device.value.toLowerCase());
    handlePlantTable(id, isDeviceFields, device.value.toLowerCase());
  };
  const handleTimings = () => {
    setOpenDrawerCal(true);
  };
  const handleFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter");
      if (res.status === 200) {
        let modifiedData = [...res.data];
        const fieldMappings = {
          output_power_kW: "Output Power",
          output_current_A: "Output Current",
          energy_counter_MWh: "Energy Counter",
          net_frequency_Hz: "Net Frequency",
          net_AC_voltage_V: "Net AC Voltage",
          DC_voltage_DCV: "DC Voltage",
          average_cosphii_percent: "Average Cosphi",
        };
        const dropdownOptions = modifiedData.map((field) => {
          return {
            label: fieldMappings[field],
            value: field,
          };
        });
        setDeviceFieldValue(dropdownOptions);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));

      handleFetchError(error, "device fields");
    }
  };

  const handlePlantTable = async (
    id,

    fieldVal,
    duration,
    isStartTime,
    isStopTime
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantTable: true }));
      const res = await invService.getPlantTable(
        id,
        fieldVal,
        duration,
        isStartTime,
        isStopTime
      );
      if (res.status === 200) {
        const modifiedData = res.data.map((item) => ({
          ...item,
          timestamp_edge: item.timestamp_edge
            .replace(/T|\.|Z/g, " ")
            .slice(0, 16),
        }));
        setColumns(modifiedData);
      }
      setLoadingState((prevState) => ({ ...prevState, plantTable: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantTable: false }));
      handleFetchError(error, "plant table");
    }
  };

  const handleChatbotClick = () => {
    setShowPopup(false);
    setShowChatIcons((prev) => !prev);
  };
  const handleChangeLosses = (loss) => {
    setIsDefaultLossOption(loss.value);
  };
  return (
    <div className="home-container">
      <Grid
        container
        className="weatherDash-container"
        sx={{ zIndex: "0", pointerEvents: openDrawer ? "none" : "auto" }}
      >
        <Grid
          item
          lg={9.6}
          xs={12}
          sm={12}
          md={isMobile ? 11.9 : 9.1}
          className="scrolling-grid-exc"
        >
          <Grid container className="grid-container">
            <Grid
              item
              lg={4}
              xs={12}
              sm={12}
              md={12}
              sx={{
                position: "relative",
              }}
              className="total_power_container"
            >
              <Paper className="inv_total_paper" id="timeGap">
                <div className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
                  {plantListsArray[0]?.name ? plantListsArray[0]?.name : "--"}
                </div>
                <div className="flex-start-column-two" id="timeGap">
                  {/* <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                      AC Power (kW)
                      </p>
                      <p className="Poppins_18px_rem">
                     { isAbtData.length > 0 &&
                      isAbtData[0]?.device_data
                        ?.three_phase_apparent_power_KVA != null
                        ? `${isAbtData[0]?.device_data?.three_phase_apparent_power_KVA.toFixed(
                            2
                          )} kW`
                        : 0
                    }

                      </p>
                    </div>
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                      DC Power (kW)
                      </p>
                      <p className="Poppins_18px_rem">
                       {
                      totalPowerGen === 0
                        ? "0.00 kW"
                        : totalPowerGen
                        ? `${totalPowerGen.toFixed(2)} kW`
                        : "--"
                    }
                      </p>
                    </div>
                  </div> */}
                  <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p
                        className="Poppins_14px_rem"
                        style={{ fontWeight: "400" }}
                      >
                        Last Updated on
                      </p>

                      <p className="Poppins_18px_rem">
                        {plantListsArray?.[0]?.last_active ? (
                          <TimestampFormatter
                            dateString={plantListsArray[0].last_active}
                          />
                        ) : (
                          "--"
                        )}
                      </p>
                    </div>

                    <div></div>
                  </div>
                  <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                        Power Exported to Grid At Present
                      </p>
                      <p className="raleway_32px_rem">
                        {presentPower != null && !isNaN(presentPower)
                          ? `${presentPower.toFixed(2)} `
                          : "--"}
                        <span className="Poppins_18px_rem">kW</span>
                      </p>
                    </div>
                    <div></div>
                  </div>

                  <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                        Total Yield Energy Till Now
                      </p>
                      <p className="raleway_32px_rem">
                        {powerarray.total_yield_energy_kWh !== null &&
                        powerarray.total_yield_energy_kWh !== undefined
                          ? `${powerarray.total_yield_energy_kWh.toFixed(2)} `
                          : "--"}
                        <span className="Poppins_18px_rem">
                          {powerarray.unit && powerarray.unit}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                  <p
                    className="Poppins_14px_rem"
                    style={{
                      color: "#5E6064",
                      fontWeight: "500",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    Data refreshed every 2 mins
                  </p>
                </div>
              </Paper>
              {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.invList} />
              )}
              {loadingState.plantData && (
                <SimpleBackdrop open={loadingState.plantData} />
              )}
              {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.powerinfo} />
              )}
            </Grid>

            {!isMobile && (
              <Grid
                item
                order={{ lg: 2 }}
                lg={3.3}
                xs={12}
                sm={12}
                md={12}
                className="power-plant-grid-exc imp_notification_block"
              >
                <Paper className="power-plant-paper-exc">
                  <div className="center_div">
                    <div className="icon-exc-div">
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/plant_other_icon.svg"
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                      <p className="Poppins_18px_rem">Start & Stop Time</p>
                    </div>
                  </div>

                  <div onClick={(event) => handlePopoverOpen(5, event)}>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                      alt="plant"
                      style={{ width: "1rem", cursor: "pointer" }}
                    />
                  </div>
                </Paper>
                <Paper
                  className="powerplant-sec-paper"
                  sx={{ flexDirection: { lg: "column !important" } }}
                >
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid #FDA737`,
                    }}
                  >
                    <p className="raleway_14px_rem">Power Generation Start</p>
                    <p className="Poppins_18px_rem">
                      {plantListsArray[0]?.sunrise_time
                        ? `${plantListsArray[0]?.sunrise_time.substring(
                            11,
                            16
                          )} am`
                        : "--"}
                    </p>
                  </div>
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid #FDA737`,
                    }}
                  >
                    <p className="raleway_14px_rem">Power Generation Stop</p>
                    <p className="Poppins_18px_rem">
                      {plantListsArray[0]?.sunset_time
                        ? `${plantListsArray[0]?.sunset_time.substring(
                            11,
                            16
                          )} pm`
                        : "--"}
                    </p>
                  </div>
                </Paper>
                <Popover
                  open={Boolean(anchorEl[5])}
                  anchorEl={anchorEl[5]}
                  onClose={() => handlePopoverClose(5)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverPaper-exc" }}
                >
                  <div className="center_div">
                    <p className="raleway_14px_rem">
                      Shows daily power generation start and stop times, aligned
                      with sunrise and sunset.
                    </p>
                  </div>
                </Popover>
                {loadingState.plantData && (
                  <SimpleBackdrop open={loadingState.plantData} />
                )}
              </Grid>
            )}

            <Grid
              item
              order={{ xs: 4 }}
              lg={4.5}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper className="exc-graph-paper" sx={{ height: "100%" }}>
              {/* <RadialBar
                  isValueData={[
                    {
                      type: "CUF-AC Capacity",
                      value: averageCUF_AC
                        ? ((averageCUF_AC * 100) / 100).toFixed(2)
                        : "--",
                    },
                    {
                      type: "CUF-DC Capacity",
                      value: averageCUF_DC
                        ? ((averageCUF_DC * 100) / 100).toFixed(2)
                        : "--",
                    },
                    {
                      type: "Performance Ratio",
                      value: averagePerformaceRatio
                        ? ((averagePerformaceRatio * 100) / 100).toFixed(2)
                        : "--",
                    },
                  ]}
                  titleText="Operational Efficiency Metrics"
                  indicateUnit="%"
                /> */}
              <OperationalEfficiency data={jsonData} />
              </Paper>
            </Grid>

            <Grid
              item
              order={{ xs: 4 }}
              lg={4.9}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <EnergyGenerated isValueData={plantEnergy} />

              {loadingState.plantEnergyOne && (
                <SimpleBackdrop open={loadingState.plantEnergyOne} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 4 }}
              lg={7}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper
                className="justify_space_betwn"
                id="donut_paper"
                sx={{ width: "100%", boxShadow: "none !important" }}
              >
                <div className="space-betwn_div">
                  <p className="Poppins_18px_rem">Daily Energy Loss Analysis</p>
                </div>
                <div
                  style={{
                    width: "auto",
                    minWidth: "8rem",
                    cursor: "pointer",
                  }}
                  className="min_width_11rem"
                  id="exc-linegraph"
                >
                  <Dropdown
                    className="recommendations-text"
                    options={lossesOptions}
                    onChange={handleChangeLosses}
                    value={isDefaultLossOption}
                  />
                </div>
              </Paper>
              {isDefaultLossOption === "Inverter" ? (
                <Paper className="energy-paper center_div_nogap">
                  <LossesDonutChart isValueData={plantEnergy?.energy_loss} />
                </Paper>
              ) : (
                <Paper className="exc-graph-paper">
                  <div className="border_div">
                    <img
                      src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/simplification.svg"
                      alt="pin"
                      style={{ width: "2rem" }}
                    />
                    <div className="border_div">
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontWeight: "700" }}
                      >
                        15
                      </p>
                      <p
                        className="Poppins_14px_rem"
                        style={{ textAlign: "center" }}
                      >
                        No. of SMBs Monitored
                      </p>
                      <p
                        className="Poppins_18px_rem"
                        style={{ fontWeight: "700" }}
                      >
                        16
                      </p>
                      <p
                        className="Poppins_14px_rem"
                        style={{ textAlign: "center" }}
                      >
                        Strings Per SMB
                      </p>
                    </div>
                  </div>
                  <DummyGraph />
                </Paper>
              )}

              {loadingState.plantEnergyOne && (
                <SimpleBackdrop open={loadingState.plantEnergyOne} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 4 }}
              lg={4}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper className="energy-paper" id="donut_paper">
                <p className="Poppins_18px_rem">Generated Power</p>
              </Paper>
              <Paper className="energy-paper center_div_nogap">
                <CustomCircularBar
                  centerText={
                    totalPowerGen
                      ? totalPowerGen >= 1000
                        ? (totalPowerGen / 1000).toFixed(2)
                        : totalPowerGen.toFixed(2)
                      : "0"
                  }
                  values={newInvArray
                    .filter((item) => item.device_details !== null)
                    .map((device, index) => {
                      const outputPower =
                        device.device_data !== null
                          ? device.device_data?.output_power_kW
                          : 0;

                      // if (demoPlant && outputPower === 0) {
                      //   // Assign specific values based on inverter index
                      //   switch (index) {
                      //     case 0:
                      //       return 650;
                      //     case 1:
                      //       return 677;
                      //     case 2:
                      //       return 689;
                      //     case 3:
                      //       return null;
                      //     default:
                      //       return 0;
                      //   }
                      // }

                      // Return the actual `output_power_kW` if it's not `0`
                      return outputPower || 0;
                    })}
                  nameData={inverterNames}
                  unit="kW"
                  unitData={
                    totalPowerGen ? (totalPowerGen >= 1000 ? "MW" : "kW") : "kW"
                  }
                />
              </Paper>

              {loadingState.invList && (
                <SimpleBackdrop open={loadingState.invList} />
              )}
            </Grid>

            {PowerPlant.map((ele, index) => (
              <Grid
                key={index}
                item
                order={{
                  xs: index === 0 ? 10 : index === 1 ? 11 : index === 2 ? 5 : 7,
                  lg: index === 0 ? 5 : index === 1 ? 6 : index === 2 ? 7 : 7,
                }}
                lg={3.9}
                xs={12}
                sm={12}
                md={5.9}
                sx={{
                  justifyContent: "space-between",
                }}
                className="power-plant-grid-exc"
              >
                <Paper className="power-plant-paper-exc">
                  <div className="center_div">
                    <div className="icon-exc-div">
                      <img
                        src={ele.PlantIcon}
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                      <p className="Poppins_18px_rem">{ele.title}</p>
                    </div>
                  </div>
                  {/* <div onClick={(event) => handlePopoverOpen(index, event)}>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                      alt="plant"
                      style={{ width: "1rem", cursor: "pointer" }}
                    />
                  </div> */}
                </Paper>
                <Paper
                  className="powerplant-sec-paper"
                  sx={{
                    flexDirection:
                      index === 1 || index === 0 ? "column" : "row",
                  }}
                >
                  <>
                    <CustomTooltip
                      title={
                        <React.Fragment>
                          <div className="center_div">
                            <p
                              className="raleway_14px_rem"
                              style={{ color: "#2F67F8" }}
                            >
                              {ele.subtitle1}:
                            </p>
                            <p className="Poppins_18px_rem">
                              {ele.value1 ? ele.value1 : "--"}
                            </p>
                          </div>
                          <div className="center_div">
                            <p
                              className="raleway_14px_rem"
                              style={{ color: "#2F67F8" }}
                            >
                              {ele.subtitle2}:
                            </p>

                            <p className="Poppins_18px_rem">
                              {ele.value2 ? ele.value2 : "--"}
                            </p>
                          </div>
                        </React.Fragment>
                      }
                      arrow
                    >
                      <div style={{ cursor: "pointer" }} id="circularBar">
                        <CircularProgressbarWithChildren
                          strokeWidth={15}
                          value={
                            ele.value1 &&
                            ele.value2 &&
                            parseFloat(ele.value1) > 0
                              ? (
                                  (parseFloat(ele.value2) /
                                    parseFloat(ele.value1)) *
                                  100
                                ).toFixed(2)
                              : 0
                          }
                        >
                          <div className="smb_value_container center_div_nogap">
                            <p className="Poppins_12px_rem">Efficiency</p>
                            <p className="Poppins_16px_rem">
                              {ele.value1 &&
                              ele.value2 &&
                              parseFloat(ele.value1) > 0
                                ? `${(
                                    (parseFloat(ele.value2) /
                                      parseFloat(ele.value1)) *
                                    100
                                  ).toFixed(2)}%`
                                : "--"}
                            </p>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </CustomTooltip>
                    <div className="center_div">
                      <p className="Poppins_16px_400">
                        <span style={{ fontWeight: "600" }}>{ele.name}: </span>
                        {ele.value1 && ele.value2 && parseFloat(ele.value1) > 0
                          ? `${parseFloat(ele.value2).toFixed(0)}/${parseFloat(
                              ele.value1
                            ).toFixed(0)} kW`
                          : "--"}
                      </p>
                    </div>
                  </>
                </Paper>
                <Popover
                  key={index}
                  open={Boolean(anchorEl[index])}
                  anchorEl={anchorEl[index]}
                  onClose={() => handlePopoverClose(index)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverPaper-exc" }}
                >
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                </Popover>
                {loadingState.plantData && (
                  <SimpleBackdrop open={loadingState.plantData} />
                )}
              </Grid>
            ))}
            <Grid
              item
              order={{ xs: 14, lg: 9 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph1 ? "fixed" : "relative",
                top: fullScreenStates.graph1 ? "9vh" : "auto",
                width: fullScreenStates.graph1
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph1 ? "92vh" : "auto",
                margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph1 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="exc-graph-paper"
                sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
              >
                <BarPlotForIOT
                  isValueData={pastGenData}
                  StartTimer={setPastGenStartTime}
                  StopTimer={setPastGenStopTime}
                  openDrawerValue={setDrawerOpen}
                  titleText="Exported Energy"
                  tooltipName="Exported Energy"
                  indicateUnit="kWh"
                  selectedDateRangeProp={[
                    {
                      startDate: pastGenStartTime
                        ? pastGenStartTime
                        : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                      endDate: pastGenStopTime ? pastGenStopTime : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isFullScreen={fullScreenStates.graph1}
                  toggleFullScreen={() => toggleFullScreen("graph1")}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  showTotal={true}
                  loadState={loadingState.pastData}
                />
              </Paper>
              {loadingState.pastData && (
                <SimpleBackdrop open={loadingState.pastData} />
              )}
            </Grid>
            <Grid
              className="total_power_container"
              item
              order={{ xs: 12, lg: 10 }}
              lg={12}
              xs={12}
              md={12}
              sx={{
                position: fullScreenStates.graph3 ? "fixed" : "relative",
                top: fullScreenStates.graph3 ? "9vh" : "auto",
                width: fullScreenStates.graph3
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph3 ? "92vh" : "auto",
                margin: fullScreenStates.graph3 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph3 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="forecasted_paper"
                sx={{ height: fullScreenStates.graph3 ? "100%" : "auto" }}
              >
                <CustomizedXaxis
                  calenderClick="on"
                  isValueData={predictedData}
                  openDrawerValue={setDrawerOpen}
                  StartTimer={setIsStartTimePrediction}
                  StopTimer={setIsStopTimePrediction}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimePrediction
                        ? isStartTimePrediction
                        : new Date(
                            new Date().getTime() - 3 * 24 * 60 * 60 * 1000
                          ),
                      endDate: isStopTimePrediction
                        ? isStopTimePrediction
                        : new Date(
                            new Date().getTime() + 2 * 24 * 60 * 60 * 1000
                          ),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isMobileAndTab={isMobileAndTab}
                  isFullScreen={fullScreenStates.graph3}
                  toggleFullScreen={() => toggleFullScreen("graph3")}
                  isDesktopView={isDesktopView}
                  stringDurationFun={setStringDurationThree}
                  isFilter={true}
                  loadState={loadingState.forecast}
                />
              </Paper>
              {loadingState.forecast && (
                <SimpleBackdrop open={loadingState.forecast} />
              )}
            </Grid>

            {/* <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="notification-grid"
            >
              <Paper className="notification-paper-one">
                <div style={{ padding: "1rem" }}>
                  <p className="Poppins_24px_black" >
                    Important Notifications
                  </p>
                </div>
                <Divider
                  sx={{ mt: 1 }}
                  style={{ border: "0.0625rem solid #D9D9D9" }}
                />
              </Paper>
              {NotificationData.map((ele, index) => (
              <Paper
                className="notification-paper"
                // key={index}
                sx={
                  {
                    // borderBottom:
                    //   index === NotificationData.length - 1
                    //     ? "none"
                    //     : "0.125rem solid #D9D9D9",
                  }
                }
              >
                <p className="Poppins_18px_rem">No Notification yet</p>
                <div
                    style={{
                      padding: "1rem 1.5rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div>
              </Paper>
               ))} 
            </Grid> */}
            <Grid
              item
              order={{ xs: 3, lg: 11 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="energy-grid"
            >
              <Grid
                container
                className="inv-count-exc"
                sx={{ justifyContent: "center !important" }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  className="notification-paper-one"
                >
                  <Paper className="notification-paper-one ">
                    <p className="Poppins_18px_rem">Components Stats</p>
                  </Paper>
                </Grid>

                {invStatus.map((ele, index) => (
                  <Grid
                    key={index}
                    item
                    lg={3.7}
                    xs={12}
                    sm={3.5}
                    md={3.7}
                    className="component-grid"
                  >
                    <Paper
                      onClick={() => handleComponents(index)}
                      className="component-paper"
                    >
                      <div>
                        {ele.iconInv ? (
                          <img
                            src={ele.iconInv}
                            alt="invStatus"
                            style={{ width: "3.125rem", height: "3.125rem" }}
                          />
                        ) : (
                          <ele.svgIcon
                            width="3.125rem"
                            fillColor={ele.fillColor}
                            height="3.125rem"
                            path1={ele.path1}
                          />
                        )}
                      </div>
                      <div className="flex-start-column-two">
                        <p className="raleway_14px_blue">{ele.title}</p>
                        <p
                          className="raleway_32px_rem"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {ele.value}/
                          <span className="Poppins_24px_black">
                            {ele.total}
                          </span>
                        </p>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              order={{ xs: 15, lg: 12 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{ position: "relative" }}
            >
              <Paper className="plant-table_paper">
                <div className="space-btn-center flex_colmn">
                  <p className="Poppins_18px_rem">Plant Performance Overview</p>
                  <div className="flex-start_gap">
                    <div
                      style={{
                        width: "auto",
                        minWidth: "1.6vw",
                        cursor: "pointer",
                      }}
                      className="min_width_11rem"
                      id="exc-linegraph"
                    >
                      <Dropdown
                        className="recommendations-text"
                        options={deviceFieldValue}
                        onChange={handleChangeDeviceFields}
                        value={isDeviceFields}
                      />
                    </div>
                    <div
                      style={{
                        width: "auto",
                        minWidth: "1.6vw",
                        cursor: "pointer",
                      }}
                      className="min_width_4rem"
                      id="exc-linegraph"
                    >
                      <Dropdown
                        className="recommendations-text"
                        options={deviceDurationValue.map((field) =>
                          field
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())
                        )}
                        onChange={handleChangeDeviceDuration}
                        value={isDeviceDurations
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                        placeholder="Select an option"
                      />
                    </div>
                    <Tooltip title="Calendar" arrow>
                      <CalendarMonthRoundedIcon
                        onClick={handleTimings}
                        className="icon-css"
                      />
                    </Tooltip>
                  </div>
                </div>
                <TableNoCheckBox
                  columns={columns}
                  data={columns}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  order={order}
                  orderBy={orderBy}
                  handlePageChange={handleChangePage}
                  handleRowsPerPageChange={handleChangeRowsPerPage}
                  handleRowClick=""
                  searchQuery={searchQuery}
                  handleSearchChange={handleSearchChange}
                  serchText="Search Date"
                  titleText=""
                  plantTable={true}
                />
                {openDrawerCal && (
                  <ModalCalender
                    openDrawer={openDrawerCal}
                    setOpenDrawer={setOpenDrawerCal}
                    handleStopDateChange={setIsStopTime}
                    handleStartDateChange={setIsStartTime}
                    singledaySelect={false}
                    selectedDateRangeVal={[
                      {
                        startDate: isStartTime ? isStartTime : new Date(),
                        endDate: isStopTime ? isStopTime : new Date(),
                        key: "selection",
                      },
                    ]}
                  />
                )}
              </Paper>
              {loadingState.plantTable && (
                <SimpleBackdrop open={loadingState.plantTable} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 13, lg: 13 }}
              lg={12}
              xs={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph4 ? "fixed" : "relative",
                top: fullScreenStates.graph4 ? "9vh" : "auto",
                width: fullScreenStates.graph4
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph4 ? "92vh" : "auto",
                margin: fullScreenStates.graph4 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph4 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="exc-graph-paper"
                sx={{ height: fullScreenStates.graph4 ? "100%" : "auto" }}
              >
                <NewLineTime
                  isValueData={activePowerIrr}
                  StartTimer={setIsStartTimeActiveIrr}
                  StopTimer={setIsStopTimeActiveIrr}
                  openDrawerValue={setDrawerOpen}
                  indicateUnit=""
                  titleText="Inverters Vs Export Energy Vs Irradiance"
                  tooltipName=""
                  stringDurationFun={setStringDurationTwo}
                  isFilter={true}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeActiveIrr
                        ? isStartTimeActiveIrr
                        : new Date(),
                      endDate: isStopTimeActiveIrr
                        ? isStopTimeActiveIrr
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  separateYaxis={true}
                  isFullScreen={fullScreenStates.graph4}
                  toggleFullScreen={() => toggleFullScreen("graph4")}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  loadState={loadingState.irrPOA}
                />
              </Paper>
              {loadingState.irrPOA && (
                <SimpleBackdrop open={loadingState.irrPOA} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 7, lg: 16 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph2 ? "fixed" : "relative",
                top: fullScreenStates.graph2 ? "9vh" : "auto",
                width: fullScreenStates.graph2
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph2 ? "92vh" : "auto",
                margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph2 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="exc-graph-paper"
                sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}
              >
                <BarPlotForIOT
                  isValueData={performanceValue}
                  StartTimer={setPerfRatioStartTime}
                  StopTimer={setPerfRatioStopTime}
                  openDrawerValue={setDrawerOpen}
                  titleText="Performance Ratio (%)"
                  indicateUnit="%"
                  selectedDateRangeProp={[
                    {
                      startDate: perfRatioStartTime
                        ? perfRatioStartTime
                        : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                      endDate: perfRatioStopTime
                        ? perfRatioStopTime
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isFullScreen={fullScreenStates.graph2}
                  toggleFullScreen={() => toggleFullScreen("graph2")}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  loadState={loadingState.perfRatio}
                />
              </Paper>
              {loadingState.perfRatio && (
                <SimpleBackdrop open={loadingState.perfRatio} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 7, lg: 16 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph5 ? "fixed" : "relative",
                top: fullScreenStates.graph5 ? "9vh" : "auto",
                width: fullScreenStates.graph5
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph5 ? "92vh" : "auto",
                margin: fullScreenStates.graph5 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph5 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="exc-graph-paper"
                sx={{ height: fullScreenStates.graph5 ? "100%" : "auto" }}
              >
                <BarPlotForIOT
                  isValueData={CUF_DC_Value}
                  StartTimer={setIsStartTimeCUF_DC}
                  StopTimer={setIsStopTimeCUF_DC}
                  openDrawerValue={setDrawerOpen}
                  titleText="CUF DC (%)"
                  indicateUnit="%"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeCUF_DC
                        ? isStartTimeCUF_DC
                        : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                      endDate: isStopTimeCUF_DC ? isStopTimeCUF_DC : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isFullScreen={fullScreenStates.graph1}
                  toggleFullScreen={() => toggleFullScreen("graph5")}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  loadState={loadingState.cufDC}
                />
              </Paper>
              {loadingState.cufDC && (
                <SimpleBackdrop open={loadingState.cufDC} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 7, lg: 16 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph6 ? "fixed" : "relative",
                top: fullScreenStates.graph6 ? "9vh" : "auto",
                width: fullScreenStates.graph6
                  ? "-webkit-fill-available !important"
                  : "auto",
                height: fullScreenStates.graph6 ? "92vh" : "auto",
                margin: fullScreenStates.graph6 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph6 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper
                className="exc-graph-paper"
                sx={{ height: fullScreenStates.graph6 ? "100%" : "auto" }}
              >
                <BarPlotForIOT
                  isValueData={CUF_AC_Value}
                  StartTimer={setIsStartTimeCUF_AC}
                  StopTimer={setIsStopTimeCUF_AC}
                  openDrawerValue={setDrawerOpen}
                  titleText="CUF AC (%)"
                  indicateUnit="%"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeCUF_AC
                        ? isStartTimeCUF_AC
                        : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                      endDate: isStopTimeCUF_AC ? isStopTimeCUF_AC : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isFullScreen={fullScreenStates.graph6}
                  toggleFullScreen={() => toggleFullScreen("graph6")}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  loadState={loadingState.cufAC}
                />
              </Paper>
              {loadingState.cufAC && (
                <SimpleBackdrop open={loadingState.cufAC} />
              )}
            </Grid>
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid
            item
            lg={2.3}
            xs={12}
            sm={12}
            md={2.8}
            // className="exc-chatbot-grid"
            className="weather_grid_weather"
          >
            <Paper className="notification-paper-one gap-grid">
              {!isMobile && (
                <div className="exc-chatbot-div">
                  <div className="exc-chatbot-div-sub" onClick={handleChatBot}>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot-icon.svg"
                      alt="chatbot"
                      className="chatbot-icon"
                    />{" "}
                    <p
                      className="Poppins_12px_rem"
                      style={{ color: "#2F67F8", fontWeight: "600" }}
                    >
                      CosmicGPT
                    </p>
                  </div>
                  <div
                    className="exc-chatbot-div-sub"
                    onClick={handleVoiceOver}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/microphone-svgrepo-com.svg"
                      alt="chatbot"
                      id="chat-mic"
                      className="chatbot-icon"
                    />{" "}
                    <p
                      className="Poppins_12px_rem"
                      style={{ color: "#2F67F8", fontWeight: "600" }}
                    >
                      VoiceOver
                    </p>
                  </div>
                </div>
              )}
              <div>
                {!isMobile && (
                  <Button
                    className="reportDownload"
                    type="submit"
                    variant="contained"
                  >
                    <div onClick={() => handleDailyCSVReport(id)}>
                      <p
                        className="Poppins_16px_400"
                        style={{ color: "white" }}
                      >
                        Download Daily Report
                      </p>
                    </div>
                    <IconButton
                      edge="end"
                      style={{
                        backgroundColor: "transparent !important",
                      }}
                    >
                      <CalendarMonthRoundedIcon
                        onClick={handleCalenderTimings}
                        sx={{ cursor: "pointer", color: "white" }}
                      />
                    </IconButton>
                  </Button>
                )}
                {openCalender && (
                  <ModalCalender
                    openDrawer={openCalender}
                    setOpenDrawer={setOpenCalender}
                    handleStopDateChange={setIsStopTimeCalender}
                    handleStartDateChange={setIsStartTimeCalender}
                    singledaySelect={false}
                    selectedDateRangeVal={[
                      {
                        startDate: isStartTimeCalender
                          ? isStartTimeCalender
                          : new Date(),
                        endDate: isStopTimeCalender
                          ? isStopTimeCalender
                          : new Date(),
                        key: "selection",
                      },
                    ]}
                  />
                )}
                {loadingState.reportCSV && (
                  <SimpleBackdrop open={loadingState.reportCSV} />
                )}
              </div>
              {!isMobile && (
                <WeatherBoard
                  weatherData={weatherInfo}
                  panelWeatherData={panelWeatherInfo}
                  weatherParam={weatherInfoData}
                  weaHeight="72vh"
                />
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <>
          {showPopup && (
            <div className="popup_text">
              <p className="Poppins_14px_rem" style={{ fontWeight: "400" }}>
                We’re Online !
              </p>
              <p className="Poppins_12px_rem">
                Hello, how may I assist you today?
              </p>
            </div>
          )}
          <div className="mobile_chatbot" onClick={handleChatbotClick}>
            <img
              alt="chatbot_icon"
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot_icon.svg"
              style={{ width: "4rem" }}
            />
          </div>
        </>
      )}

      {isMobile && showChatIcons && (
        <div className="icons_wrapper">
          <div
            className="show_chaticon"
            style={{ bottom: "1rem", right: "5rem" }}
            onClick={handleChatBot}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot-icon.svg"
              alt="chatbot"
              className="chatbot-icon"
            />{" "}
            <p
              className="Poppins_12px_rem"
              style={{ color: "#2F67F8", fontWeight: "600" }}
            >
              CosmicGPT
            </p>
          </div>
          <div
            className="show_chaticon"
            style={{ bottom: "5rem", right: "2rem" }}
            onClick={handleVoiceOver}
          >
            <img
              src="https://s3.amazonaws.com/smarttrak.co/images/microphone-svgrepo-com.svg"
              alt="chatbot"
              id="chat-mic"
              className="chatbot-icon"
            />{" "}
            <p
              className="Poppins_12px_rem"
              style={{ color: "#2F67F8", fontWeight: "600" }}
            >
              VoiceOver
            </p>
          </div>
        </div>
      )}
      {openDrawer && (
        <Grid container>
          <Grid item lg={10} xs={12} sm={12} md={11}>
            <Paper className="chatbot_Paper">
              <Chatbot handleClose={setOpenDrawer} historyList={historyList} />
            </Paper>
          </Grid>
        </Grid>
      )}
      {openVoiceChat && (
        <Grid container>
          <Grid item lg={10} xs={12} sm={12} md={11}>
            <Paper className="chatbot_Paper" id="chatbot_Paper_voice">
              <VoiceChat
                handleClose={setOpenVoiceChat}
                historyList={historyList}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default ExecutiveDashboard;
