import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";

const MonthRangeSelection = (props) => {
  const { onDayCountChange, onMonthCountChange, isStartTimeChange, isStopTimeChange } = props;
  const [value, setValue] = useState([]);

  const getSelectedMonthCount = () => {
    if (value.length === 2) {
      const startDate = new Date(value[0]);
      let endDate = new Date(value[1]);
      endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
      isStartTimeChange(startDate)
      isStopTimeChange(endDate)

      const totalMonths =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth()) +
        1;

      return totalMonths;
    }
    return 0;
  };
  const getTotalDaysInRange = () => {
    if (value.length === 2) {
      const startDate = new Date(value[0]);
      let endDate = new Date(value[1]);
      endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0); // Last day of end month
  
      let totalDays = 0;
      let current = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // First day of start month
  
      while (current <= endDate) {
        totalDays += new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate(); // Get days in month
        current.setMonth(current.getMonth() + 1); // Move to next month
      }
  
      return totalDays;
    }
    return 0;
  };
  
  useEffect(() => {
    const count = getSelectedMonthCount();
    const totalDays = getTotalDaysInRange();
    onDayCountChange && onDayCountChange(totalDays);
    onMonthCountChange(count);
  }, [value]);

  return (
    <DatePicker
      value={value}
      onChange={setValue}
      onlyMonthPicker
      range
      format="MMMM YYYY"
      placeholder={
       "Select Month Range"
      }
      style={{ width: "100%", textAlign: "center", height:"34px" }} />
  );
};

export default MonthRangeSelection;