import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { Backdrop, Modal, Fade } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";

import ImgBarchart from "../../Graphs/ImgBarchart";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#FF4D50",
    color: "white",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function Bioreactor() {
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph1: false,
    graph5: false,
    graph6: false,
  });
  const [highlightIndexes, setHighlightIndexes] = useState([0, 1, 2]);

  const [openDrawer, setOpenDrawer] = useState(true);
  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };

  const param = [
    {
      name: "Temperature",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/bioone.svg",
      value: "40.2",
      unit: "°C",
    },
    {
      name: "pH Levels",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/biotwo.svg",
      value: "8.5",
      unit: "",
    },
    {
      name: "Dissolved Oxygen",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/biothree.svg",
      value: "68",
      unit: "%",
    },
    {
      name: "Cell Density",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/biofour.svg",
      value: "1",
      unit: "g/L",
    },
    {
      name: "Glucose Concentration",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/biofive.svg",
      value: "3.4",
      unit: "g/L",
    },
    {
      name: "Metabolite Levels",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/biosix.svg",
      value: "3",
      unit: "g/L",
    },
    {
      name: "Product Titer",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/bioseven.svg",
      value: "1.4",
      unit: "g/L",
    },
    {
      name: "Pressure",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/bioeight.svg",
      value: "2",
      unit: "Bar",
    },
    {
      name: "Agitation Rate",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/bionine.svg",
      value: "100",
      unit: "RPM",
    },
    {
      name: "Lactate Concentration",
      info: "https://s3.us-east-1.amazonaws.com/smarttrak.co/new_landing_images/bioten.svg",
      value: "2.5",
      unit: "g/L",
    },
  ];
  function getCurrentTimeInUSA() {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 3); // Subtract 3 minutes

    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/New_York",
    };

    const formatter = new Intl.DateTimeFormat("en-GB", options);
    let formattedDate = formatter.format(now);

    formattedDate = formattedDate.replace(/(am|pm)/, (match) =>
      match.toUpperCase()
    );

    return formattedDate;
  }

  console.log(getCurrentTimeInUSA());

  const notifications = [
    {
      title: "Temperature",
      data: "Temperature spike detected at 40.2°C Immediate action required!.",
    },
    {
      title: "pH Level",
      data: "pH level rising above 8.0 Possible equipment malfunction.",
    },
    {
      title: "Dissolved Oxygen",
      data: "Dissolved Oxygen dropping below 75% Monitor closely.",
    },
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenDrawer(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleNotify = () => {
    setOpenDrawer(true);
  };
  return (
    <div
      className="home-container gap_1vw  flex_col"
      style={{ display: "flex" }}
    >
      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
          position: "relative",
        }}
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper className="exc-graph-paper space-btn-center">
            <div className="expert_div">
              <p className="Poppins_24px_black c_lightblue">
                Bioprocess Digital Twin Dashboard
              </p>
            </div>

            <div className="gap_1vw foo_one">
              <div
                className="expert_div notify_div cursor_p"
                onClick={handleNotify}
              >
                <StyledBadge
                  badgeContent={3}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <NotificationsIcon fontSize="large" />
                </StyledBadge>
              </div>
              <p className="Poppins_16px_rem font_400 ">
                Last Updated on:&nbsp;
                <span className="font_600">{getCurrentTimeInUSA()}</span>
              </p>
            </div>
          </Paper>
        </Grid>

        <Grid
          container
          className="grid-container"
          sx={{
            backgroundColor: "#ffff",
          }}
        >
          {param.length > 0 &&
            param.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={2.3}
                  xs={12}
                  md={2.9}
                  sm={5.9}
                  className="donut_grid_exc"
                  id="bio_grid_shadow"
                  sx={{
                    border: highlightIndexes.includes(index)
                      ? "2px solid #FF4560 !important"
                      : "1px solid #dbdbdb !important",
                  }}
                >
                  <Paper className="energy-paper  expert_div">
                    <div className="expert_div div_width flex_col gap_1vw text_cen">
                      <p className="expert_div text_c Poppins_18px_rem font_400">
                        {item.name}
                      </p>
                      <div className="expert_div div_width">
                        <img src={item.info} alt="battery" />
                      </div>

                      <div className="expert_div div_width ">
                        <p
                          className="Poppins_20px_rem"
                          style={{
                            color: highlightIndexes.includes(index)
                              ? "#FF4560"
                              : "#000",
                          }}
                        >
                          {item.value}
                        </p>
                        &nbsp;
                        <p
                          className="Poppins_20px_rem"
                          style={{
                            color: highlightIndexes.includes(index)
                              ? "#FF4560"
                              : "#000",
                          }}
                        >
                          {item.unit}
                        </p>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openDrawer}>
            <div className="alert_style">
              <div className="div_width expert_div gap_1vw flex_col">
                <div className="div_width expert_div">
                  <p className="Poppins_24px_black">Critical Alerts</p>
                </div>
                {notifications.length > 0 &&
                  notifications.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="div_width expert_div gap_1vw"
                        id="justify-start"
                      >
                        <div className="expert_div bg_notify">
                          <NotificationsNoneRoundedIcon />
                        </div>
                        <div className="expert_div flex_col align_item">
                          <p className="Poppins_18px_rem">{item.title}</p>
                          <p className="Poppins_16px_400">{item.data}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Fade>
        </Modal>
      </Grid>

      <Grid
        container
        className="grid-container"
        sx={{
          backgroundColor: "#ffff",
          padding: "1rem",
          border: "0.0625rem solid #d9d9d9",
          borderRadius: "0.5rem",
        }}
      >
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                31, 32, 30, 33, 34, 35, 36, 40.2, 39.5, 37, 34, 34, 32,
              ]}
              chartType="area"
              yaxisText="Temperature (°C)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 50]}
              openDrawerValue={true}
              titleText="Temperature"
              indicateUnit="°C"
              tooltipName="Temperature"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              annotation={39.5}
              annotationText="Overheating"
            />
          </Paper>
        </Grid>

        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                6.9, 6.6, 6.9, 6.8, 7.3, 7.5, 7.6, 8.5, 6.5, 6.4, 6.8, 6.9, 7,
              ]}
              chartType="area"
              yaxisText="pH Levels"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 10]}
              openDrawerValue={true}
              titleText="pH Levels"
              indicateUnit=""
              tooltipName="pH Level"
              annotation={6.5}
              annotationText="Too Acidic"
              annotationTwo={7.7}
              annotationTextTwo="Too Basic"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[75, 78, 79, 77, 79, 80, 78, 76, 68, 83, 85, 80, 78]}
              chartType="area"
              yaxisText="Dissolved Oxygen (%)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 100]}
              openDrawerValue={true}
              titleText="Dissolved Oxygen"
              indicateUnit="%"
              tooltipName="Dissolved Oxygen"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              annotation={75}
              annotationText="Critical Low"
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                2.5, 2, 1.8, 1.5, 1.3, 1.7, 2, 2.5, 2.8, 3, 3.5, 4, 3.5,
              ]}
              chartType="area"
              yaxisText="Cell Density (g/L)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 6]}
              openDrawerValue={true}
              titleText="Cell Density"
              indicateUnit="g/L"
              tooltipName="Cell Density"
              annotation={1.3}
              stringDurationFun={""}
              annotationText="Insufficient Growth"
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                2.5, 3, 3.5, 3.8, 3, 2.7, 2.5, 2.1, 2.8, 3, 3.5, 4, 3.5,
              ]}
              chartType="area"
              yaxisText="Glucose Concentration (g/L)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 6]}
              openDrawerValue={true}
              titleText="Glucose Concentration"
              indicateUnit="g/L"
              tooltipName="Glucose Concentration"
              annotation={2.1}
              annotationText="Glucose Depletion"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                0.5, 0.8, 1, 1.3, 1.4, 1.2, 1, 1.2, 2.2, 2, 1.5, 1.3, 1,
              ]}
              chartType="area"
              yaxisText="Pressure"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 4]}
              openDrawerValue={true}
              titleText="Pressure"
              indicateUnit="Bar"
              tooltipName="Pressure"
              annotation={2.2}
              annotationText="High Pressure Risk"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                100, 120, 200, 250, 300, 75, 100, 200, 300, 570, 500, 400, 300,
              ]}
              chartType="area"
              yaxisText="Agitation Rate (RPM)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 600]}
              openDrawerValue={true}
              titleText="Agitation Rate"
              indicateUnit="RPM"
              tooltipName="Agitation Rate"
              annotation={75}
              annotationText="Inefficient Mixing"
              annotationTwo={570}
              annotationTextTwo="Shear Stress Risk"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.95}
          xs={12}
          md={12}
          sm={12}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph1 ? "fixed" : "relative",
            top: fullScreenStates.graph1 ? "9vh" : "auto",
            width: fullScreenStates.graph1
              ? "-webkit-fill-available !important"
              : "auto",
            height: fullScreenStates.graph1 ? "92vh" : "auto",
            margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
            zIndex: fullScreenStates.graph1 ? 999 : "auto",
            background: "#fff",
          }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}
          >
            <ImgBarchart
              isValueData={[
                3, 3.2, 3.5, 3.6, 3.3, 3, 2.9, 2.5, 2, 1.5, 1.3, 1, 0.4,
              ]}
              chartType="area"
              yaxisText="Lactate Concentration (g/L)"
              StartTimer={""}
              StopTimer={""}
              minmax={[0, 5]}
              openDrawerValue={true}
              titleText="Lactate Concentration"
              indicateUnit="g/L"
              tooltipName="Lactate Concentration"
              annotation={3.6}
              annotationText="Toxic Accumulation"
              annotationTwo={0.4}
              annotationTextTwo="Metabolic Imbalance"
              stringDurationFun={""}
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
              isFilter={false}
              //   isFullScreen={fullScreenStates.graph1}
              //   toggleFullScreen={() => toggleFullScreen('graph1')}
              //   isMobileAndTab={isMobileAndTab}
              //   isDesktopView={isDesktopView}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Bioreactor;
