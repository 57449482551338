import React, { useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import "./Careers.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Footer from "../MainLandingPage/Footer";
function Careers() {
  const expOptions = ["Senior Level", "Entry Level", "Intern"];
  const location = ["Holly Springs,NC 27540 US"];
  const empOptions = ["Full-time", "Internship", "Contractual"];
  const skillsOptions = [
    "Java",
    "Python",
    "SQL",
    "Agile",
    "Jenkins",
    "Documentation",
    "Quality Assurance",
  ];
  const jobOptions = ["Remote", "On-site", "Hybrid"];
  const [isExpType, setIsExpType] = useState("Select");
  const [isLocationType, setIsLocationType] = useState("Select");
  const [employmentType, setEmploymentType] = useState("Select");
  const [skillsType, setSkillsType] = useState("Select");
  const [jobType, setJobType] = useState("Remote");
  const [isApply, setIsApply] = useState(false);
  const jobsData = [
    {
      title: "Software Engineer",
      desc: "Design, develop, and enhance software solutions using Java/Python, SQL, and CI/CD pipelines. Collaborate with stakeholders, ensure quality, automate testing, and provide deployment & production support.",
      level: "Mid Level",
      loc: "Holly Springs , NC 27540 US",
    },
  ];
  const handleChangeExp = (option) => {
    setIsExpType(option.value);
  };
  const handleChangeLocation = (option) => {
    setIsLocationType(option.value);
  };
  const handleChangeEmp = (option) => {
    setEmploymentType(option.value);
  };
  const handleChangeSkills = (option) => {
    setSkillsType(option.value);
  };
  const handleChangeJobType = (option) => {
    setJobType(option.value);
  };
  const handleApply = () => {
    setIsApply(true);
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main "
        id="our_sol"
      >
        <Grid
          container
          className="home_grid_container career-grid_container"
          id="our_sol"
        >
          <Grid
            className="exc-graph-paper"
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
          >
            <Paper className="exc-graph-paper">
              <div className="search-box">
                <input
                  className="semibold-smalltext"
                  id="search-input"
                  type="text"
                  placeholder="Search for jobs"
                />
                <div className="expert_div search_case">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M19.1129 17.5178L17.4596 15.9617M10.4329 16.7398C11.4642 16.7398 12.4855 16.5486 13.4383 16.1771C14.3911 15.8057 15.2568 15.2612 15.9861 14.5749C16.7153 13.8885 17.2938 13.0737 17.6885 12.177C18.0831 11.2802 18.2863 10.3191 18.2863 9.3484C18.2863 8.37775 18.0831 7.41661 17.6885 6.51985C17.2938 5.62308 16.7153 4.80827 15.9861 4.12191C15.2568 3.43556 14.3911 2.89112 13.4383 2.51967C12.4855 2.14821 11.4642 1.95703 10.4329 1.95703C8.35009 1.95703 6.35256 2.73576 4.87978 4.12191C3.40699 5.50807 2.57959 7.38809 2.57959 9.3484C2.57959 11.3087 3.40699 13.1887 4.87978 14.5749C6.35256 15.961 8.35009 16.7398 10.4329 16.7398V16.7398Z"
                      stroke="white"
                      stroke-width="1.25967"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            className="exc-graph-paper"
            item
            lg={10.3}
            xs={11.9}
            sm={12}
            md={9.8}
          >
            <Grid
              container
              className="home_grid_container career-grid_container"
            >
              <Grid
                className="exc-graph-paper"
                item
                lg={3.8}
                xs={11.9}
                sm={5.5}
                md={5.5}
              >
                <Paper className="energy-paper">
                  <div className="expert_div_main flex_col">
                    <p className="Raleway_21px_500">Experience Level</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "10" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={expOptions.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleChangeExp}
                          value={{
                            value: isExpType,
                            label: isExpType
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }}
                          placeholder="Select Experience"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className="exc-graph-paper"
                item
                lg={3.8}
                xs={11.9}
                sm={5.5}
                md={5.5}
              >
                <Paper className="energy-paper">
                  <div className="expert_div_main flex_col">
                    <p className="Raleway_21px_500">Location</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "9" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={location.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleChangeLocation}
                          value={{
                            value: isLocationType,
                            label: isLocationType
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }}
                          placeholder="Choose preferred location"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className="exc-graph-paper"
                item
                lg={3.8}
                xs={11.9}
                sm={5.5}
                md={5.5}
              >
                <Paper className="energy-paper">
                  <div className="expert_div_main flex_col">
                    <p className="Raleway_21px_500">Employment Type</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "8" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={empOptions.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleChangeEmp}
                          value={{
                            value: employmentType,
                            label: employmentType
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }}
                          placeholder="Choose Employment Type"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className="exc-graph-paper"
                item
                lg={3.8}
                xs={11.9}
                sm={5.5}
                md={5.5}
              >
                <Paper className="energy-paper">
                  <div className="expert_div_main flex_col">
                    <p className="Raleway_21px_500">Skills</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "7" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={skillsOptions.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleChangeSkills}
                          value={{
                            value: skillsType,
                            label: skillsType
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }}
                          placeholder="Choose Skills"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className="exc-graph-paper"
                item
                lg={3.8}
                xs={11.9}
                sm={5.5}
                md={5.5}
              >
                <Paper className="energy-paper">
                  <div className="expert_div_main flex_col">
                    <p className="Raleway_21px_500">Job Type</p>
                    <div
                      className="media_width_auto"
                      style={{ width: "14rem", minWidth: 160, zIndex: "6" }}
                    >
                      <FormControl className="form-report" fullWidth>
                        <Select
                          options={jobOptions.map((field) => ({
                            value: field,
                            label: field
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }))}
                          isMulti={false}
                          onChange={handleChangeJobType}
                          value={{
                            value: jobType,
                            label: jobType
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (char) => char.toUpperCase()),
                          }}
                          placeholder="Choose the Device Type"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="exc-graph-paper"
            item
            lg={1.5}
            xs={11.9}
            sm={12}
            md={2}
          >
            <Paper className="energy-paper">
              <div className="title_div" id="our_sol">
                <Button
                  type="submit"
                  id="viewjob"
                  variant="contained"
                  className="gap_1vw foo_one demo_btn"
                  style={{
                    padding: "1vh 1.5vw !important",
                    fontSize: "1.1vw !important",
                    borderRadius: "0.6vw !important",
                  }}
                >
                  View Jobs
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <div className="expert_div_main">
          <div className="expert_div_main">
            <p className="Raleway_23px_dark c_thirdBlack">1 Open Job Found</p>
          </div>
          <Grid
            container
            className="home_grid_container career-grid_container"
            id="joblist"
          >
            {jobsData.map((ele, index) => (
              <Grid
                key={index}
                className="exc-graph-paper"
                item
                lg={12}
                xs={11.9}
                sm={12}
                md={12}
              >
                <Paper className="exc-graph-paper">
                  <div className="expert_div_main gap_1vw">
                    <p className="Raleway_31px_600 font_700 c_blue">
                      {ele.title}
                    </p>
                    <div className="foo_one div_width flex_crct gap_1vw">
                      <div className="expert_div gap_1vw flex_crct div_width justify_start">
                        <p className="filter_car Raleway_18px_500 c_lightgray align_self">
                          {ele.level}
                        </p>
                        <p className="filter_car Raleway_18px_500 c_lightgray align_self">
                          {ele.loc}
                        </p>
                      </div>
                      <div
                        className="expert_div justify_end div_width"
                        id="justify_ends"
                      >
                        <Button
                          type="submit"
                          id="job_apply"
                          variant="contained"
                          className="gap_1vw foo_one demo_btn"
                          style={{
                            padding: "1vh 1.5vw !important",
                            fontSize: "1.1vw !important",
                          }}
                          onClick={handleApply}
                        >
                          Apply Now
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/arrowDemo.svg"
                            alt="Tracker"
                          />
                        </Button>
                      </div>
                    </div>
                    <p className="Raleway_23px_dark font_500 c_darkblack opacity_8 text_j">
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
        {isApply && (
          <Grid container className="home_grid_container " id="our_sol">
            <Grid
              className="career-grid_container"
              item
              lg={7.8}
              xs={11.9}
              sm={12}
              md={7.8}
            >
              <Paper className="exc-graph-paper">
                <div className="expert_div_main gap_1vw div_width">
                  <p className="Raleway_31px_600 font_700 c_darkBlack">
                    Software Engineer
                  </p>

                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Design & Analyze user specifications and requirements for
                      the project: 20%
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Interacting with business internal stakeholders in
                        understanding business requirements and converting
                        technical requirements
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Analyze user needs and software requirements to
                        determine feasibility of solution approach, prepare high
                        level design and provide estimations to business
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Develop and exhibit proof of concept (POC) for the
                        projects
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Pre and Post support: 40%
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Design low level documentations and technologies to be
                        used in the project
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Development of the features and security of the features
                        in java or python
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Mentoring junior in the feature development and their
                        code review
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Develop programs for automation testing of applications
                        using PYTHON or JAVA
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Develop and Program complex SQL(SQL Developer, SQL
                        management studio), Cassandra DB queries
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Schedule meeting with functional team and product owners
                        to discuss technical upgrade plan, additional
                        functionalities and Bug fixes in the new version
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Ensure all the requirements met: 20%
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Providing Demos to all the stakeholders and business
                        teams upon completion of critical milestones in the
                        project
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Building alerts for new requirements when it comes to
                        the production environment
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Peer reviews and feedback are collected from the various
                        stakeholders for better
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Deployments & Continuous production support: 10%
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Following the CICD (Continuous Integration/Continuous
                        Deployment) process using Git, Jenkins for promoting
                        Programs from one environment to another
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Provide knowledge transfer sessions and handover of
                        completed projects to support the team
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Maintenance and Enhancement of the projects: 10%
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Actively participating in the daily SCRUM meetings to
                        produce quality deliverable within time
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Interact with users, and other project team members on a
                        daily basis to perform development and pre-activities
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">Required Skills</p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Software Development & Engineering
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Business & Technical Requirement Analysis
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Software Testing & Automation
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        CI/CD (Continuous Integration/Continuous Deployment)
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Performance Testing & Optimization
                      </li>
                    </ul>
                  </div>
                  <div className="expert_div_main">
                    <p className="Raleway_23px_dark">
                      Current tools associated with this job
                    </p>
                    <ul id="dot_car">
                      <li className="Raleway_21px_500  c_black">
                        Version Control: Git
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Build & Deployment: Jenkins
                      </li>

                      <li className="Raleway_21px_500  c_black">
                        Database Management: SQL Developer, SQL Management
                        Studio, Cassandra DB
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Testing & Automation: Unit Testing Frameworks,
                        Automation Scripts (Python/Java) Code Review &
                        Development: Integrated Development Environments (IDEs)
                        like IntelliJ, Eclipse, VS Code
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Collaboration & Project Management: Jira, Agile Boards
                      </li>
                      <li className="Raleway_21px_500  c_black">
                        Code Review & Development: Integrated Development
                        Environments (IDEs) like IntelliJ, Eclipse, VS Code
                      </li>
                    </ul>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              className="career-grid_container"
              item
              lg={4}
              xs={11.9}
              sm={12}
              md={4}
            >
              <Paper className="exc-graph-paper">
                <div className="expert_div_main gap_1vw div_width">
                  <div className="expert_div_main gap_1vw div_width padd_1vw">
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Employment Type:</p>
                      <p className="Raleway_21px_500  c_black">Full-time</p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Work place:</p>
                      <p className="Raleway_21px_500  c_black">
                        Smarttrak LLC Holly Springs , NC 27540 US
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Work Type:</p>
                      <p className="Raleway_21px_500  c_black">Not Specified</p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Salary:</p>
                      <p className="Raleway_21px_500  c_black">
                        $ 144830.00 (Depends on Skill and Experience)
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        Salary Review: Yearly
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Experience Required:</p>
                      <p className="Raleway_21px_500  c_black">
                        Minimum 5 Years
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">
                        Working Hours and Shift:
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        40 Hours Per Week / Day Shift
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Working Days:</p>
                      <p className="Raleway_21px_500  c_black">
                        Weekly: 5 days.
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        Weekend: Saturday . Sunday
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">
                        Contact Person for this Job:
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        Bhagawan Gnanapa
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        Ph. No. (510) 945-8169
                      </p>
                      <p className="Raleway_21px_500  c_black">
                        Email: bhagawan@smarttrak.co
                      </p>
                    </div>
                  </div>
                  <div className="expert_div_main gap_1vw div_width border_car height_100 padd_1vw">
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Open Position </p>
                      <p className="Raleway_23px_dark">1 </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Location</p>
                      <p className="Raleway_23px_dark">
                        Smarttrak LLC Holly Springs , NC 27540 US{" "}
                      </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Job Code</p>
                      <p className="Raleway_23px_dark"> 16829627 </p>
                    </div>

                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Skills Required </p>
                      <p className="Raleway_23px_dark"> Software Engineer </p>
                    </div>
                    <div className="expert_div_main">
                      <p className="Raleway_23px_dark">Years of Exp </p>
                      <p className="Raleway_23px_dark"> 3 to 4 Years </p>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              className="career-grid_container margin_top_1vw"
              id="mail_shadow"
              item
              lg={7.8}
              xs={10}
              sm={7.8}
              md={7.8}
            >
              <Paper className="exc-graph-paper">
                <div className="expert_div div_width">
                  <p className="Raleway_31px_600  text_cen c_darkBlack font_style_i">
                    If interested, please email your resume to
                    <a href="mailto:hr@smarttrak.co" className="c_darkBlack">
                      {" "}
                      hr@smarttrak.co
                    </a>
                  </p>
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}
      </div>
      <div style={{ marginTop: "7vh" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Careers;
